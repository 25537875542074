import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import LanguageSelect from "../components/LanguageSelect";
import Sprite from "../components/Sprite";
import { loadNamespaces } from "i18next";

const PublicPageLayout: FunctionComponent = () => {
  const { i18n } = useTranslation(["language"]);
  const [isLanguageLoading, setIsLanguageLoading] = useState(false);
  loadNamespaces("home");
  i18n.on("languageChanged", function () {
    setIsLanguageLoading(false);
  });

  return (
    <main className="flex min-h-screen flex-col bg-bizDarkBlue-600">
      <div className="flex bg-white justify-center pt-4 pb-5">
        <Sprite width={206} height={34} x={0} y={0} />
      </div>
      <div className="flex justify-center items-center flex-1 pt-4 pb-8 px-4">
        <Outlet />
      </div>
      <div className="flex justify-center items-center flex-1 mb-4">
        <LanguageSelect
          onChange={(lang) => {
            setIsLanguageLoading(true);
            i18n.changeLanguage(lang);
          }}
          isLoading={isLanguageLoading}
        />
      </div>
    </main>
  );
};

export default PublicPageLayout;

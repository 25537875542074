import React, { useState } from "react";
import {
  useDeleteNotification,
  useGetNotificationList,
} from "../../../api/UserNotification";
import { Link } from "react-router-dom";
import Alert from "../../../components/Alert";
import Button from "../../../components/Button";
import FlashMessageHandler from "../../../components/FlashMessageHandler";
import PageHeader from "../../../components/PageHeader";
import PageHeaderWrapper from "../../../components/PageHeaderWrapper";
import ROUTES from "../../../navigation/route";
import { useTranslation } from "react-i18next";
import useFlashMessage from "../../../hooks/useFlashMessage";
import Modal from "../../../components/Modal";

function NotificationsPage() {
  const { data } = useGetNotificationList();
  const { displayFlashMessage } = useFlashMessage();
  const [itemToDelete, setItemToDelete] = useState<number>();

  const { mutate: deleteNotification } = useDeleteNotification({
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message?.join(" ") || error.message;
      displayFlashMessage("AdminNotificationPage", {
        description: errorMessage,
        data: { type: "danger" },
      });
    },
    onSuccess: () => {
      displayFlashMessage("AdminNotificationPage", {
        description: "data_has_been_removed",
      });
    },
  });
  const { t } = useTranslation(["admin_notifications", "common"]);
  const handleDeleteClick = (id: number) => {
    setItemToDelete(id);
  };

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8 w-full">
      <PageHeaderWrapper>
        <PageHeader>{t("header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        <FlashMessageHandler
          id={"AdminNotificationPage"}
          render={({ description, data: { type = "success" } }) => (
            <Alert type={type}>{t(description, { ns: "common" })}</Alert>
          )}
        />
        <div className="py pb-4">
          <Link to={ROUTES.ADMIN_NOTIFICATIONS_ADD}>
            <Button size="sm" colorScheme="sky-blue" onClick={() => {}}>
              {t("add", { ns: "common" })}
            </Button>
          </Link>
        </div>
        <div className="flex">
          <table className="text-sm border-spacing-y-2 w-full">
            <thead>
              <tr className="tr-class">
                <th className="th-class">{t("id")}</th>
                <th className="th-class">{t("message")}</th>
                <th className="th-class"></th>
              </tr>
            </thead>
            <tbody>
              {data?.map(({ id, message }) => (
                <tr className="tr-class" key={id}>
                  <td className="td-class">{id}</td>
                  <td className="td-class">{message}</td>
                  <td className="td-class">
                    <Link
                      to={`${ROUTES.ADMIN_NOTIFICATIONS_EDIT}/${id}`}
                      className="capitalize text-skyBlue-600 hover:text-skyBlue-700 px-0.5"
                    >
                      {t("edit", { ns: "common" })}
                    </Link>
                    <Button
                      colorScheme="ghost"
                      className="capitalize text-red-600 hover:text-red-700 hover:bg-transparent px-0.5 py-0"
                      onClick={() => handleDeleteClick(id)}
                    >
                      {t("remove", { ns: "common" })}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={Boolean(itemToDelete)}
        onClose={() => setItemToDelete(undefined)}
        onButtonClick={() => deleteNotification(itemToDelete!)}
        buttonText={t("yes", { ns: "common" })}
        title={t("confirm_your_action", { ns: "common" })}
        isLoading={false}
      >
        {t("are_you_sure_to_delete", { ns: "common" })}
      </Modal>
    </div>
  );
}

export default NotificationsPage;

import { AxiosError } from "axios";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  useIsPinRequiredMutation,
  useSignInMutation,
  useSignUpMutation,
} from "../../api/Auth";
import Alert from "../../components/Alert";
import { HttpStatusCode } from "../../enums/HttpStatusCode";
import useAuth from "../../hooks/useAuth";
import ROUTES from "../../navigation/route";
import SignInForm from "./form/SignInForm";
import SignUpForm from "./form/SignUpForm";
import { loadNamespaces } from "i18next";
import Icon from "../../components/Icon";

const HomePage: FunctionComponent = (): JSX.Element => {
  loadNamespaces("home");

  const navigate = useNavigate();
  const { t } = useTranslation(["home", "validation"]);
  const { onSignIn } = useAuth();
  const [signInErrorMessage, setSignInErrorMessage] = useState<string>();
  const [signUpErrorMessage, setSignUpErrorMessage] = useState<string>();

  const { mutate: signInMutate } = useSignInMutation();
  const { mutate: signUpMutate } = useSignUpMutation();

  const isPinRequiredMutation = useIsPinRequiredMutation();

  const onCredentialsProvided = async ({
    username,
    password,
    remember,
  }: {
    username: string;
    password: string;
    remember: boolean;
  }) => {
    if (!(await isPinRequiredMutation.mutateAsync({ username }))) {
      signInMutate(
        {
          username,
          password,
        },
        {
          onSuccess: (data) => {
            onSignIn({
              accessToken: data.token,
              refreshToken: data.refresh_token,
              isLoggedIn: true,
              remember: remember,
              homeShop: data.homeShop,
            });
          },
          onError: (error: AxiosError) => {
            if (error.response?.status === HttpStatusCode.UNAUTHORIZED) {
              setSignInErrorMessage("validation:invalid_credentials");
            } else if (error.code === AxiosError.ERR_NETWORK) {
            }
          },
        },
      );
    } else {
      navigate("/pin", { state: { username, password, remember } });
    }
  };

  const handleSignUp = async (custNo: string) => {
    signUpMutate(
      { custNo },
      {
        onSuccess: ({ url }) => {
          window.location.href = url;
        },
        onError: (error: AxiosError) => {
          if (error.response?.status === HttpStatusCode.NOT_FOUND) {
            setSignUpErrorMessage("sponsor_not_found");
          } else if (error.code === AxiosError.ERR_NETWORK) {
            setSignUpErrorMessage("unexpected_error_occured");
          }
          setSignUpErrorMessage("unexpected_error_occured");
        },
      },
    );
  };

  return (
    <div className="flex flex-col flex-1 h-full max-w-4xl bg-bizDarkBlue-500 pb-14 font-gothicBookRegular">
      <div className="flex bg-skyBlue-600 h-20 rounded-t justify-center items-center">
        <Icon name="login" />
        <h1 className="pl-4 text-white uppercase">{t("login")}</h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-1 flex-col py-8 px-8 sm:px-16">
          <div className="text-skyBlue-600 pb-7 uppercase">
            {t("new_customer")}
          </div>
          {signUpErrorMessage && (
            <Alert type="danger">{t(signUpErrorMessage)}</Alert>
          )}
          <SignUpForm onSubmit={({ custNo }) => handleSignUp(custNo)} />
        </div>
        <div className="flex flex-1 flex-col py-8 px-8 sm:px-16">
          <div className="text-skyBlue-600 pb-7 uppercase">
            {t("existing_customer")}
          </div>
          {signInErrorMessage && (
            <Alert type="danger">{t(signInErrorMessage)}</Alert>
          )}
          <SignInForm onSubmit={(data) => onCredentialsProvided(data)} />
          <div className="text-white pt-6 text-xs">
            {t("forgotten_password")}{" "}
            <Link
              to={ROUTES.REMIND_PASSWORD}
              className="hover:underline text-skyBlue-600"
            >
              {t("click_here")}
              {" >"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

import {
  DetailedHTMLProps,
  FunctionComponent,
  InputHTMLAttributes,
} from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../../../../components/Form";

type CheckboxInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  errorMessage?: string;
  children: JSX.Element | string;
  name: string;
};

const CheckboxInput: FunctionComponent<CheckboxInputProps> = ({
  children,
  errorMessage,
  name,
  ...rest
}) => {
  const { register } = useFormContext();

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <input
          {...register(name)}
          {...rest}
          id="link-checkbox"
          type="checkbox"
          value=""
          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
        />
        <label className="ml-2 font-medium text-white text-xs">
          {children}
        </label>
      </div>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
};

export default CheckboxInput;

import { PaginationParams } from "../types/PaginationParams";
import useApiClient from "../hooks/useApiClient";
import { useQuery } from "@tanstack/react-query";
import { TableData } from "../types/TableData";
import camelcaseKeys from "camelcase-keys";

export type CustomerListFilters = {
  custNo: number | null;
  lastName: string | null;
  firstName: string | null;
  titleGt: number | null;
  titleLt: number | null;
  period: number | null;
  pointsGt: number | null;
  pointsLt: number | null;
  freeMonth: number | null;
  active: boolean | null;
  noEmail: boolean | null;
  creditCardProb: boolean | null;
  noOrderLastMonth: boolean | null;
};

type Customer = {
  custNo: number;
  deleted: number | null;
  title: number | null;
  active: number | null;
  period: number | null;
  currentPoints: string | null;
  currentOsV: string | null;
  currentGsV: string | null;
  businessKit: null;
  country: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  email: string | null;
};

export const useCustomersTableData = ({
  filters,
  page,
  perPage,
}: { filters: Partial<CustomerListFilters> | {} } & PaginationParams) => {
  const { privateApiClient } = useApiClient();

  return useQuery<TableData<Customer>, unknown>({
    queryKey: ["manager_customer_list", JSON.stringify(filters)],
    queryFn: async () =>
      camelcaseKeys(
        (
          await privateApiClient.post(
            `/customer/tree/search/${page}/${perPage}`,
            filters,
          )
        ).data,
        { deep: true },
      ),
    initialData: undefined,
  });
};

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Form } from "../../../types/form";
import TextInput from "../components/form/TextInput";
import ButtonSubmit from "../components/form/ButtonSubmit";
import Icon from "../../../components/Icon";

type FormValues = {
  custNo: string;
};

const schema = yup
  .object({
    custNo: yup.string().trim().required("field_required"),
  })
  .required();

const SignUpForm: Form<FormValues> = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation(["home"]);

  const { formState, handleSubmit, ...formMethods } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider<FormValues>
      formState={formState}
      {...formMethods}
      handleSubmit={handleSubmit}
    >
      <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name="custNo"
          sprite={<Icon name="user" />}
          placeholder="EQ ID Number"
          errorMessage={formState.errors.custNo?.message}
        />
        <div className="flex justify-end">
          <ButtonSubmit isLoading={isLoading}>{t("register")}</ButtonSubmit>
        </div>
      </form>
      <div className="text-white text-xs pt-6">
        {t("register_description")}{" "}
        <a
          href="mailto:support@eqology.com"
          className="hover:underline text-skyBlue-600"
        >
          support@eqology.com
        </a>
        .
      </div>
    </FormProvider>
  );
};

export default SignUpForm;

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form } from "../../../types/form";
import PinKeybaordButton from "../components/form/PinKeybaordButton";
import PinItem from "../components/form/PinItem";
import { useEffect } from "react";
import ButtonSubmit from "../components/form/ButtonSubmit";

type FormValues = {
  pin: string;
};

const schema = yup
  .object({
    pin: yup.string().trim().required("field_required").length(4),
  })
  .required();

const PinForm: Form<
  FormValues,
  { setErrorMessage: (errorMessage: string) => void }
> = ({ onSubmit, isLoading, setErrorMessage }) => {
  const { handleSubmit, watch, setValue } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      pin: "",
    },
  });

  const pin = watch("pin");

  useEffect(() => {}, []);

  return (
    <form
      className="flex space-y-3 flex-col items-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex space-x-4 pb-2">
        <PinItem filled={pin.length >= 1} />
        <PinItem filled={pin.length >= 2} />
        <PinItem filled={pin.length >= 3} />
        <PinItem filled={pin.length === 4} />
      </div>
      <div className="flex flex-wrap w-72 justify-center">
        {Array.from({ length: 9 }).map((_, index) => (
          <PinKeybaordButton
            key={index}
            onClick={() =>
              pin.length < 4 && setValue("pin", pin + String(index + 1))
            }
          >
            {String(index + 1)}
          </PinKeybaordButton>
        ))}
        <PinKeybaordButton />
        <PinKeybaordButton
          onClick={() => pin.length < 4 && setValue("pin", pin + "0")}
        >
          0
        </PinKeybaordButton>
        <PinKeybaordButton onClick={() => setValue("pin", "")} danger>
          Reset
        </PinKeybaordButton>
        <div className="flex flex-1 justify-end pr-3 pt-4">
          <ButtonSubmit isLoading={isLoading}>Login</ButtonSubmit>
        </div>
      </div>
    </form>
  );
};

export default PinForm;

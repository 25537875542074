import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from "react";
import { ErrorMessage } from "../index";
type CheckboxInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  errorMessage?: string;
  name: string;
};

const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ errorMessage, ...rest }, ref) => {
    return (
      <div className="flex flex-col">
        <input
          type="checkbox"
          ref={ref}
          {...rest}
          className="pl-2 rounded-[3px] shadow-dashboard-input rounded-none rounded-l-sm rounded-r-sm bg-white text-gray-900 block flex-1 min-w-0 w-full text-sm border-gray-300 p-1.5 focus:outline-none"
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </div>
    );
  },
);

export default CheckboxInput;

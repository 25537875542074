import { useMutation } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";
import { AxiosError } from "axios";
import { BasicMutationOptions } from "../queryClient";

export type Recipient = {
  custNo: number;
  currency?: string;
  amount: number;
};
export type LiveBonus = {
  type: string;
  sourceCustNo?: number | null;
  sourceOrderId?: number | null;
  sourceOrderLineId?: number | null;
  recipients: Recipient[];
};

export const useLiveBonusBuilder = (
  mutationOptions?: BasicMutationOptions<any, AxiosError<{ message: string }>>,
) => {
  const { privateApiClient } = useApiClient();
  return useMutation<LiveBonus, AxiosError<{ message: string }>, LiveBonus>({
    mutationFn: async (data: LiveBonus) =>
      camelcaseKeys(
        (await privateApiClient.post("/bonus/live/builder/award", data)).data,
      ),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

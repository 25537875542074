import React from "react";
import CollapsedNavChild from "./CollapsedNavChild";
import { useTranslation } from "react-i18next";
import { Role } from "biz-dashboard-frontend/lib/types/components/reports-core/types/Role";

export type NavChildren = {
  route: string;
  label: string;
  isOldRouting?: boolean;
  accessFor?: Role[];
};

type CollapsedNavChildrenPropsType = {
  childrenCollection: NavChildren[];
  section: string;
  className?: string;
};
function CollapsedNavChildren({
  childrenCollection,
  section,
  className,
}: CollapsedNavChildrenPropsType) {
  const { t } = useTranslation(["sidebar"]);
  return childrenCollection.map(
    ({ route, label, isOldRouting = false, accessFor }) => {
      return (
        <CollapsedNavChild
          key={route}
          section={section}
          route={route}
          label={t(label)}
          accessFor={accessFor}
          isOldRouting={isOldRouting}
          className={className}
        />
      );
    },
  );
}

export default CollapsedNavChildren;

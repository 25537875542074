import React, { ReactNode, useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import PageHeaderWrapper from "../../../components/PageHeaderWrapper";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/Form/TextInput";
import ButtonSubmit from "../../../components/Form/ButtonSubmit";
import { FormRow } from "../../../components/FormRow";
import PointsToCurrentMonthForm from "./form/PointsToCurrentMonthForm";
import PointsFromCustomerToCustomerForm from "./form/PointsFromCustomerToCustomerForm";
import SetCustomerAsActiveForm from "./form/SetCustomerAsActiveForm";
import GivePointsDirectlyForm from "./form/GivePointsDirectlyForm";
import useBonusRunHistory, { Option } from "../../../hooks/useBonusRunHistory";
import ReactSelect from "react-select";

type BonusArchiveSectionPropsType = {
  sectionTitle: string;
  description: string;
  form: ReactNode;
};
const BonusArchiveSection = ({
  sectionTitle,
  description,
  form,
}: BonusArchiveSectionPropsType) => {
  return (
    <div className="flex flex-col gap-3 py-6">
      <h2 className="text-subtitle uppercase">{sectionTitle}</h2>
      <p>{description}</p>
      {form}
    </div>
  );
};

function BonusArchiveManagementPage() {
  const { t, i18n } = useTranslation("bonus_archive_management");
  const { bonusRunHistorySelectOptions, bonusRunHistoryLoading } =
    useBonusRunHistory({
      i18n,
    });
  const [bonusRunHistory, setBonusRunHistory] = useState<Option>();
  useEffect(() => {
    setBonusRunHistory(bonusRunHistorySelectOptions[0]);
  }, [bonusRunHistorySelectOptions]);
  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8 w-full">
      <PageHeaderWrapper>
        <PageHeader>{t("header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        <FormRow label={t("choose_period")} labelClassName="sm:w-auto pr-2">
          <ReactSelect
            className="max-w-60"
            options={bonusRunHistorySelectOptions}
            value={bonusRunHistory}
            onChange={(value) => {
              if (value) setBonusRunHistory(value);
            }}
            isLoading={bonusRunHistoryLoading}
          />
        </FormRow>
        {!!bonusRunHistory && (
          <>
            <BonusArchiveSection
              sectionTitle={t("points_to_current_month_title")}
              description={t("points_to_current_month_description")}
              form={
                <PointsToCurrentMonthForm
                  bonusRunHistoryId={bonusRunHistory.value}
                />
              }
            />
            <BonusArchiveSection
              sectionTitle={t("points_from_customer_to_customer_title")}
              description={t("points_from_customer_to_customer_description")}
              form={
                <PointsFromCustomerToCustomerForm
                  bonusRunHistoryId={bonusRunHistory.value}
                />
              }
            />
            <BonusArchiveSection
              sectionTitle={t("set_customer_as_active_title")}
              description={t("set_customer_as_active_description")}
              form={
                <SetCustomerAsActiveForm
                  bonusRunHistoryId={bonusRunHistory.value}
                />
              }
            />
            <BonusArchiveSection
              sectionTitle={t("give_points_directly_title")}
              description={t("give_points_directly_description")}
              form={
                <GivePointsDirectlyForm
                  bonusRunHistoryId={bonusRunHistory.value}
                />
              }
            />
          </>
        )}
      </div>
    </div>
  );
}

export default BonusArchiveManagementPage;

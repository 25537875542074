import { FunctionComponent, useEffect } from "react";
import {
  MyProfileWidget,
  MyStatisticsWidget,
  MyTitleWidget,
  LiveBonusesWidget,
  CabAcceleratorWidget,
  MentorAcceleratorWidget,
  LifestyleTrip20242025Widget,
  PlatinumTripWidget20242025,
  PccBonusWidget,
  GivesBackWidget,
  PremiumChallengeWidget,
  FsqProgramWidget,
  Director60ProgramWidget,
  ActiveCustomerOverviewWidget,
  CustomersWidget,
  BusinessPartnersWidget,
  GraphWidget,
  Top20RecruitmentContestWidget,
} from "biz-dashboard-frontend";

import useApiClient from "../../hooks/useApiClient";
import { useTranslation } from "react-i18next";
import withI18nNamespace from "../../helpers/withI18nNamespace";
import { useWebComponentsWithSPARouting } from "../../hooks/useWebComponentsWithSPARouting";
import { useGetUserInfo } from "../../api/UserInfo";
import useUser from "../../hooks/useUser";
const WidgetsPage: FunctionComponent = (): JSX.Element => {
  const { i18n } = useTranslation();
  const { privateApiClient } = useApiClient();
  const {
    user: { custNo },
  } = useUser();
  const { data: userInfo } = useGetUserInfo({ id: custNo });
  const lang = userInfo?.lang || "en";
  useWebComponentsWithSPARouting();

  return (
    <div className="h-full w-full font-gothicBookRegular grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
      <MyProfileWidget
        i18n={withI18nNamespace("my_profile", i18n)}
        apiClient={privateApiClient}
      />
      <MyStatisticsWidget
        i18n={withI18nNamespace("my_statistics", i18n)}
        apiClient={privateApiClient}
      />
      <MyTitleWidget
        i18n={withI18nNamespace("my_title", i18n)}
        apiClient={privateApiClient}
      />
      <LiveBonusesWidget
        i18n={withI18nNamespace("live_bonuses", i18n)}
        apiClient={privateApiClient}
      />
      <CabAcceleratorWidget
        i18n={withI18nNamespace("cab_accelerator", i18n)}
        apiClient={privateApiClient}
      />
      <MentorAcceleratorWidget
        i18n={withI18nNamespace("mentor_accelerator", i18n)}
        apiClient={privateApiClient}
      />
      <LifestyleTrip20242025Widget
        i18n={withI18nNamespace("lifestyle_trip", i18n)}
        apiClient={privateApiClient}
        lang={lang}
      />
      <PlatinumTripWidget20242025
        i18n={withI18nNamespace("platinum_trip", i18n)}
        apiClient={privateApiClient}
        lang={lang}
      />
      <PccBonusWidget
        i18n={withI18nNamespace("pcc_bonus", i18n)}
        apiClient={privateApiClient}
      />
      <GivesBackWidget
        i18n={withI18nNamespace("gives_back", i18n)}
        apiClient={privateApiClient}
      />
      <PremiumChallengeWidget
        i18n={withI18nNamespace("premium_challenge", i18n)}
        apiClient={privateApiClient}
        lang={lang}
      />
      <FsqProgramWidget
        i18n={withI18nNamespace("fsq_program", i18n)}
        apiClient={privateApiClient}
      />
      <Director60ProgramWidget
        i18n={withI18nNamespace("director60_program", i18n)}
        apiClient={privateApiClient}
      />
      <ActiveCustomerOverviewWidget
        i18n={withI18nNamespace("active_customer_overview", i18n)}
        apiClient={privateApiClient}
      />
      <CustomersWidget
        i18n={withI18nNamespace("customers", i18n)}
        apiClient={privateApiClient}
      />
      <BusinessPartnersWidget
        i18n={withI18nNamespace("business_partners", i18n)}
        apiClient={privateApiClient}
      />
      <div className="col-span-1 md:col-span-2">
        <GraphWidget
          i18n={withI18nNamespace("graph", i18n)}
          apiClient={privateApiClient}
        />
      </div>
      <Top20RecruitmentContestWidget
        i18n={withI18nNamespace("top20_recruitment_contest", i18n)}
        apiClient={privateApiClient}
        lang={lang}
      />
    </div>
  );
};

export default WidgetsPage;

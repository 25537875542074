import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeaderWrapper from "../../../components/PageHeaderWrapper";
import PageHeader from "../../../components/PageHeader";
import FlashMessageHandler from "../../../components/FlashMessageHandler";
import Alert from "../../../components/Alert";
import {
  CustomerListFilters,
  useCustomersTableData,
} from "../../../api/Customer";
import dayjs from "dayjs";
import { useImpersonateMutation } from "../../../api/Auth";
import useAuth from "../../../hooks/useAuth";
import FiltersForm from "./FiltersForm";

const CustomersPage: FunctionComponent = (): JSX.Element => {
  const { onImpersonate } = useAuth();

  const { t } = useTranslation(["manager_customers", "common"]);

  const { mutate: impersonate } = useImpersonateMutation({
    onSuccess: (data) =>
      onImpersonate({
        isLoggedIn: true,
        accessToken: data.token,
        refreshToken: "",
        homeShop: "",
        remember: false,
      }),
  });

  const [filters, setFilters] = useState<Partial<CustomerListFilters> | {}>({});

  const { data } = useCustomersTableData({
    filters,
    page: 1,
    perPage: 25,
  });

  // const { displayFlashMessage } = useFlashMessage();

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8 w-full">
      <PageHeaderWrapper>
        <PageHeader>{t("header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        <FlashMessageHandler
          id={"AdminUserPage:success"}
          render={({ description }) => (
            <Alert type="success">{t(description, { ns: "common" })}</Alert>
          )}
        />

        <FiltersForm onSubmit={(data) => setFilters(data)} loading={false} />

        <div className="flex">
          <table className="text-sm border-spacing-y-2 w-full">
            <thead>
              <tr className="tr-class">
                <th className="th-class">{t("customer_number")}</th>
                <th className="th-class">{t("first_name")}</th>
                <th className="th-class">{t("last_name")}</th>
                <th className="th-class">{t("phone")}</th>
                <th className="th-class">{t("email")}</th>
                <th className="th-class">{t("deleted")}</th>
                <th className="th-class">{t("title")}</th>
                <th className="th-class">{t("active")}</th>
                <th className="th-class">{t("period")}</th>
                <th className="th-class">{t("current_points")}</th>
                <th className="th-class">{t("current_osv")}</th>
                <th className="th-class">{t("current_gsv")}</th>
                <th className="th-class">{t("business_kit")}</th>
                <th className="th-class">{t("country")}</th>
                <th className="th-class">options</th>
              </tr>
            </thead>
            <tbody>
              {data?.items.map(
                ({
                  custNo,
                  deleted,
                  title,
                  active,
                  period,
                  currentPoints,
                  currentOsV,
                  currentGsV,
                  businessKit,
                  country,
                  firstName,
                  lastName,
                  phone,
                  email,
                }) => (
                  <tr className="tr-class" key={custNo}>
                    <td className="td-class">{custNo}</td>
                    <td className="td-class">{firstName}</td>
                    <td className="td-class">{lastName}</td>
                    <td className="td-class">{phone}</td>
                    <td className="td-class">{email}</td>
                    <td className="td-class">{deleted ? t("yes") : t("no")}</td>
                    {/*<td className="td-class">{findByLevel(Number(title)).shortName}</td>*/}
                    <td className="td-class">{title}</td>
                    <td className="td-class">{active}</td>
                    <td className="td-class">{period}</td>
                    <td className="td-class">{currentPoints}</td>
                    <td className="td-class">{currentOsV}</td>
                    <td className="td-class">{currentGsV}</td>
                    <td className="td-class">
                      {businessKit
                        ? dayjs(businessKit).format("YYYY-MM-DD")
                        : "-"}
                    </td>
                    <td className="td-class">{country}</td>
                    <td className="td-class">
                      {/* Assuming a route to edit Customer details, with custNo as parameter */}
                      <button
                        onClick={() => impersonate({ custNo })}
                        className="capitalize text-skyBlue-600 hover:text-skyBlue-700"
                      >
                        {t("impersonate", { ns: "common" })}
                      </button>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomersPage;

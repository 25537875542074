import { QueryClient } from "@tanstack/react-query";

export type BasicMutationOptions<DataT, ErrorT> = {
  onSuccess?: (data: DataT) => void;
  onError?: (error: ErrorT) => void;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

export default queryClient;

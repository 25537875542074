import {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import { useGetLanguageList } from "../../api/Language";
import {
  useGetTranslationList,
  useGetNamespaceList,
  useCreateNsMutation,
  useAddTranslationMutation,
  useEditTranslationMutation,
  Translation,
} from "../../api/Translation";
import ReactSelect from "react-select";
import { localNamespaces } from "../../i18n";
import { Controller, useForm } from "react-hook-form";
import { FormRow } from "../../components/FormRow";

type TranslationFilters = {
  selectedNamespaceId: number;
  selectedLanguageId: number;
  translations: Translation[];
};

type SelectedOption = {
  label: string;
  value: number;
};

function transformTranslations({
  selectedNamespaceId,
  selectedLanguageId,
  translations,
}: TranslationFilters) {
  const transformToObject = translations?.reduce<
    Record<string, Pick<Translation, "value" | "id" | "description">>
  >(
    (previousTranslation, { key, value, id, description }) => ({
      ...previousTranslation,
      [key]: { value, id, description },
    }),
    {},
  );
  return transformToObject;
}

const TranslationsPage: FunctionComponent<{
  variant?: string;
  menuPlacement?: "top" | "bottom";
}> = () => {
  const { t } = useTranslation(["translations"]);
  const { data: languageList } = useGetLanguageList();
  const { data: namespaces, isLoading: isNsListLoading, isFetching: isNsListFetching } =
    useGetNamespaceList();
  const { mutate: createNsMutate } = useCreateNsMutation();
  const { mutate: addTranslationMutate } = useAddTranslationMutation();
  const { mutate: editTranslationMutate } = useEditTranslationMutation();
  const [selectedLang, setSelectedLang] = useState<SelectedOption | null>(null);
  const [selectedNs, setSelectedNs] = useState<SelectedOption | null>(null);
  const isOptionsSelected = !!selectedLang?.value && !!selectedNs?.value;
  const { data: translations } = useGetTranslationList({
    language: selectedLang?.value,
    namespace: selectedNs?.value,
  });
  const editableContentRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    editableContentRef &&
      setWidth(Number(editableContentRef?.current?.offsetWidth));
  }, []);

  const mapLocaleNamespaces = Object.keys(localNamespaces).map((ns) => ({
    label: ns,
    value: ns,
  }));

  const localeNamespaceTrans =
    localNamespaces &&
    selectedNs &&
    Object.entries(
      localNamespaces[selectedNs.label as keyof typeof localNamespaces],
    )?.map(([key, value]) => ({
      key,
      value,
    }));

  const langNsTrans =
    isOptionsSelected &&
    transformTranslations({
      selectedNamespaceId: selectedNs.value,
      selectedLanguageId: selectedLang.value,
      translations: translations as Translation[],
    });

  const { control, reset } = useForm<Record<string, string>>();

  useEffect(() => {
    reset({});
  }, [selectedLang]);

  useEffect(() => {
    if (!isNsListFetching) {
      if (!namespaces.length) {
        mapLocaleNamespaces.forEach(({ label }) => {
          createNsMutate({ name: label, description: "" });
        });
        return;
      }
      const newLocaleNs = mapLocaleNamespaces.filter(({ value: localName }) => {
        return !namespaces.some(({ name }) => localName === name);
      });
      if (newLocaleNs.length) {
        newLocaleNs.forEach(({ label }) => {
          createNsMutate({ name: label, description: "" });
        });
      }
    }
  }, [isNsListLoading, namespaces]);

  const onTranslationInputBlur = ({
    key,
    value,
  }: {
    key: string;
    value: string | null;
  }) => {
    const isKeyExist = langNsTrans && langNsTrans[key] !== undefined;
    const isValueChanged = isKeyExist && langNsTrans[key].value !== value;
    const isNewValueExist = !isKeyExist && value !== "";

    if (isValueChanged && value) {
      editTranslationMutate({
        id: langNsTrans[key].id as number,
        value: value.replace(/<[^>]+>/g, ""),
        description: langNsTrans[key].description || "",
      });
    }
    if (isNewValueExist && value) {
      addTranslationMutate({
        key,
        value: value.replace(/<[^>]+>/g, ""),
        namespace: selectedNs?.value || 0,
        language: selectedLang?.value || 0,
        description: "",
      });
    }
  };

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8 gap-2">
      <PageHeaderWrapper>
        <PageHeader>{t("translations").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col sm:flex-row gap-3">
        <FormRow direction="vertical" label={t("language")}>
          <ReactSelect
            className={`w-64 color-white  cursor-pointer`}
            options={languageList?.map(({ lang, id, fullLangName }) => ({
              label: `${fullLangName} (${lang})`,
              value: id,
            }))}
            onChange={(selectedOption) => {
              setSelectedLang(selectedOption);
            }}
          />
        </FormRow>
        <FormRow direction="vertical" label={t("namespace")}>
          <ReactSelect
            className={`w-64 color-white  cursor-pointer`}
            options={namespaces?.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            onChange={(selectedOption) => {
              setSelectedNs(selectedOption);
            }}
          />
        </FormRow>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex-row hidden sm:flex">
          <div className="bg-skyBlue-600 flex flex-col basis-1/2 px-1 py-1.5">
            <span className="pl-2 text-white text-sm">
              <b>{t("system_value")}</b>
            </span>
          </div>
          <div
            ref={editableContentRef}
            className="bg-skyBlue-600 flex flex-col basis-1/2 px-1 py-1.5"
          >
            <span className="pl-2 text-white text-sm">
              <b>{t("translation")}</b>
            </span>
          </div>
        </div>
        {Array.isArray(localeNamespaceTrans) &&
          localeNamespaceTrans?.map(({ key, value }) => (
            <div
              className="flex flex-col sm:flex-row "
              key={selectedLang + key}
            >
              <div className="flex flex-col basis-1/2">
                <span className="bg-bizGray-300">{key}</span>
                <span className="bg-white">{value}</span>
              </div>
              <div
                className="flex flex-col basis-1/2"
                style={{ maxWidth: width }}
              >
                <span className="hidden sm:flex">&nbsp;</span>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <span
                      className={
                        "sm:ml-0.5 bg-white pl-1 focus:outline-none focus:ring-1 focus:border-skyBlue-500 focus:ring-skyBlue-500"
                      }
                      role="textbox"
                      onBlur={(e) => {
                        const { id: key, textContent: value } = e.currentTarget;
                        onChange(value);
                        onTranslationInputBlur({ key, value });
                      }}
                      id={key}
                      style={{ wordWrap: "break-word" }}
                      contentEditable
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (document.activeElement instanceof HTMLElement) {
                            document.activeElement.blur();
                          }
                        }
                      }}
                      suppressContentEditableWarning
                    >
                      {value ??
                        ((langNsTrans &&
                          langNsTrans?.[key]?.value.replace(/<[^>]+>/g, "")) ||
                          "")}
                    </span>
                  )}
                  control={control}
                  name={key + selectedLang}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TranslationsPage;

import { useQuery, useMutation } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import queryClient from "../queryClient";

export const useGetDaemonStatus = () => {
  const { privateApiClient } = useApiClient();
  return useQuery<{ status: 0 | 1 }, unknown>({
    queryKey: ["daemonStatus"],
    queryFn: async () =>
      (await privateApiClient.get<{ status: 0 | 1 }>("/daemon/status")).data,
  });
};

export const useStopDaemon = () => {
  const { privateApiClient } = useApiClient();
  return useMutation<unknown, unknown>({
    mutationFn: async () => (await privateApiClient.post("/daemon/stop")).data,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["daemonStatus"] }),
  });
};

export const useStartDaemon = () => {
  const { privateApiClient } = useApiClient();
  return useMutation<unknown, unknown>({
    mutationFn: async () => (await privateApiClient.post("/daemon/start")).data,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["daemonStatus"] }),
  });
};

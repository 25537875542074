import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import common from "./translations/en/common.json";
import home from "./translations/en/home.json";
import validation from "./translations/en/validation.json";
import sidebar from "./translations/en/sidebar.json";
import topbar from "./translations/en/topbar.json";
import payment_information from "./translations/en/payment_information.json";
import pin_management from "./translations/en/pin_management.json";
import crop_profile_image from "./translations/en/crop_profile_image.json";
import languages from "./translations/languages.json";
import language from "./translations/en/language.json";
import language_management from "./translations/en/language_management.json";
import translations from "./translations/en/translations.json";
import admin_users from "./translations/en/admin_users.json";
import manager_customers from "./translations/en/manager_customers.json";
import agreement_notification from "./translations/en/agreement_notification.json";
import admin_notifications from "./translations/en/admin_notifications.json";
import pagination from "./translations/en/pagination.json";
import bonus_archive_management from "./translations/en/bonus_archive_management.json";
import live_bonus_builder from "./translations/en/live_bonus_builder.json";
import LocalStorageBackend from "i18next-localstorage-backend";

import resourcesToBackend from "i18next-resources-to-backend";
import { ComponentsI18nNamespaces } from "biz-dashboard-frontend/src/ComponentsI18nNamespaces";

import active_legs_report from "biz-dashboard-frontend/src/components/active-legs-report/translations/en.json";
import active_customer_overview from "biz-dashboard-frontend/src/components/active-customer-overview/translations/en.json";
import active_titles_status_report from "biz-dashboard-frontend/src/components/active-titles-status-report/translations/en.json";
import arctic_challenge_report from "biz-dashboard-frontend/src/components/arctic-challenge-report/translations/en.json";
import business_kits_report from "biz-dashboard-frontend/src/components/business-kits-report/translations/en.json";
import business_partners from "biz-dashboard-frontend/src/components/business-partners/translations/en.json";
import business_partners_report from "biz-dashboard-frontend/src/components/business-partners-report/translations/en.json";
import cab_accelerator from "biz-dashboard-frontend/src/components/cab-accelerator/translations/en.json";
import cab_accelerator_report from "biz-dashboard-frontend/src/components/cab-accelerator-report/translations/en.json";
import coaching_commission_report from "biz-dashboard-frontend/src/components/coaching-commission-report/translations/en.json";
import commission_report from "biz-dashboard-frontend/src/components/commission-report/translations/en.json";
import customers from "biz-dashboard-frontend/src/components/customers/translations/en.json";
import director60_program from "biz-dashboard-frontend/src/components/director60-program/translations/en.json";
import ending_subscriptions_report from "biz-dashboard-frontend/src/components/ending-subscriptions-report/translations/en.json";
import events_calendar from "biz-dashboard-frontend/src/components/events-calendar/translations/en.json";
import events_calendar_report from "biz-dashboard-frontend/src/components/events-calendar-report/translations/en.json";
import expiring_cards_report from "biz-dashboard-frontend/src/components/expiring-cards-report/translations/en.json";
import fsq_program from "biz-dashboard-frontend/src/components/fsq-program/translations/en.json";
import fsq_program_report from "biz-dashboard-frontend/src/components/fsq-program-report/translations/en.json";
import gives_back from "biz-dashboard-frontend/src/components/gives-back/translations/en.json";
import graph from "biz-dashboard-frontend/src/components/graph/translations/en.json";
import group_search_report from "biz-dashboard-frontend/src/components/group-search-report/translations/en.json";
import inactive_customers_report from "biz-dashboard-frontend/src/components/inactive-customers-report/translations/en.json";
import lifestyle_bonus_report from "biz-dashboard-frontend/src/components/lifestyle-bonus-report/translations/en.json";
import lifestyle_trip from "biz-dashboard-frontend/src/components/lifestyle-trip/translations/en.json";
import lifestyle_trip_organisation_report from "biz-dashboard-frontend/src/components/lifestyle-trip-organisation-report/translations/en.json";
import lifestyle_trip_report from "biz-dashboard-frontend/src/components/lifestyle-trip-report/translations/en.json";
import live_bonuses from "biz-dashboard-frontend/src/components/live-bonuses/translations/en.json";
import live_bonuses_bonus_report from "biz-dashboard-frontend/src/components/live-bonuses-bonus-report/translations/en.json";
import matching_bonus_report from "biz-dashboard-frontend/src/components/matching-bonus-report/translations/en.json";
import members_contest_bonus_report from "biz-dashboard-frontend/src/components/members-contest-bonus-report/translations/en.json";
import mentor_accelerator from "biz-dashboard-frontend/src/components/mentor-accelerator/translations/en.json";
import month_to_month_report from "biz-dashboard-frontend/src/components/month-to-month-report/translations/en.json";
import my_members_export_report from "biz-dashboard-frontend/src/components/my-members-export-report/translations/en.json";
import my_members_report from "biz-dashboard-frontend/src/components/my-members-report/translations/en.json";
import my_profile from "biz-dashboard-frontend/src/components/my-profile/translations/en.json";
import my_statistics from "biz-dashboard-frontend/src/components/my-statistics/translations/en.json";
import my_title from "biz-dashboard-frontend/src/components/my-title/translations/en.json";
import new_customers_report from "biz-dashboard-frontend/src/components/new-customers-report/translations/en.json";
import new_title_congratulations from "biz-dashboard-frontend/src/components/new-title-congratulations/translations/en.json";
import new_titles_report from "biz-dashboard-frontend/src/components/new-titles-report/translations/en.json";
import order_history_report from "biz-dashboard-frontend/src/components/order-history-report/translations/en.json";
import organisation_orders_report from "biz-dashboard-frontend/src/components/organisation-orders-report/translations/en.json";
import organisation_tree from "biz-dashboard-frontend/src/components/organisation-tree/translations/en.json";
import pausing_customers_report from "biz-dashboard-frontend/src/components/pausing-customers-report/translations/en.json";
import payouts_report from "biz-dashboard-frontend/src/components/payouts-report/translations/en.json";
import pcc_bonus from "biz-dashboard-frontend/src/components/pcc-bonus/translations/en.json";
import pcc_bonus_report from "biz-dashboard-frontend/src/components/pcc-bonus-report/translations/en.json";
import platinum_trip from "biz-dashboard-frontend/src/components/platinum-trip/translations/en.json";
import premium_challenge from "biz-dashboard-frontend/src/components/premium-challenge/translations/en.json";
import premium_challenge_report from "biz-dashboard-frontend/src/components/premium-challenge-report/translations/en.json";
import product_list_report from "biz-dashboard-frontend/src/components/product-list-report/translations/en.json";
import product_statistics from "biz-dashboard-frontend/src/components/product-statistics/translations/en.json";
import qv_booster_bonus_report from "biz-dashboard-frontend/src/components/qv-booster-bonus-report/translations/en.json";
import rank_advancement_bonus_report from "biz-dashboard-frontend/src/components/rank-advancement-bonus-report/translations/en.json";
import rank_advancement_challenge from "biz-dashboard-frontend/src/components/rank-advancement-challenge/translations/en.json";
import recruitment_contest_report from "biz-dashboard-frontend/src/components/recruitment-contest-report/translations/en.json";
import returns_in_your_organisation_report from "biz-dashboard-frontend/src/components/returns-in-your-organisation-report/translations/en.json";
import search_members_report from "biz-dashboard-frontend/src/components/search-members-report/translations/en.json";
import statistics_report from "biz-dashboard-frontend/src/components/statistics-report/translations/en.json";
import subscriptions_status_report from "biz-dashboard-frontend/src/components/subscriptions-status-report/translations/en.json";
import team_commission_bonus_report from "biz-dashboard-frontend/src/components/team-commission-bonus-report/translations/en.json";
import top_10_earners_report from "biz-dashboard-frontend/src/components/top-10-earners-report/translations/en.json";
import top_lifestyle_trip_qualifiers from "biz-dashboard-frontend/src/components/top-lifestyle-trip-qualifiers/translations/en.json";
import top20_recruitment_contest from "biz-dashboard-frontend/src/components/top20-recruitment-contest/translations/en.json";
import waiting_room from "biz-dashboard-frontend/src/components/waiting-room/translations/en.json";
import waiting_room_report from "biz-dashboard-frontend/src/components/waiting-room-report/translations/en.json";
import widget_10x from "biz-dashboard-frontend/src/components/widget-10x/translations/en.json";

type LocalNamespace =
  | "common"
  | "home"
  | "validation"
  | "languages"
  | "language"
  | "payment_information"
  | "sidebar"
  | "crop_profile_image"
  | "pin_management"
  | "language_management"
  | "translations"
  | "admin_users"
  | "manager_customers"
  | "agreement_notification"
  | "admin_notifications"
  | "pagination"
  | "bonus_archive_management"
  | "live_bonus_builder"
  | "topbar";

type CombinedNamespaces = LocalNamespace | ComponentsI18nNamespaces;

export type LocaleNamespaces = Record<
  CombinedNamespaces,
  { [key: string]: string }
>;
export const localNamespaces: LocaleNamespaces = {
  validation,
  home,
  languages,
  payment_information,
  common,
  sidebar,
  topbar,
  pin_management,
  crop_profile_image,
  language,
  translations,
  language_management,
  admin_users,
  manager_customers,
  active_legs_report,
  active_titles_status_report,
  arctic_challenge_report,
  business_kits_report,
  business_partners,
  business_partners_report,
  cab_accelerator,
  cab_accelerator_report,
  coaching_commission_report,
  commission_report,
  customers,
  director60_program,
  ending_subscriptions_report,
  events_calendar,
  events_calendar_report,
  expiring_cards_report,
  fsq_program,
  fsq_program_report,
  gives_back,
  graph,
  group_search_report,
  inactive_customers_report,
  lifestyle_bonus_report,
  lifestyle_trip,
  lifestyle_trip_organisation_report,
  lifestyle_trip_report,
  live_bonuses,
  live_bonuses_bonus_report,
  matching_bonus_report,
  members_contest_bonus_report,
  mentor_accelerator,
  month_to_month_report,
  my_members_export_report,
  my_members_report,
  my_profile,
  my_statistics,
  my_title,
  new_customers_report,
  new_title_congratulations,
  new_titles_report,
  order_history_report,
  organisation_orders_report,
  organisation_tree,
  pausing_customers_report,
  payouts_report,
  pcc_bonus,
  pcc_bonus_report,
  platinum_trip,
  premium_challenge,
  premium_challenge_report,
  product_list_report,
  product_statistics,
  qv_booster_bonus_report,
  rank_advancement_bonus_report,
  rank_advancement_challenge,
  recruitment_contest_report,
  returns_in_your_organisation_report,
  search_members_report,
  statistics_report,
  subscriptions_status_report,
  team_commission_bonus_report,
  top_10_earners_report,
  top_lifestyle_trip_qualifiers,
  top20_recruitment_contest,
  waiting_room,
  waiting_room_report,
  widget_10x,
  active_customer_overview,
  agreement_notification,
  admin_notifications,
  pagination,
  bonus_archive_management,
  live_bonus_builder,
};

const bundledResources = {
  en: {
    ...localNamespaces,
  },
  de: {
    ...localNamespaces,
  },
};

i18n.use(ChainedBackend).init({
  partialBundledLanguages: true,
  fallbackLng: "en",
  debug: false,
  ns: Object.keys(localNamespaces),
  interpolation: {
    escapeValue: false,
  },
  backend: {
    backends: [
      LocalStorageBackend,
      HttpBackend,
      resourcesToBackend(bundledResources),
    ],
    backendOptions: [
      {},
      {
        expirationTime: 7 * 24 * 60 * 60 * 1000,
        loadPath: "http://localhost:8080/api/translation/cached/{{lng}}/{{ns}}",
        parse(data: any, _language: string, namespace: LocalNamespace) {
          return {
            ...localNamespaces[namespace],
            ...JSON.parse(data),
          };
        },
      },
      {
        expirationTime: 7 * 24 * 60 * 60 * 1000,
      },
    ],
  },
  react: {
    useSuspense: true,
  },
});

export default i18n;

import { FunctionComponent, useContext } from "react";
import SimpleNavElement from "./components/SimpleNavElement";
import { useTranslation } from "react-i18next";
import CollapsedNavElement from "./components/CollapsedNavElement";
import StopDaemonNavButton from "./components/StopDaemonNavButton";
import UnlockModsNavButton from "./components/UnlockModsNavButton";
import Icon from "../Icon";
import { cn } from "../../helpers/cn";
import {
  SECTION_MEMBERS,
  MEMBERS_NAV_CHILDREN_COLLECTION,
  SECTION_STATISTICS,
  STATISTICS_NAV_CHILDREN_COLLECTION,
  SECTION_BONUS_REPORT,
  BONUS_REPORT_NAV_CHILDREN_COLLECTION,
  SECTION_SETTINGS,
  SETTINGS_NAV_CHILDREN_COLLECTION,
  SECTION_TRANSLATIONS,
  TRANSLATIONS_NAV_CHILDREN_COLLECTION,
  SECTION_ADMIN,
  ADMIN_NAV_CHILDREN_COLLECTION,
} from "./consts";
import { SidebarContext } from "../../context/SidebarContext";
import adminTool from "../../assets/images/admin-tools.png";

const Sidebar: FunctionComponent<{
  isOpen: boolean;
}> = ({ isOpen }) => {
  const { t } = useTranslation(["sidebar"]);
  const { openedSection } = useContext(SidebarContext);

  return (
    <div
      className={cn(
        `z-10 w-[63px] lg:w-[320px] overflow-hidden bg-bizDarkBlue-600 flex-col duration-300 lg:left-0 lg:flex absolute sm:static h-full lg:h-auto`,
        Boolean(openedSection)
          ? "w-[320px] overflow-visible"
          : "w-[320px] sm:w-[63px]",
        !isOpen ? "-left-80 sm:left-0 lg:w-[320px]" : "left-0 w-[320px]",
      )}
    >
      <div className="flex flex-col flex-1 items-stretch bg-bizDarkBlue-600 font-gothicBookRegular">
        <SimpleNavElement
          label={t("dashboard")}
          icon={<Icon name="dashboard" />}
          activeIcon={<Icon name="dashboardActive" />}
          route={"/"}
        />
        <CollapsedNavElement
          label={t("members")}
          section={SECTION_MEMBERS}
          icon={<Icon name="member" />}
          activeIcon={<Icon name="memberActive" />}
          childrenCollection={MEMBERS_NAV_CHILDREN_COLLECTION}
        />
        <CollapsedNavElement
          label={t("statistics")}
          section={SECTION_STATISTICS}
          icon={<Icon name="statistic" />}
          activeIcon={<Icon name="statisticActive" />}
          childrenCollection={STATISTICS_NAV_CHILDREN_COLLECTION}
        />
        <SimpleNavElement
          label={t("order_history_report")}
          icon={<Icon name="memo" />}
          activeIcon={<Icon name="memoActive" />}
          route={"/?do=orderhistory&section=orderhistory"}
        />
        <CollapsedNavElement
          label={t("bonus_report")}
          section={SECTION_BONUS_REPORT}
          icon={<Icon name="thumbUp" />}
          activeIcon={<Icon name="thumbUpActive" />}
          childrenCollection={BONUS_REPORT_NAV_CHILDREN_COLLECTION}
        />
        <CollapsedNavElement
          label={t("settings")}
          section={SECTION_SETTINGS}
          icon={<Icon name="callendar" />}
          activeIcon={<Icon name="callendarActive" />}
          childrenCollection={SETTINGS_NAV_CHILDREN_COLLECTION}
        />
        <CollapsedNavElement
          label={t("translations")}
          section={SECTION_TRANSLATIONS}
          icon={<Icon name="callendar" />}
          activeIcon={<Icon name="callendarActive" />}
          childrenCollection={TRANSLATIONS_NAV_CHILDREN_COLLECTION}
          accessFor={["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]}
        />
        <CollapsedNavElement
          label={t("admin")}
          section={SECTION_ADMIN}
          icon={<Icon url={adminTool} />}
          activeIcon={<Icon url={adminTool} />}
          childrenCollection={ADMIN_NAV_CHILDREN_COLLECTION}
          accessFor={["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]}
          className="bg-bizGreen-400 hover:bg-bizGreen-500 text-white border-t-[1px] border-t-[#59a4a6] border-b-[1px] border-b-[#046262] border-r-[1px] border-r-[#0e8181]"
          childrenClassName="mt-[12px]"
        />
        <StopDaemonNavButton />
        <UnlockModsNavButton />
        {/*<CollapsedNavChild*/}
        {/*  route={ROUTES.MANAGER_CUSTOMERS_PAGE}*/}
        {/*  label={t("customers")}*/}
        {/*/>*/}
        {/*<CollapsedNavChild*/}
        {/*  route={ROUTES.ADMIN_NOTIFICATIONS}*/}
        {/*  label={t("notifications")}*/}
        {/*/>*/}
      </div>
      <div className="flex flex-1" />
    </div>
  );
};

export default Sidebar;

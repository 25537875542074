import { FunctionComponent } from "react";

const Option: FunctionComponent<{ color: string }> = ({ color }) => {
  return (
    <div className="flex flex-1 justify-between pr-4">
      {color}
      <div className={`bg-[${color}] w-20 h-5`}></div>
    </div>
  );
};

export default Option;

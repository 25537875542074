import Navigation from "./navigation/Navigation";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./queryClient";
import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserProvider";
import { I18nextProvider, withTranslation } from "react-i18next";
import i18n from "./i18n";
import { FlashMessageProvider } from "./context/FlashMessageContext";

const AppWithProviders = withTranslation()(() => {
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <UserProvider>
            <FlashMessageProvider>
              <App />
            </FlashMessageProvider>
          </UserProvider>
        </AuthProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
});

const App = () => {
  return <Navigation />;
};

export default AppWithProviders;

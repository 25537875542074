import { useState, useEffect } from "react";
import { Role } from "biz-dashboard-frontend/lib/types/components/reports-core/types/Role";
import { useGetUserInfo } from "../api/UserInfo";
import useUser from "./useUser";

const accessFor: Role[] = ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"];

function useMods(): {
  modsEnabled: boolean;
  setEnabled(): boolean;
  setDisabled(): boolean;
} {
  const { user } = useUser();
  const { data: userInfo } = useGetUserInfo({ id: user.custNo });
  const [modsEnabled, setModsEnabled] = useState<boolean>(false);

  const setEnabled = () => {
    const password = prompt("Enter password");
    if (password === "Easter2016") {
      return document.dispatchEvent(new Event("enable-mods"));
    }
    alert("Wrong password");
    return false;
  };
  const setDisabled = () => document.dispatchEvent(new Event("disable-mods"));

  useEffect(() => {
    const isAdmin = userInfo?.roles.some((role) => accessFor.includes(role));
    if (isAdmin) {
      setModsEnabled(localStorage.getItem("mods-enabled") === "true");
    }
    document.addEventListener("enable-mods", () => {
      if (isAdmin) {
        setModsEnabled(true);
        localStorage.setItem("mods-enabled", String(true));
      }
    });

    document.addEventListener("disable-mods", () => {
      setModsEnabled(false);
      localStorage.setItem("mods-enabled", String(false));
    });
  }, []);

  return {
    modsEnabled,
    setEnabled,
    setDisabled,
  };
}

export default useMods;

import React, { FunctionComponent } from "react";
import TextInput from "../../../../components/Form/TextInput";
import ButtonSubmit from "../../../../components/Form/ButtonSubmit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePointsFromCustomerToCustomer } from "../../../../api/CommissionModify";
import FlashMessageHandler from "../../../../components/FlashMessageHandler";
import Alert from "../../../../components/Alert";
import useFlashMessage from "../../../../hooks/useFlashMessage";
import { FormRow } from "../../../../components/FormRow";

const PointsFromCustomerToCustomerForm: FunctionComponent<{
  bonusRunHistoryId: string;
}> = ({ bonusRunHistoryId }) => {
  const { t } = useTranslation("bonus_archive_management");
  const { displayFlashMessage } = useFlashMessage();
  const { mutate } = usePointsFromCustomerToCustomer({
    onError: (error) => {
      displayFlashMessage("PointsFromCustomerToCustomer", {
        description:
          error?.response?.status === 400 ? "invalid_data" : "error_occured",
        data: { type: "danger" },
      });
    },
    onSuccess: () => {
      reset();
      displayFlashMessage("PointsFromCustomerToCustomer", {
        description: "data_has_been_saved",
      });
    },
  });
  const schema = yup
    .object({
      customerFromId: yup.string().required("field_required"),
      customerToId: yup.string().required("field_required"),
      qv: yup.string().required("field_required"),
      bv: yup.string().required("field_required"),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<{
    customerFromId: string;
    customerToId: string;
    qv: string;
    bv: string;
  }>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: undefined,
  });
  return (
    <>
      <FlashMessageHandler
        id={"PointsFromCustomerToCustomer"}
        render={({ description, data: { type = "success" } }) => (
          <Alert type={type}>{t(description, { ns: "validation" })}</Alert>
        )}
      />
      <form
        className="flex flex-wrap items-center gap-x-2"
        onSubmit={handleSubmit(({ customerFromId, customerToId, qv, bv }) => {
          mutate({ customerFromId, customerToId, qv, bv, bonusRunHistoryId });
        })}
      >
        <FormRow label={t("from_customer")} labelClassName="sm:w-auto pr-2">
          <TextInput
            {...register("customerFromId")}
            errorMessage={errors.customerFromId?.message}
          />
        </FormRow>
        <FormRow label={t("to_customer")} labelClassName="sm:w-auto pr-2">
          <TextInput
            {...register("customerToId")}
            errorMessage={errors.customerToId?.message}
          />
        </FormRow>
        <FormRow label={t("qv")} labelClassName="sm:w-auto pr-2">
          <TextInput {...register("qv")} errorMessage={errors.qv?.message} />{" "}
        </FormRow>
        <FormRow label={t("bv")} labelClassName="sm:w-auto pr-2">
          <TextInput {...register("bv")} errorMessage={errors.bv?.message} />
        </FormRow>
        <ButtonSubmit size="sm" colorScheme="white">
          {t("submit", { ns: ["common"] })}
        </ButtonSubmit>
      </form>
    </>
  );
};

export default PointsFromCustomerToCustomerForm;

import { FunctionComponent, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import LangageSelect from "../../components/LanguageSelect/LangageSelect";
import { useUpdateCustomerLanguageMutation } from "../../api/Language";
import Alert from "../../components/Alert";
import useUser from "../../hooks/useUser";
import { useGetUserInfo } from "../../api/UserInfo";

const LanguagePage: FunctionComponent = (): JSX.Element => {
  const { t, i18n } = useTranslation(["language"]);

  const { user } = useUser();
  const { data: userInfo } = useGetUserInfo({ id: user.custNo });

  const [successMessage, setSuccessMessage] = useState<string>();

  const { mutate } = useUpdateCustomerLanguageMutation({
    onError: (error) => {},
    onSuccess: () => {
      setSuccessMessage("data_has_been_saved");
    },
  });

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      {successMessage && (
        <Alert type="success">{t(successMessage, { ns: "common" })}</Alert>
      )}
      <PageHeaderWrapper>
        <PageHeader>{t("change_language").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      {userInfo && (
        <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
          <span className="pb-2">{t("your_current_language_is")}:</span>
          <LangageSelect
            menuPlacement={"bottom"}
            variant={"light"}
            value={userInfo.lang}
            onChange={(language) => {
              i18n.changeLanguage(language);
              mutate({
                lang: language,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LanguagePage;

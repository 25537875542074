import React from "react";
import Sprite from "../Sprite";

const iconProps = {
  dashboard: {
    x: 3,
    y: -85,
  },
  dashboardActive: {
    x: -30,
    y: -85,
  },
  member: {
    x: 3,
    y: -151,
  },
  memberActive: {
    x: -30,
    y: -151,
  },
  statistic: {
    x: 3,
    y: -217,
  },
  statisticActive: {
    x: -30,
    y: -217,
  },
  memo: {
    x: 3,
    y: -284,
  },
  memoActive: {
    x: -30,
    y: -284,
  },
  thumbUp: {
    x: 3,
    y: -349,
  },
  thumbUpActive: {
    x: -30,
    y: -349,
  },
  callendar: {
    x: 3,
    y: -415,
  },
  callendarActive: {
    x: -30,
    y: -415,
  },
  cross: {
    x: -185,
    y: -716,
  },
  info: {
    x: -184,
    y: -210,
    width: 22,
    height: 22,
  },
  login: {
    x: -182,
    y: -116,
    width: 24,
    height: 24,
  },
  lock: {
    x: 8,
    y: -525,
    width: 32,
    height: 32,
  },
  user: {
    x: 8,
    y: -485,
    width: 32,
    height: 32,
  },
  leftArrowSkyBlue: {
    x: -125,
    y: -54,
    width: 7,
    height: 12,
  },
  rightArrowSkyBlue: {
    x: -143,
    y: -54,
    width: 7,
    height: 12,
  },
  cart: {
    x: -176,
    y: -577,
    width: 32,
    height: 32,
  },
  userRegister: {
    x: 0,
    y: -43,
    width: 32,
    height: 32,
  },
  rightArrow: {
    x: -164,
    y: -54,
    width: 7,
    height: 12,
  },
  hamburger: {
    x: -156,
    y: -391,
    width: 34,
    height: 34,
  },
  exit: {
    x: -180,
    y: -110,
    width: 34,
    height: 34,
  },
} as const;

type IconName = keyof typeof iconProps;

type IconPopsType =
  | {
      name: IconName;
      className?: string;
      url?: never;
    }
  | { className?: string; url: string; name?: never };

function Icon({ name, className, url }: IconPopsType) {
  return name ? (
    <Sprite className={className} width={26} height={26} {...iconProps[name]} />
  ) : (
    <img src={url} alt="icon" />
  );
}

export default Icon;

import { forwardRef } from "react";
import { ErrorMessage } from "../index";

type PasswordInputProps = {
  errorMessage?: string;
};

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ errorMessage, ...rest }, ref) => {
    return (
      <div className="flex flex-col w-48">
        <input
          type="password"
          ref={ref}
          {...rest}
          className="pl-2 rounded-[3px] shadow-dashboard-input rounded-none rounded-l-sm rounded-r-sm bg-white text-gray-900 block flex-1 min-w-0 w-full text-sm border-gray-300 p-1.5 focus:outline-none"
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  },
);

export default PasswordInput;

import { useEffect } from "react";
import { useGetUserInfo } from "../api/UserInfo";
import useUser from "./useUser";

const TRANSLATION_LAST_UPDATE = "translation_last_update";

const useValidateTranslations = () => {
  const { user } = useUser();
  const { data: userInfo } = useGetUserInfo({ id: user.custNo });

  useEffect(() => {
    if (!userInfo?.translationLastUpdate) {
      window.localStorage.setItem(
        TRANSLATION_LAST_UPDATE,
        `${new Date().getTime()}`,
      );
      return;
    }

    const savedTranslationLastUpdateTime = Number(
      window.localStorage.getItem(TRANSLATION_LAST_UPDATE),
    );
    const currentTranslationLastUpdateTime = new Date(
      userInfo.translationLastUpdate,
    ).getTime();

    if (savedTranslationLastUpdateTime < currentTranslationLastUpdateTime) {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("i18next_")) {
          localStorage.removeItem(key);
        }
      });
      window.localStorage.setItem(
        TRANSLATION_LAST_UPDATE,
        `${currentTranslationLastUpdateTime}`,
      );
    }
  }, [userInfo?.translationLastUpdate]);
};

export default useValidateTranslations;

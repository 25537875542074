import { FunctionComponent } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import AddPinForm from "./AddPinForm";
import { useIsPinRequiredQuery } from "../../api/Auth";
import EditPinForm from "./EditPinForm";
import DeletePinForm from "./DeletePinForm";
import useAuth from "../../hooks/useAuth";

const PinManagementPage: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation(["pin_management"]);

  const {
    auth: { username },
  } = useAuth();

  const { data } = useIsPinRequiredQuery({
    username: username,
  });

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      <PageHeaderWrapper>
        <PageHeader>{t("pin_settings").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        {!data?.pinNeeded ? (
          <AddPinForm />
        ) : (
          <div className="flex flex-col">
            <EditPinForm />
            <DeletePinForm />
          </div>
        )}
      </div>
    </div>
  );
};

export default PinManagementPage;

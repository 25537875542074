import { t } from "i18next";
import React from "react";
import PageHeader from "../../../components/PageHeader";
import PageHeaderWrapper from "../../../components/PageHeaderWrapper";
import { useTranslation } from "react-i18next";
import LiveBonusBuilderForm from "./form/LiveBonusBuilderForm";

function LiveBonusBuilder() {
  const { t } = useTranslation("live_bonus_builder");
  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8 w-full">
      <PageHeaderWrapper>
        <PageHeader>{t("header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        <LiveBonusBuilderForm />
      </div>
    </div>
  );
}

export default LiveBonusBuilder;

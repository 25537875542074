import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddAdminUserMutation, useEditAdminUserMutation,
  useGetAdminUser,
} from "../../../api/AdminUser";
import ROUTES from "../../../navigation/route";
import useFlashMessage from "../../../hooks/useFlashMessage";
import PageHeaderWrapper from "../../../components/PageHeaderWrapper";
import PageHeader from "../../../components/PageHeader";
import { AdminUserForm } from "./form/AdminUserForm";

const EditLanguagePage: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation("admin_users");
  const { displayFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  let { id } = useParams();

  const { data } = useGetAdminUser({ id: Number(id) });

  const { mutate, isPending } = useEditAdminUserMutation({
    onError: () => {},
    onSuccess: () => {
      displayFlashMessage("AdminUserPage:success", {
        description: "data_has_been_saved",
      });
      navigate(ROUTES.ADMIN_USERS_PAGE);
    },
  });

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      <PageHeaderWrapper>
        <PageHeader>{t("add_user_header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        {data && (
          <AdminUserForm
            formData={data}
            onSubmit={(data) => {
              mutate(data);
            }}
            isLoading={isPending}
          />
        )}
      </div>
    </div>
  );
};

export default EditLanguagePage;

import { useMutation, useQuery } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";
import queryClient, { BasicMutationOptions } from "../queryClient";
import { AxiosError } from "axios";
import { isEmpty, pickBy } from "lodash";

export type BankData = {
  customerBankData: CustomerBankData;
  customerExtraBankData: CustomerExtraBankData;
};

export type CustomerBankData = {
  custNo: number;
  account: string;
  swift: string;
  bank: string;
  company: string;
  orgNr: string;
  personNr: string;
  carProgram: string;
  currency: string;
};

export type CustomerExtraBankData = {
  fiscalCode: string;
  birthDate: string;
  birthPlace: string;
  birthProvince: string;
  nationality: string;
  residenceProvince: string;
  documentType: string;
  documentNumber: string;
  documentIssuer: string;
  documentDate: string;
  documentExpirationDate: string;
  employmentStatus: string;
};

export const useGetBankData = () => {
  const { privateApiClient } = useApiClient();
  return useQuery<BankData, unknown>({
    queryKey: ["BankData"],
    queryFn: async () =>
      camelcaseKeys(
        (await privateApiClient.get(`/customer/settings/bank/data`)).data,
        { deep: true },
      ),
    initialData: undefined,
  });
};

export type CustomerBankDataBaseUpdateModel = {
  account?: string;
  swift?: string;
  bank?: string;
  company?: string;
  orgNr?: string;
  personNr?: string;
  carProgram?: boolean;
};

export type CustomerBankDataExtraUpdateModel = {
  fiscalCode?: string;
  birthDate?: string;
  birthPlace?: string;
  birthProvince?: string;
  nationality?: string;
  residenceProvince?: string;
  documentType?: string;
  documentNumber?: string;
  documentIssuer?: string;
  documentDate?: string;
  documentExpirationDate?: string;
  employmentStatus?: string;
};

type CustomerBankDataBaseUpdateErrorResponseData = Record<
  keyof CustomerBankDataBaseUpdateModel,
  string[]
>;
type CustomerBankDataExtraUpdateErrorResponseData = Record<
  keyof CustomerBankDataExtraUpdateModel,
  string[]
>;

export const useUpdateBankDataMutation = (
  mutationOptions?: BasicMutationOptions<
    CustomerBankDataBaseUpdateModel | CustomerBankDataExtraUpdateModel,
    AxiosError<{
      base: CustomerBankDataBaseUpdateErrorResponseData;
      extra: CustomerBankDataExtraUpdateErrorResponseData;
    }>
  >,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<
    CustomerBankDataBaseUpdateModel & CustomerBankDataExtraUpdateModel,
    AxiosError<{
      base: CustomerBankDataBaseUpdateErrorResponseData;
      extra: CustomerBankDataExtraUpdateErrorResponseData;
    }>,
    CustomerBankDataBaseUpdateModel & CustomerBankDataExtraUpdateModel
  >({
    mutationKey: ["BankData"],
    mutationFn: async ({
      account,
      swift,
      bank,
      company,
      orgNr,
      personNr,
      carProgram,
      ...rest
    }) => {
      const base = pickBy(
        {
          account,
          swift,
          bank,
          company,
          orgNr,
          personNr,
          carProgram,
        },
        (value) => value !== undefined && value !== "",
      );
      return (
        await privateApiClient.put(`/customer/settings/bank/data`, {
          base,
          ...(!isEmpty(rest) ? { extra: rest } : undefined),
        })
      ).data;
    },
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["BankData"] }),
  });
};

export const useDeleteBankDataMutation = (
  mutationOptions?: BasicMutationOptions<{}, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<{}, AxiosError>({
    mutationKey: ["remindPassword"],
    mutationFn: async () =>
      await privateApiClient.delete(`/customer/settings/bank/data`),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["BankData"] }),
  });
};

import { useState } from "react";

function useLocalStorage<T>(key: string, initialValue: T) {
  const storageKey = (key: string) => `biz:${key}`;

  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(storageKey(key));
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(
          storageKey(key),
          JSON.stringify(valueToStore),
        );
      }
    } catch (error) {}
  };
  return [storedValue, setValue] as const;
}

export default useLocalStorage;

import { AxiosError } from "axios";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRemindPasswordMutation } from "../../api/Auth";
import Alert from "../../components/Alert";
import { HttpStatusCode } from "../../enums/HttpStatusCode";
import RemindPasswordForm from "./form/RemindPasswordForm";
import Icon from "../../components/Icon";

const RemindPassword: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation(['home']);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();

  const remindPasswordMutation = useRemindPasswordMutation({
    onSuccess: () => {
      setSuccessMessage("email_has_been_sent");
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === HttpStatusCode.BAD_REQUEST) {
        setErrorMessage("we_can_not_find_such_a_user");
      } else if (error.code === AxiosError.ERR_NETWORK) {
        setErrorMessage("unexpected_error_occured");
      } else {
        setErrorMessage("unexpected_error_occured");
      }
    },
  });

  return (
    <div className="flex flex-col flex-1 h-full max-w-xl bg-bizDarkBlue-500 pb-9 font-gothicBookRegular">
      <div className="flex bg-skyBlue-600 h-20 rounded-t justify-center items-center">
        <Icon name="login" />
        <h1 className="pl-4 text-white uppercase">{t("forgotten_password")}</h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-1 flex-col py-8 px-8 sm:px-16">
          <div className="text-white pb-7 flex text-sm">
            <div className="py-2 pr-2">
              <Icon name="info" />
            </div>
            {t("remind_password_description")}
          </div>
          {successMessage && <Alert type="success">{t(successMessage)}</Alert>}
          {errorMessage && <Alert type="danger">{t(errorMessage)}</Alert>}
          <RemindPasswordForm
            onSubmit={({ username, email }) =>
              remindPasswordMutation.mutate({
                username,
                email,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RemindPassword;

import { FunctionComponent } from "react";
import ReactSelect from "react-select";
import Option from "./Option";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { colors } from "../../theme/colors";

const allColors = Object.entries(colors).reduce<Record<string, string>[]>(
  (acc, [color, variants]) => {
    const colorVariants = Object.entries(variants).map(([variant, value]) => ({
      [`bg-${color}-${variant}`]: value,
    }));
    return [...acc, ...colorVariants];
  },
  [],
);

const customStylesDark = {
  singleValue: (provided: any) => ({
    ...provided,
    height: "100%",
    color: "white",
    paddingTop: "3px",
    cursor: "pointer",
  }),
  control: (base: any, state: any) => ({
    ...base,
    background: "#303a49",
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "#3d495c" : "#3d495c",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#00BCE6" : "#00BCE6",
    },
    cursor: "pointer",
  }),
};

const customStylesLight = {
  singleValue: (provided: any) => ({
    ...provided,
    height: "100%",
    paddingTop: "3px",
    cursor: "pointer",
  }),
  control: (base: any, state: any) => ({
    ...base,
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "#3d495c" : "#ffffff",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: !state.isFocused ? "#00BCE6" : "#ffffff",
    },
    cursor: "pointer",
  }),
};

const ColorSelect: FunctionComponent<{
  onChange?: (language: string) => void;
  variant?: string;
  menuPlacement?: "top" | "bottom";
  value: string;
}> = ({ onChange, variant = "dark", menuPlacement = "top", value }) => {
  return (
    <div className={`flex w-64`}>
      <ReactSelect
        styles={variant === "light" ? customStylesLight : customStylesDark}
        className={`w-64 color-white  cursor-pointer`}
        menuPlacement={menuPlacement}
        value={{ label: <Option color={value} />, value }}
        options={allColors.flatMap((colors) =>
          Object.values(colors).map((color) => ({
            label: <Option color={color} />,
            value: color,
          })),
        )}
        onChange={(selectedOption) => {
          onChange && onChange(String(selectedOption?.value));
        }}
      />
    </div>
  );
};

export default ColorSelect;

import { FunctionComponent } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import { LanguageForm } from "./form/LanguageForm";
import {
  useGetLanguageList,
  useUpdateLanguageMutation,
} from "../../api/Language";
import { useNavigate, useParams } from "react-router-dom";
import ROUTES from "../../navigation/route";
import useFlashMessage from "../../hooks/useFlashMessage";

const PaymentInformationPage: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation("language_management");

  let { id: languageId } = useParams();
  const navigate = useNavigate();
  const { displayFlashMessage } = useFlashMessage();

  const { mutate, isPending } = useUpdateLanguageMutation({
    onError: (error) => {},
    onSuccess: () => {
      displayFlashMessage("LanguageManagementPage:success", {
        description: "data_has_been_saved",
      });
      navigate(ROUTES.LANGUAGE_MANAGEMENT);
    },
  });

  const { data } = useGetLanguageList();

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      <PageHeaderWrapper>
        <PageHeader>{t("header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>

      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        {data?.find(({ id }) => String(id) === languageId) !== undefined && (
          <LanguageForm
            onSubmit={(data) => mutate(data)}
            isLoading={isPending}
            formData={data.find(({ id }) => String(id) === languageId)}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentInformationPage;

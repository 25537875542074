import { FunctionComponent, useState } from "react";
import {
  useGetDaemonStatus,
  useStartDaemon,
  useStopDaemon,
} from "../../../api/Daemon";
import Loader from "../../Loader";
import icon from "../../../assets/images/stop-daemon.png";
import iconHover from "../../../assets/images/stop-daemon-hover.png";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon";
import { useGrantAccess } from "../../../hooks/useGrantAccess";
import { Role } from "biz-dashboard-frontend/lib/types/components/reports-core/types/Role";
import useMods from "../../../hooks/useMods";

const accessFor: Role[] = ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"];

const StopDaemonNavButton: FunctionComponent = () => {
  const { modsEnabled } = useMods();
  const { isAccessGranted } = useGrantAccess(accessFor);
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation(["sidebar"]);
  const { data } = useGetDaemonStatus();
  const { mutate: startDaemon, isPending: isStartDaemonLoading } =
    useStartDaemon();
  const { mutate: stopDaemon, isPending: isStopDaemonLoading } =
    useStopDaemon();

  if (!isAccessGranted || !modsEnabled) {
    return null;
  }

  const isLoading = isStartDaemonLoading || isStopDaemonLoading;
  const label = data?.status ? t("stop_daemon") : t("start_daemon");

  const handleDaemonClick = () => {
    if (isLoading) return;

    data?.status ? stopDaemon() : startDaemon();
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const renderIcon = () => {
    if (isLoading) return <Loader />;

    return (
      <div className="pl-1 shrink-0">
        <Icon url={isHover ? iconHover : icon} />
      </div>
    );
  };

  return (
    <div
      onClick={handleDaemonClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="cursor-pointer flex flex-col items-stretch bg-bizGray-200 border-b-bizGray-200 border-t-bizGray-200 hover:border-b-intenseRed-600 hover:border-t-intenseRed-600 border-t mb-[1px]"
    >
      <div className="text-intenseRed-600 py-3.5 pl-3.5 bg-bizGray-200 hover:bg-intenseRed-600 hover:text-white flex items-center border-b-bizGray-200 hover:border-b-intenseRed-600 border-b font-gothicBookRegular text-sm">
        <>
          {renderIcon()}
          <span className="pl-6 text-ellipsis text-nowrap">
            {label.toUpperCase()}
          </span>
        </>
      </div>
    </div>
  );
};

export default StopDaemonNavButton;

import { FunctionComponent, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import Alert from "../../components/Alert";
import ButtonSubmit from "../../components/Form/ButtonSubmit";
import { useForm } from "react-hook-form";
import {
  useCreateProfileImageMutation,
  useDeleteProfileImageMutation,
  useProfileImage,
} from "../../api/useProfileImage";
import Button from "../../components/Button";
import ExtendedErrorAlert from "../../components/ExtendedErrorAlert";

type FormValues = {
  file: FileList;
};

const CropProfileImagePage: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation(["crop_profile_image", "common"]);

  const [successMessage, setSuccessMessage] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  const {
    handleSubmit,
    setError,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
  });

  const { mutate } = useCreateProfileImageMutation({
    onSuccess: () => {
      setSuccessMessage("data_has_been_saved");
    },
    onError: (error) => {
      error?.response &&
        setError("file", {
          type: "backend",
          message: error.response.data.file.join("/n"),
        });
    },
  });

  const { mutate: deleteProfileImage } = useDeleteProfileImageMutation({
    onSuccess: () => {
      setSuccessMessage("data_has_been_removed");
    },
  });

  const { data: profileImage } = useProfileImage();

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      <PageHeaderWrapper>
        <PageHeader>{t("title").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      {successMessage && !errors.file && (
        <div className="mt-4">
          <Alert type="success">{t(successMessage, { ns: "common" })}</Alert>
        </div>
      )}
      {errors.file?.message && (
        <div className="mt-4">
          <ExtendedErrorAlert
            description={
              <div>
                <div>
                  {t("file_name")}: {fileName}
                </div>
                <div>
                  {t("status")}: {t("failed")}
                </div>
                <div className="mt-2">{t("upload_error_title")}</div>
              </div>
            }
            messages={[errors.file?.message]}
          />
        </div>
      )}

      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600 max-w-xs	">
        <span className="pb-4">{t("upload_a_new_profile_picture")}:</span>
        <form
          className="flex flex-col"
          onSubmit={handleSubmit((data) => {
            mutate(data);
            setFileName(data.file[0].name);
          })}
        >
          <input
            className="m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 dark:border-neutral-600 bg-clip-padding py-[0.32rem] px-3 leading-[2.15] font-normal text-neutral-700 dark:text-neutral-200 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 dark:file:bg-neutral-700 file:px-3 file:py-[0.32rem] file:text-neutral-700 dark:file:text-neutral-100 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
            type="file"
            {...register("file")}
          />
          <div className={"pt-4"}>
            <ButtonSubmit size="sm">
              {t("submit", { ns: "common" })}
            </ButtonSubmit>
          </div>
        </form>
        {profileImage && (
          <>
            <span className="mt-4 pb-2">
              {t("remove_your_profile_picture")}:
            </span>
            <div>
              <Button
                size="sm"
                colorScheme="white"
                onClick={() => deleteProfileImage({})}
              >
                {t("remove", { ns: "common" })}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CropProfileImagePage;

import { FunctionComponent } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const RestrictedRoute: FunctionComponent<{
  to: string[];
}> = ({ to }) => {
  const { auth } = useAuth();
  const location = useLocation();
  return auth.isLoggedIn &&
    auth.roles?.length &&
    auth.roles.some((role) => to.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RestrictedRoute;

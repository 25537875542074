import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import useUser from "../hooks/useUser";
import UpdateBankDetailsInfo from "../components/UpdateBankDetailsInfo";
import { localNamespaces } from "../i18n";
import { useGetUserInfo } from "../api/UserInfo";
import { useTranslation } from "react-i18next";
import {
  NotificationType,
  useGetUserNotificationList,
  Notification,
} from "../api/UserNotification";
import PartnerAgreementNotification from "../components/PartnerAgreementNotification";
import Topbar from "../components/Topbar/Topbar";
import DefaultNotifications from "../components/DefaultNotifications";
import LoadingPage from "../pages/LoadingPage/LoadingPage";
import { SidebarContext, SidebarProvider } from "../context/SidebarContext";

type MainContentPropsType = {
  isPending: boolean;
  notifications?: Notification[];
};
const MainContent = ({ isPending, notifications }: MainContentPropsType) => {
  if (isPending) return <LoadingPage />;
  const { sidebarOpen } = useContext(SidebarContext);

  return (
    <>
      {Boolean(notifications?.length) && (
        <>
          <UpdateBankDetailsInfo
            notifications={notifications as Notification[]}
          />
          <DefaultNotifications
            notifications={notifications as Notification[]}
          />
        </>
      )}
      <div className="flex-1 grid grid-cols-1 sm:grid-cols-dashboard lg:grid-cols-dashboardLg min-h-full">
        <Sidebar isOpen={sidebarOpen} />
        <div className="flex w-full">
          <Outlet />
        </div>
      </div>
    </>
  );
};

const DashboardLayout: FunctionComponent = () => {
  const { user } = useUser();
  const { data: userInfo, isPending } = useGetUserInfo({ id: user.custNo });
  const { i18n } = useTranslation();
  const { data: notifications } = useGetUserNotificationList();

  const missingPartnerAgreementNotification = notifications?.find(
    ({ type }) => type === NotificationType.MissingPartnerAgreement,
  );

  useEffect(() => {
    userInfo && i18n.changeLanguage(userInfo?.lang);
  }, [userInfo]);
  i18n.loadNamespaces(Object.keys(localNamespaces));

  return (
    <main className="flex min-h-screen flex-col">
      <SidebarProvider>
        <Topbar user={user} />
        {!missingPartnerAgreementNotification && false ? (
          <PartnerAgreementNotification />
        ) : (
          <MainContent isPending={isPending} notifications={notifications} />
        )}
      </SidebarProvider>
    </main>
  );
};

export default DashboardLayout;

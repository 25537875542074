import React from "react";
import NotificationsForm from "./form/NotificationsForm";
import { useAddNotificationMutation } from "../../../api/UserNotification";
import ROUTES from "../../../navigation/route";
import { useNavigate } from "react-router-dom";
import useFlashMessage from "../../../hooks/useFlashMessage";
import Alert from "../../../components/Alert";
import FlashMessageHandler from "../../../components/FlashMessageHandler";
import PageHeader from "../../../components/PageHeader";
import PageHeaderWrapper from "../../../components/PageHeaderWrapper";
import { useTranslation } from "react-i18next";

function AddNotificationsPage() {
  const navigate = useNavigate();
  const { displayFlashMessage } = useFlashMessage();
  const { t } = useTranslation(["admin_notifications", "common"]);

  const { mutate: addNotification, isPending } = useAddNotificationMutation({
    onError: (error) => {
      displayFlashMessage("AdminAddNotificationPage", {
        description: error.response?.data?.message.join(" "),
        data: { type: "danger" },
      });
    },
    onSuccess: () => {
      displayFlashMessage("AdminNotificationPage", {
        description: "data_has_been_saved",
      });
      navigate(ROUTES.ADMIN_NOTIFICATIONS);
    },
  });
  const handleSubmit = (value: { message: string }) => {
    addNotification(value);
  };
  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      <PageHeaderWrapper>
        <PageHeader>{t("header_add").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        <FlashMessageHandler
          id={"AdminAddNotificationPage"}
          render={({ description, data: { type = "success" } }) => (
            <Alert type={type}>{t(description, { ns: "common" })}</Alert>
          )}
        />
        <NotificationsForm
          onSubmit={handleSubmit}
          isLoading={isPending}
        ></NotificationsForm>
      </div>
    </div>
  );
}

export default AddNotificationsPage;

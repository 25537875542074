import React from "react";
import TextInput from "../../../../components/Form/TextInput";
import ButtonSubmit from "../../../../components/Form/ButtonSubmit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGivePointsDirectly } from "../../../../api/CommissionModify";
import useFlashMessage from "../../../../hooks/useFlashMessage";
import FlashMessageHandler from "../../../../components/FlashMessageHandler";
import Alert from "../../../../components/Alert";
import { FormRow } from "../../../../components/FormRow";

function GivePointsDirectlyForm({
  bonusRunHistoryId,
}: {
  bonusRunHistoryId: string;
}) {
  const { t } = useTranslation("bonus_archive_management");
  const { displayFlashMessage } = useFlashMessage();
  const { mutate } = useGivePointsDirectly({
    onError: (error) => {
      displayFlashMessage("GivePointsDirectly", {
        description:
          error?.response?.status === 400 ? "invalid_data" : "error_occured",
        data: { type: "danger" },
      });
    },
    onSuccess: () => {
      reset();
      displayFlashMessage("GivePointsDirectly", {
        description: "data_has_been_saved",
      });
    },
  });
  const schema = yup
    .object({
      customerId: yup.string().required("field_required"),
      qv: yup.string().required("field_required"),
      bv: yup.string().required("field_required"),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<{
    customerId: string;
    qv: string;
    bv: string;
  }>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: undefined,
  });
  return (
    <>
      <FlashMessageHandler
        id={"GivePointsDirectly"}
        render={({ description, data: { type = "success" } }) => (
          <Alert type={type}>
            {t(description, { ns: ["validation", "common"] })}
          </Alert>
        )}
      />
      <form
        onSubmit={handleSubmit((value) =>
          mutate({ ...value, bonusRunHistoryId }),
        )}
        className="flex flex-wrap items-center gap-x-2"
      >
        <FormRow label={t("to_customer")} labelClassName="sm:w-auto pr-2">
          <TextInput
            {...register("customerId")}
            errorMessage={errors.customerId?.message}
          />
        </FormRow>
        <FormRow label={t("qv")} labelClassName="sm:w-auto pr-2">
          <TextInput {...register("qv")} errorMessage={errors.qv?.message} />
        </FormRow>
        <FormRow label={t("bv")} labelClassName="sm:w-auto pr-2">
          <TextInput {...register("bv")} errorMessage={errors.bv?.message} />
        </FormRow>
        <ButtonSubmit size="sm" colorScheme="white">
          {t("submit", { ns: ["common"] })}
        </ButtonSubmit>
      </form>
    </>
  );
}

export default GivePointsDirectlyForm;

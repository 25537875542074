import axios from "axios";
import useAuth from "./useAuth";
import useApiClient from "./useApiClient";

const useRememberMeLogin = () => {
  const {
    auth,
    setAuth,
    impersonatedAuth,
    setImpersonatedAuth,
    logoutImpersonatedUser,
  } = useAuth();

  const rememberMeLogin = async () => {
    console.log('REMEMBER ME LOGIN')
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/token/remember/login`,
      {
         refresh_token: auth.refreshToken,
      },
    );

    if (impersonatedAuth.accessToken) {
      const impersonatedAuthResponse = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/token/remember/login`,
        {
          refresh_token: impersonatedAuth.refreshToken,
        },
      );

      if (impersonatedAuthResponse.data.token) {
        setImpersonatedAuth({
          ...impersonatedAuth,
          accessToken: impersonatedAuthResponse.data.token,
        });
      } else {
        logoutImpersonatedUser();
      }
    }

    setAuth({
      ...auth,
      accessToken: response.data.token,
    });
    return response.data.token;
  };
  return rememberMeLogin;
};

export default useRememberMeLogin;

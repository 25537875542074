import { FunctionComponent } from "react";

type PinKeybaordButtonProps = {
  children?: string;
  disabled?: boolean;
  onClick?: () => void;
  danger?: boolean;
};

const PinKeybaordButton: FunctionComponent<PinKeybaordButtonProps> = ({
  children,
  disabled,
  onClick,
  danger,
}) => (
  <button
    onClick={onClick || undefined}
    disabled={disabled}
    className={`text-white h-11 w-20 bg-bizDarkBlue-600 hover:bg-skyBlue-600 ${
      danger ? "hover:bg-red-600" : "hover:bg-skyBlue-600"
    } transition-colors duration-500 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none`}
  >
    {children ?? ""}
  </button>
);

export default PinKeybaordButton;

export const colors = {
  seaBlue: {
    600: "#283996",
    500: "#7787BC",
    400: "#d5d8e7",
  },
  skyBlue: {
    700: "#008dab",
    600: "#00BCE6",
    500: "#85D0E8",
    400: "#00a7d1",
  },
  darkOlive: {
    600: "#517436",
    500: "#A1B287",
  },
  lightOlive: {
    600: "#9FBE3D",
    500: "#C5D388",
  },
  yellow: {
    900: "#CE9B1D",
    800: "#E5A606",
    700: "#E6B02A",
    600: "#F2C406",
    500: "#F9DD64",
    400: "#e7ce8d",
  },
  orange: {
    600: "#F76400",
    500: "#F99048",
  },
  darkRed: {
    600: "#671914",
    500: "#9B6F6D",
  },
  intenseRed: {
    600: "#E7131A",
    500: "#E88585",
  },
  black: {
    600: "#000000",
    500: "#888888",
  },
  almostBlack: {
    600: "#393939",
    500: "#888888",
  },
  bizDarkBlue: {
    700: "#354051",
    600: "#303a49",
    500: "#3d495c",
  },
  bizGray: {
    200: "#f1f1f1",
    300: "#d7d9dc",
    400: "#acb0b7",
    600: "#303a49",
    800: "#595959",
  },
  bizGreen: {
    400: "#059494",
    500: "#01848a",
  },
} as const;

export const colorSafeList = Object.entries(colors).reduce<string[]>(
  (acc, [color, shades]) => {
    const colorShades = Object.entries(shades).flatMap(([shade, value]) => [
      `bg-${color}-${shade}`,
      `bg-[${value}]`,
      `text-${color}-${shade}`,
      `text-[${value}]`,
    ]);
    return [...acc, ...colorShades];
  },
  [],
);

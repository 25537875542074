import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ROUTES from "../../navigation/route";
import { AdminUser } from "../../api/AdminUser";

const UsersCards: FunctionComponent<{ items: AdminUser[] }> = ({ items }) => {
  const { t } = useTranslation(["admin_users", "common"]);

  return (
    <div className="w-full">
      {items.map(
        ({
          id,
          email,
          roles,
          lang,
          firstName,
          lastName,
          login,
          updatedAt,
          createdAt,
          enabled,
        }) => (
          <div key={id} className="border-skyBlue-600 border-[1px] mb-1">
            <div className="flex p-2 bg-skyBlue-600">
              <span className="font-bold mr-2">{id}</span>
              <span className="font-bold">{login}</span>
              <span className="flex ml-auto">
                <Link
                  to={`${ROUTES.ADMIN_USERS_EDIT}/${id}`}
                  className="capitalize text-white hover:text-skyBlue-700 px-0.5"
                >
                  {t("edit", { ns: "common" })}
                </Link>
              </span>
            </div>
            <ul className="flex flex-col gap-1 p-2">
              <li className="">
                {t("email")}: <span className="font-bold">{email}</span>
              </li>
              <li className="">
                {t("first_name")}:{" "}
                <span className="font-bold">{firstName}</span>
              </li>
              <li className="">
                {t("last_name")}: <span className="font-bold">{lastName}</span>
              </li>
              <li className="">
                {t("enabled")}: <span className="font-bold">{enabled}</span>
              </li>
              <li className="">
                {t("lang")}: <span className="font-bold">{lang}</span>
              </li>
              <li className="">
                {t("created_at")}:{" "}
                <span className="font-bold">{createdAt}</span>
              </li>
              <li className="">
                {t("updated_at")}:{" "}
                <span className="font-bold">{updatedAt}</span>
              </li>
              <li className="">
                {t("roles")}: <span className="font-bold">{roles}</span>
              </li>
            </ul>
          </div>
        ),
      )}
    </div>
  );
};

export default UsersCards;

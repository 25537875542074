import { useState } from "react";
import UnlockIcon from "../../../assets/images/unlock-mods.png";
import LockIcon from "../../../assets/images/lock-mods.png";
import LockIconHover from "../../../assets/images/lock-mods-hover.png";
import Icon from "../../Icon";
import { cn } from "../../../helpers/cn";
import { Role } from "biz-dashboard-frontend/lib/types/components/reports-core/types/Role";
import { useGrantAccess } from "../../../hooks/useGrantAccess";
import { useTranslation } from "react-i18next";
import useMods from "../../../hooks/useMods";

const accessFor: Role[] = ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"];

const UnlockModsNavButton = () => {
  const { isAccessGranted } = useGrantAccess(accessFor);
  if (!isAccessGranted) {
    return null;
  }
  // TODO replace with hook imported from next verison lib
  const { modsEnabled, setEnabled, setDisabled } = useMods();
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation(["sidebar"]);
  const handleModeClick = () => (modsEnabled ? setDisabled() : setEnabled());

  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const renderIcon = () => {
    const lockIcon = isHover ? LockIcon : LockIconHover;
    return (
      <div className="pl-1 shrink-0">
        <Icon url={modsEnabled ? lockIcon : UnlockIcon} />
      </div>
    );
  };
  return (
    <div
      onClick={handleModeClick}
      onMouseEnter={handleMouseLeave}
      onMouseLeave={handleMouseEnter}
      className={cn(
        "cursor-pointer flex flex-col items-stretch border-t mb-[1px]",
        modsEnabled
          ? "border-b-yellow-900 border-t-white hover:border-t-yellow-600"
          : "border-b-yellow-800 border-t-yellow-800",
      )}
    >
      <div
        className={cn(
          "text-white py-3.5 pl-3.5 bg-yellow-700 hover:bg-yellow-800 flex items-center border-b-yellow-900 border-b font-gothicBookRegular text-sm",
          modsEnabled &&
            "text-yellow-900 border-b-white hover:text-white hover:border-b-yellow-800 bg-white",
        )}
      >
        <>
          {renderIcon()}
          <span className="pl-6 text-ellipsis text-nowrap">
            {(modsEnabled ? t("lock_mods") : t("unlock_mods")).toUpperCase()}
          </span>
        </>
      </div>
    </div>
  );
};

export default UnlockModsNavButton;

import { FunctionComponent, useState } from "react";
import {
  BankData,
  CustomerBankDataBaseUpdateModel,
  CustomerBankDataExtraUpdateModel,
  useDeleteBankDataMutation,
  useUpdateBankDataMutation,
} from "../../api/BankData";
import { useTranslation } from "react-i18next";
import { HttpStatusCode } from "../../enums/HttpStatusCode";
import useUser from "../../hooks/useUser";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRow } from "../../components/FormRow";
import TextInput from "../../components/Form/TextInput";
import { pickBy } from "lodash";
import Alert from "../../components/Alert";
import { useGetUserInfo } from "../../api/UserInfo";
import Button from "../../components/Button";
import ButtonSubmit from "../../components/Form/ButtonSubmit";

type FormValues = CustomerBankDataBaseUpdateModel &
  CustomerBankDataExtraUpdateModel;

type ObjectShapeValues =
  yup.ObjectShape extends Record<string, infer V> ? V : never;

type Shape<T extends Record<any, any>> = Partial<
  Record<keyof T, ObjectShapeValues>
>;

export const PaymentInformationForm: FunctionComponent<{
  bankData: BankData;
}> = ({
  bankData: {
    customerBankData: {
      account,
      swift,
      bank,
      company,
      orgNr,
      personNr,
      currency,
    },
    customerExtraBankData: {
      fiscalCode,
      birthDate,
      birthPlace,
      birthProvince,
      nationality,
      residenceProvince,
      documentType,
      documentNumber,
      documentIssuer,
      documentDate,
      documentExpirationDate,
      employmentStatus,
    },
  },
}) => {
  const { user } = useUser();
  const { data: userInfo } = useGetUserInfo({ id: user.custNo });

  const { t } = useTranslation(["payment_information", "common"]);

  const { mutate, isPending } = useUpdateBankDataMutation({
    onError: (error) => {
      if (error.response?.status === HttpStatusCode.BAD_REQUEST) {
        [
          ...Object.entries(error.response.data.base),
          ...(error.response.data.extra
            ? Object.entries(error.response.data.extra)
            : []),
        ].forEach(([field, messages]) => {
          setError(field as keyof FormValues, {
            type: "backend",
            message: messages.join("\n"),
          });
        });
      }
    },
    onSuccess: () => {
      setSuccessMessage("data_has_been_saved");
    },
  });

  const { mutate: deleteBankData } = useDeleteBankDataMutation({
    onSuccess: () => {
      setSuccessMessage("data_has_been_removed");
    },
  });

  const schema = yup
    .object<Shape<FormValues>>({
      account: yup.string().trim().required("field_required"),
      swift: yup.string().trim().required("field_required"),
      bank: yup.string().trim().required("field_required"),
    })
    .required();

  const {
    handleSubmit,
    setError,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      account,
      swift,
      bank,
      company,
      orgNr,
      personNr,
      ...(userInfo?.homeShop === "it"
        ? {
            fiscalCode,
            birthDate,
            birthPlace,
            birthProvince,
            nationality,
            residenceProvince,
            documentType,
            documentNumber,
            documentIssuer,
            documentDate,
            documentExpirationDate,
            employmentStatus,
          }
        : {}),
    },
  });

  const [successMessage, setSuccessMessage] = useState<string>();
  const onSubmit = (data: FormValues) => {
    mutate(pickBy(data, (value) => String(value)?.length > 0));
  };

  return (
    <form
      className="flex mt-8 flex-col"
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      {successMessage && (
        <Alert type="success">{t(successMessage, { ns: "common" })}</Alert>
      )}
      <FormRow label={t("customer_number")}>{user.custNo}</FormRow>
      <FormRow label={t("business_partner")}>
        {user.firstName} {user.lastName}
      </FormRow>
      <FormRow label={t("shop")}>{userInfo?.homeShop}</FormRow>
      <FormRow label={t("currency")}>{currency}</FormRow>
      <FormRow label={t("account_iban")}>
        <TextInput
          {...register("account")}
          errorMessage={errors.account?.message}
        />
      </FormRow>
      <FormRow label={t("swift_bic")}>
        <TextInput
          {...register("swift")}
          errorMessage={errors.swift?.message}
        />
      </FormRow>
      <FormRow label={t("bank_name")}>
        <TextInput {...register("bank")} errorMessage={errors.bank?.message} />
      </FormRow>
      <FormRow label={t("company")}>
        <TextInput
          {...register("company")}
          errorMessage={errors.company?.message}
        />
      </FormRow>
      <FormRow label={t("company_id")}>
        <TextInput
          {...register("orgNr")}
          errorMessage={errors.orgNr?.message}
        />
      </FormRow>
      <FormRow label={t("personal_id_number")}>
        <TextInput
          {...register("personNr")}
          errorMessage={errors.personNr?.message}
        />
      </FormRow>
      {userInfo?.homeShop === "it" && (
        <>
          <FormRow label="Codice fiscale">
            <TextInput
              {...register("fiscalCode")}
              errorMessage={errors.fiscalCode?.message}
            />
          </FormRow>
          <FormRow label="Data di nascita">
            <TextInput
              {...register("birthDate")}
              errorMessage={errors.birthDate?.message}
            />
          </FormRow>
          <FormRow label="Luogo di nascita">
            <TextInput
              {...register("birthPlace")}
              errorMessage={errors.birthPlace?.message}
            />
          </FormRow>
          <FormRow label="Provincia di nascita">
            <TextInput
              {...register("birthProvince")}
              errorMessage={errors.birthProvince?.message}
            />
          </FormRow>
          <FormRow label="Nazionalita">
            <TextInput
              {...register("nationality")}
              errorMessage={errors.nationality?.message}
            />
          </FormRow>
          <FormRow label="Provincia di residenza">
            <TextInput
              {...register("residenceProvince")}
              errorMessage={errors.residenceProvince?.message}
            />
          </FormRow>
          <FormRow label="Tipo di documento">
            <TextInput
              {...register("documentType")}
              errorMessage={errors.documentType?.message}
            />
          </FormRow>
          <FormRow label="Numero documento">
            <TextInput
              {...register("documentNumber")}
              errorMessage={errors.documentNumber?.message}
            />
          </FormRow>
          <FormRow label="Soggetto responsabile del rilascio del documento">
            <TextInput
              {...register("documentIssuer")}
              errorMessage={errors.documentIssuer?.message}
            />
          </FormRow>
          <FormRow label="Data del documento">
            <TextInput
              {...register("documentDate")}
              errorMessage={errors.documentDate?.message}
            />
          </FormRow>
          <FormRow label="Data di scadenza del documento">
            <TextInput
              {...register("documentExpirationDate")}
              errorMessage={errors.documentExpirationDate?.message}
            />
          </FormRow>
          <FormRow label="Stato di impiego">
            <TextInput
              {...register("employmentStatus")}
              errorMessage={errors.employmentStatus?.message}
            />
          </FormRow>
        </>
      )}
      <FormRow>
        <Button
          onClick={() => deleteBankData()}
          colorScheme="black-red"
          size="lg"
        >
          {t("delete_bank_data")}
        </Button>
        <ButtonSubmit size="lg" colorScheme="sky-blue" isLoading={isPending}>
          {t("save")}
        </ButtonSubmit>
      </FormRow>
    </form>
  );
};

import { FunctionComponent, ReactElement, useContext } from "react";
import { NavLink, To, useLocation } from "react-router-dom";
import { cn } from "../../../helpers/cn";
import { SidebarContext } from "../../../context/SidebarContext";
import { Role } from "biz-dashboard-frontend/lib/types/components/reports-core/types/Role";
import { useGrantAccess } from "../../../hooks/useGrantAccess";

const SimpleNavElement: FunctionComponent<{
  label: string;
  icon: ReactElement;
  activeIcon: ReactElement;
  route: To;
  accessFor?: Role[];
}> = ({
  label,
  icon,
  activeIcon,
  route,
  accessFor = [
    "ROLE_SUPER_ADMIN",
    "ROLE_USER",
    "ROLE_ADMIN",
    "ROLE_CUSTOMER",
    "ROLE_MANAGER",
  ],
}) => {
  const { isAccessGranted } = useGrantAccess(accessFor);
  if (!isAccessGranted) {
    return null;
  }
  const { setOpenedSection, setSidebarOpen } = useContext(SidebarContext);
  const { search } = useLocation();

  const isActive = `/${search}` === route;

  const isLgScreen = window.innerWidth > 1024;
  const isSmScreen = window.innerWidth < 640;

  const handleNavLinkClick = () => {
    if (isLgScreen) {
      return null;
    }
    if (isSmScreen) {
      setOpenedSection(null);
      setSidebarOpen(false);
      return;
    }
    setOpenedSection(null);
    return;
  };

  return (
    <div className="flex flex-col items-stretch bg-bizDarkBlue-600 border-b-bizDarkBlue-500 border-b ">
      <NavLink
        onClick={handleNavLinkClick}
        to={route}
        className="text-bizGray-400 py-3.5 pl-3.5 bg-bizDarkBlue-700 hover:bg-bizDarkBlue-600 flex items-center border-b-bizDarkBlue-600 border-b-2 font-gothicBookRegular text-sm"
      >
        <>
          <div className="pl-1">{isActive ? activeIcon : icon}</div>
          <span
            className={cn(
              "pl-6 text-ellipsis text-nowrap",
              isActive && "text-white",
            )}
          >
            {label.toUpperCase()}
          </span>
        </>
      </NavLink>
    </div>
  );
};

export default SimpleNavElement;

import { FunctionComponent } from "react";
import WidgetsPage from "../pages/widgets/WidgetsPage";
import CropProfileImagePage from "../pages/CropProfileImagePage/CropProfileImagePage";
import ReportWrapper from "../components/ReportWrapper";

import {
  SearchMembersReport,
  OrganisationTreeReport,
  ActiveLegsReport,
  ActiveTitlesStatusReport,
  BusinessKitsReport,
  BusinessPartnersReport,
  CabAcceleratorReport,
  CoachingCommissionReport,
  CommissionReport,
  EndingSubscriptionsReport,
  EventsCalendarReport,
  ExpiringCardsReport,
  FsqProgramReport,
  GroupSearchReport,
  LifestyleTripReport,
  LifestyleTripOrganisationReport,
  LiveBonusesBonusReport,
  MatchingBonusReport,
  MembersContestBonusReport,
  MonthToMonthReport,
  MyMembersExportReport,
  MyMembersReport,
  NewCustomersReport,
  NewTitlesReport,
  OrderHistoryReport,
  OrganisationOrdersReport,
  PausingCustomersReport,
  PayoutsReport,
  PccBonusReport,
  PremiumChallengeReport,
  ProductListReport,
  ProductStatisticsReport,
  QvBoosterBonusReport,
  RankAdvancementBonusReport,
  RecruitmentContestReport,
  SubscriptionsStatusReport,
  TeamCommissionBonusReport,
  Top10EarnersReport,
  TopLifestyleTripQualifiersReport,
  WaitingRoomReport,
  ArcticChallengeReport,
  ReturnsInYourOrganisationReport,
  LifestyleBonusReport,
  InactiveCustomersReport,
  StatisticsReport,
} from "biz-dashboard-frontend";

const PAGES: Record<
  string,
  {
    component: any;
    section: string;
    title: string;
  }
> = {
  // cropProfileImage: <CropProfileImagePage />,
  cropProfileImage: {
    component: <CropProfileImagePage />,
    section: "members",
    title: "CropProfileImagePage",
  },
  membersearch: {
    component: (
      <ReportWrapper
        Component={SearchMembersReport}
        namespace={"search_members_report"}
      />
    ),
    section: "members",
    title: "search_members_report",
  },
  tree: {
    component: (
      <ReportWrapper
        Component={OrganisationTreeReport}
        namespace={"organisation_tree"}
      />
    ),
    section: "members",
    title: "organisation_tree",
  },
  groupsearch: {
    component: (
      <ReportWrapper
        Component={GroupSearchReport}
        namespace={"group_search_report"}
      />
    ),
    section: "members",
    title: "group_search_report",
  },
  distributors: {
    component: (
      <ReportWrapper
        Component={BusinessPartnersReport}
        namespace={"business_partners_report"}
      />
    ),
    section: "members",
    title: "business_partners_report",
  },
  titlesstatus: {
    component: (
      <ReportWrapper
        Component={ActiveTitlesStatusReport}
        namespace={"active_titles_status_report"}
      />
    ),
    section: "members",
    title: "active_titles_status_report",
  },
  activelegs: {
    component: (
      <ReportWrapper
        Component={ActiveLegsReport}
        namespace={"active_legs_report"}
      />
    ),
    section: "members",
    title: "active_legs_report",
  },
  recruits: {
    component: (
      <ReportWrapper
        Component={MyMembersReport}
        namespace={"my_members_report"}
      />
    ),
    section: "members",
    title: "my_members_report",
  },
  myMembersExport: {
    component: (
      <ReportWrapper
        Component={MyMembersExportReport}
        namespace={"my_members_export_report"}
      />
    ),
    section: "members",
    title: "my_members_export_report",
  },
  inactives: {
    component: (
      <ReportWrapper
        Component={InactiveCustomersReport}
        namespace={"inactive_customers_report"}
      />
    ),
    section: "members",
    title: "inactive_customers_report",
  },
  newmembers: {
    component: (
      <ReportWrapper
        Component={NewCustomersReport}
        namespace={"new_customers_report"}
      />
    ),
    section: "members",
    title: "new_customers_report",
  },
  newbusinesskits: {
    component: (
      <ReportWrapper
        Component={BusinessKitsReport}
        namespace={"business_kits_report"}
      />
    ),
    section: "members",
    title: "business_kits_report",
  },
  newtitles: {
    component: (
      <ReportWrapper
        Component={NewTitlesReport}
        namespace={"new_titles_report"}
      />
    ),
    section: "members",
    title: "new_titles_report",
  },
  openordersreport: {
    component: (
      <ReportWrapper
        Component={OrganisationOrdersReport}
        namespace={"organisation_orders_report"}
      />
    ),
    section: "members",
    title: "organisation_orders_report",
  },
  productsStatisticsReport: {
    component: (
      <ReportWrapper
        Component={ProductStatisticsReport}
        namespace={"product_statistics"}
      />
    ),
    section: "members",
    title: "product_statistics",
  },
  endingSubscriptionsReport: {
    component: (
      <ReportWrapper
        Component={EndingSubscriptionsReport}
        namespace={"ending_subscriptions_report"}
      />
    ),
    section: "members",
    title: "ending_subscriptions_report",
  },
  pausingcustomers: {
    component: (
      <ReportWrapper
        Component={PausingCustomersReport}
        namespace={"pausing_customers_report"}
      />
    ),
    section: "members",
    title: "pausing_customers_report",
  },
  expiredcards: {
    component: (
      <ReportWrapper
        Component={ExpiringCardsReport}
        namespace={"expiring_cards_report"}
      />
    ),
    section: "members",
    title: "expiring_cards_report",
  },
  waitingroom: {
    component: (
      <ReportWrapper Component={WaitingRoomReport} namespace={"waiting_room"} />
    ),
    section: "members",
    title: "waiting_room",
  },
  distributors30day: {
    component: (
      <ReportWrapper
        Component={FsqProgramReport}
        namespace={"fsq_program_report"}
      />
    ),
    section: "members",
    title: "fsq_program_report",
  },
  stat: {
    component: (
      <ReportWrapper
        Component={StatisticsReport}
        namespace={"statistics_report"}
      />
    ),
    section: "statistics",
    title: "statistics_report",
  },
  recruitmentContestReport: {
    component: (
      <ReportWrapper
        Component={RecruitmentContestReport}
        namespace={"recruitment_contest_report"}
      />
    ),
    section: "statistics",
    title: "recruitment_contest_report",
  },
  top10EarnersReport: {
    component: (
      <ReportWrapper
        Component={Top10EarnersReport}
        namespace={"top_10_earners_report"}
      />
    ),
    section: "statistics",
    title: "top_10_earners_report",
  },
  lifestyleTripTop20Qualifiers: {
    component: (
      <ReportWrapper
        Component={TopLifestyleTripQualifiersReport}
        namespace={"top_lifestyle_trip_qualifiers"}
      />
    ),
    section: "statistics",
    title: "top_lifestyle_trip_qualifiers",
  },
  orderhistory: {
    component: (
      <ReportWrapper
        Component={OrderHistoryReport}
        namespace={"order_history_report"}
      />
    ),
    section: "orderhistory",
    title: "order_history_report",
  },
  bonusreport: {
    component: (
      <ReportWrapper
        Component={CommissionReport}
        namespace={"commission_report"}
      />
    ),
    section: "bonusdata",
    title: "commission_report",
  },
  payoutsreport: {
    component: (
      <ReportWrapper Component={PayoutsReport} namespace={"payouts_report"} />
    ),
    section: "bonusdata",
    title: "payouts_report",
  },
  pccbonusreport: {
    component: (
      <ReportWrapper
        Component={PccBonusReport}
        namespace={"pcc_bonus_report"}
      />
    ),
    section: "bonusdata",
    title: "pcc_bonus_report",
  },
  teamcommissionbonusreport: {
    component: (
      <ReportWrapper
        Component={TeamCommissionBonusReport}
        namespace={"team_commission_bonus_report"}
      />
    ),
    section: "bonusdata",
    title: "team_commission_bonus_report",
  },
  lifestylebonusreport: {
    component: (
      <ReportWrapper
        Component={LifestyleBonusReport}
        namespace={"lifestyle_bonus_report"}
      />
    ),
    section: "bonusdata",
    title: "lifestyle_bonus_report",
  },
  rankadvancementbonusreport: {
    component: (
      <ReportWrapper
        Component={RankAdvancementBonusReport}
        namespace={"rank_advancement_bonus_report"}
      />
    ),
    section: "bonusdata",
    title: "rank_advancement_bonus_report",
  },
  coachingcommissionreport: {
    component: (
      <ReportWrapper
        Component={CoachingCommissionReport}
        namespace={"coaching_commission_report"}
      />
    ),
    section: "bonusdata",
    title: "coaching_commission_report",
  },
  cabAcceleratorReport: {
    component: (
      <ReportWrapper
        Component={CabAcceleratorReport}
        namespace={"cab_accelerator_report"}
      />
    ),
    section: "bonusdata",
    title: "cab_accelerator_report",
  },
  liveBonusReport: {
    component: (
      <ReportWrapper
        Component={LiveBonusesBonusReport}
        namespace={"live_bonuses_bonus_report"}
      />
    ),
    section: "bonusdata",
    title: "live_bonuses_bonus_report",
  },
  qvBoosterReport: {
    component: (
      <ReportWrapper
        Component={QvBoosterBonusReport}
        namespace={"qv_booster_bonus_report"}
      />
    ),
    section: "bonusdata",
    title: "qv_booster_bonus_report",
  },
  memberscontestbonusreport: {
    component: (
      <ReportWrapper
        Component={MembersContestBonusReport}
        namespace={"members_contest_bonus_report"}
      />
    ),
    section: "bonusdata",
    title: "members_contest_bonus_report",
  },
  arcticChallenge: {
    component: (
      <ReportWrapper
        Component={ArcticChallengeReport}
        namespace={"arctic_challenge_report"}
      />
    ),
    section: "bonusdata",
    title: "arctic_challenge_report",
  },
  lifestyleTripDetails: {
    component: (
      <ReportWrapper
        Component={LifestyleTripReport}
        params={{
          year: 2025
        }}
        namespace={"lifestyle_trip_report"}
      />
    ),
    section: "bonusdata",
    title: "lifestyle_trip_report",
  },
  premiumChallenge: {
    component: (
      <ReportWrapper
        Component={PremiumChallengeReport}
        namespace={"premium_challenge_report"}
      />
    ),
    section: "bonusdata",
    title: "premium_challenge_report",
  },
  bonusactivelegs: {
    component: (
      <ReportWrapper
        Component={ActiveLegsReport}
        namespace={"active_legs_report"}
      />
    ),
    section: "bonusdata",
    title: "active_legs_report",
  },
  productList: {
    component: (
      <ReportWrapper
        Component={ProductListReport}
        namespace={"product_list_report"}
      />
    ),
    section: "bonusdata",
    title: "product_list_report",
  },
  eventsCalendar: {
    component: (
      <ReportWrapper
        Component={EventsCalendarReport}
        namespace={"events_calendar"}
      />
    ),
    section: "bonusdata",
    title: "product_list_report",
  },
  // leadershipbonusreport: {
  //   component:
  //     <ReportWrapper
  //       Component={PayoutsReport}
  //       namespace={"payouts_report"}
  //     />,
  //   section: 'bonusdata',
  //   title: "payouts_report"
  // },
};
const DashboardNavigation: FunctionComponent<{ route: string }> = ({
  route,
}) => {
  return <>{getPageByRoute(route)}</>;
};

const getPageByRoute = (route: string) => {
  return PAGES?.[route].component ?? <WidgetsPage />;
};

export default DashboardNavigation;

import React from "react";
import TextInput from "../../../../components/Form/TextInput";
import ButtonSubmit from "../../../../components/Form/ButtonSubmit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePointsToCurrentMonth } from "../../../../api/CommissionModify";
import FlashMessageHandler from "../../../../components/FlashMessageHandler";
import useFlashMessage from "../../../../hooks/useFlashMessage";
import Alert from "../../../../components/Alert";
import { FormRow } from "../../../../components/FormRow";

function PointsToCurrentMonthForm({
  bonusRunHistoryId,
}: {
  bonusRunHistoryId: string;
}) {
  const { displayFlashMessage } = useFlashMessage();

  const { t } = useTranslation("bonus_archive_management");
  const { mutate } = usePointsToCurrentMonth({
    onError: (error) => {
      displayFlashMessage("PointsToCurrentMonthForm", {
        description:
          error?.response?.status === 400 ? "invalid_data" : "error_occured",
        data: { type: "danger" },
      });
    },
    onSuccess: () => {
      reset();
      displayFlashMessage("PointsToCurrentMonthForm", {
        description: "data_has_been_saved",
      });
    },
  });
  const schema = yup
    .object({
      orderNumber: yup.string().required("field_required"),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<{
    orderNumber: string;
  }>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: undefined,
  });
  return (
    <>
      <FlashMessageHandler
        id={"PointsToCurrentMonthForm"}
        render={({ description, data: { type = "success" } }) => (
          <Alert type={type}>{t(description, { ns: "validation" })}</Alert>
        )}
      />
      <form
        onSubmit={handleSubmit(({ orderNumber }) => {
          mutate({ orderId: orderNumber, bonusRunHistoryId });
        })}
        className="flex flex-wrap items-center gap-x-2"
      >
        <FormRow label={t("order_number")} labelClassName="sm:w-auto pr-2">
          <TextInput
            {...register("orderNumber")}
            errorMessage={errors.orderNumber?.message}
          />
        </FormRow>
        <ButtonSubmit size="sm" colorScheme="white">
          {t("submit", { ns: ["common"] })}
        </ButtonSubmit>
      </form>
    </>
  );
}

export default PointsToCurrentMonthForm;

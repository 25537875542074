import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ROUTES from "../../navigation/route";
import { AdminUser } from "../../api/AdminUser";

const UsersTable: FunctionComponent<{ items: AdminUser[] }> = ({ items }) => {
  const { t } = useTranslation(["admin_users", "common"]);

  return (
    <div className="table-wrapper">
      <table className="w-full text-sm border-spacing-y-2">
        <thead>
          <tr className="tr-class">
            <th className="th-class">{t("id")}</th>
            <th className="th-class">{t("login")}</th>
            <th className="th-class">{t("email")}</th>
            <th className="th-class">{t("first_name")}</th>
            <th className="th-class">{t("last_name")}</th>
            <th className="th-class">{t("enabled")}</th>
            <th className="th-class">{t("lang")}</th>
            <th className="th-class">{t("created_at")}</th>
            <th className="th-class">{t("updated_at")}</th>
            <th className="th-class">{t("roles")}</th>
            <th className="th-class"></th>
          </tr>
        </thead>
        <tbody>
          {items.map(
            ({
              id,
              email,
              roles,
              lang,
              firstName,
              lastName,
              login,
              updatedAt,
              createdAt,
              enabled,
            }) => (
              <tr className="tr-class" key={id}>
                <td className="td-class">{id}</td>
                <td className="td-class">{login}</td>
                <td className="td-class">{email}</td>
                <td className="td-class">{firstName}</td>
                <td className="td-class">{lastName}</td>
                <td className="td-class">{enabled}</td>
                <td className="td-class">{lang}</td>
                <td className="td-class">{createdAt}</td>
                <td className="td-class">{updatedAt}</td>
                <td className="td-class">{roles}</td>
                <td className="td-class flex">
                  <Link
                    to={`${ROUTES.ADMIN_USERS_EDIT}/${id}`}
                    className="capitalize text-skyBlue-600 hover:text-skyBlue-700 px-0.5"
                  >
                    {t("edit", { ns: "common" })}
                  </Link>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;

import { useState, useEffect } from "react";
import { AxiosInstance } from "axios";
import { I18nInterface } from "biz-dashboard-frontend/lib/types/interface/I18nInterface";
import { privateApiClient } from "../ApiClient";

export type Option = {
  label: string;
  value: string;
};

export class BonusRunHistory {
  constructor(
    public id: string,
    public year: number,
    public month: number,
    public finalized: string,
  ) {}
}

export const mapToBonusRunHistory = (data: any): BonusRunHistory => {
  return new BonusRunHistory(
    data.id ?? null,
    data.year ?? null,
    // String(data.month).padStart(2, "0") ?? null,
    data.month,
    data.finalized ?? null,
  );
};

export const fetchBonusRunHistory = async (): Promise<BonusRunHistory[]> => {
  return (
    await privateApiClient.get<BonusRunHistory[]>(`/bonus-run-history/list`)
  ).data;
};

export default function useBonusRunHistory({
  i18n,
  minYear,
  minMonthOfMinYear = 0,
  hideNotFinalized = false,
}: {
  i18n: I18nInterface;
  minYear?: number;
  minMonthOfMinYear?: number;
  hideNotFinalized?: boolean;
}): {
  bonusRunHistoryData: BonusRunHistory[];
  bonusRunHistorySelectOptions: Option[];
  bonusRunHistoryLoading: boolean;
  bonusRunHistoryError: Error | undefined;
} {
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<BonusRunHistory[]>([]);

  useEffect(() => {
    const bonusRunHistoryDataItems: BonusRunHistory[] = [];
    setLoading(true);
    setError(undefined);
    fetchBonusRunHistory()
      .then((bonusRunHistory) => {
        setOptions(
          bonusRunHistory
            .filter(({ year, month }: { year: number; month: number }) =>
              minYear
                ? year > minYear ||
                  (year === minYear && month >= minMonthOfMinYear)
                : true,
            )
            .filter(({ finalized }) => !hideNotFinalized || finalized)
            .map((member): Option => {
              const bonusRunHistoryPeriod = mapToBonusRunHistory(member);
              bonusRunHistoryDataItems.push(bonusRunHistoryPeriod);
              return {
                value: bonusRunHistoryPeriod.id,
                label:
                  `${bonusRunHistoryPeriod.year}-${String(
                    bonusRunHistoryPeriod.month,
                  ).padStart(2, "0")}` +
                  (bonusRunHistoryPeriod.finalized
                    ? ` (${i18n.t("completed")})`
                    : ""),
              };
            }),
        );
        setData(bonusRunHistoryDataItems);
        setLoading(false);
        setError(undefined);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, []);

  return {
    bonusRunHistorySelectOptions: options,
    bonusRunHistoryLoading: loading,
    bonusRunHistoryError: error,
    bonusRunHistoryData: data,
  };
}

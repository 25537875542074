import { FunctionComponent, ReactNode } from "react";
import { cn } from "../../helpers/cn";

export const FormRow: FunctionComponent<{
  label?: string;
  children: ReactNode;
  direction?: "vertical" | "horizontal";
  wrapperClassName?: string;
  labelClassName?: string;
}> = ({
  label,
  children,
  direction = "horizontal",
  wrapperClassName,
  labelClassName,
}) => {
  const isHorizontal = direction === "horizontal";
  return (
    <div
      className={cn(
        isHorizontal ? "flex" : "flex-col",
        "sm:flex-row py-2 items-center",
        wrapperClassName,
      )}
    >
      <div className={cn("pr-8 w-32 sm:w-48", labelClassName)}>{label}</div>
      <div>{children}</div>
    </div>
  );
};

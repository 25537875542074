import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomerListFilters } from "../../../api/Customer";
import { useGetTitleList } from "../../../api/Title";
import { FormRow } from "../../../components/FormRow";
import TextInput from "../../../components/Form/TextInput";
import CheckboxInput from "../../../components/Form/CheckboxInput/CheckboxInput";
import Button from "../../../components/Button";
import SelectInput from "../../../components/Form/SelectInput";

export type SearchFormProps = {
  onSubmit: (data: CustomerListFilters) => void;
  loading: boolean;
};

const SearchForm = ({ onSubmit, loading }: SearchFormProps) => {
  const { register, control, handleSubmit } = useForm<CustomerListFilters>();
  const { t } = useTranslation(["manager_customers", "common"]);

  const { data: titles } = useGetTitleList();

  const periodOptions = [
    { value: "", name: "-" },
    { value: 1, name: "1 (1. - 7.)" },
    { value: 2, name: "2 (8. - 15.)" },
    { value: 3, name: "3 (16. - 23.)" },
    { value: 4, name: "4 (24. - 31.)" },
  ];

  const months = [
    { name: "-", value: "" },
    ...moment.months().map((name, index) => ({
      name,
      value: index + 1,
    })),
  ];
  return (
    <form
      className="flex mt-8 flex-col"
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <FormRow label={t("customer_number")}>
        <TextInput {...register("custNo")} />
      </FormRow>
      <FormRow label={t("first_name")}>
        <TextInput {...register("firstName")} />
      </FormRow>
      <FormRow label={t("last_name")}>
        <TextInput {...register("lastName")} />
      </FormRow>
      <FormRow label={`${t("title")} ≥`}>
        <SelectInput {...register("titleGt")}>
          {titles?.map(({ level, titleName }) => (
            <option key={level} value={level}>
              {titleName}
            </option>
          ))}
        </SelectInput>
      </FormRow>
      <FormRow label={`${t("title")} ≤`}>
        <SelectInput {...register("titleLt")}>
          {titles?.map(({ level, titleName }) => (
            <option key={level} value={level}>
              {titleName}
            </option>
          ))}
        </SelectInput>
      </FormRow>
      <FormRow label={`${t("period")} ≤`}>
        <SelectInput {...register("period")}>
          {periodOptions.map(({ value, name }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </SelectInput>
      </FormRow>
      <FormRow label={`${t("pp")} ≥`}>
        <TextInput {...register("pointsGt")} />
      </FormRow>
      <FormRow label={`${t("pp")} ≤`}>
        <TextInput {...register("pointsLt")} />
      </FormRow>
      <FormRow label={t("free_month")}>
        <SelectInput {...register("freeMonth")}>
          {months.map(({ name, value }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </SelectInput>
      </FormRow>

      {/* Checkbox Inputs */}
      <FormRow label={t("without_email")}>
        <CheckboxInput {...register("noEmail")} />
      </FormRow>
      <FormRow label={t("credit_card_problems")}>
        <CheckboxInput {...register("creditCardProb")} />
      </FormRow>
      <FormRow label={t("no_purchase_last_month")}>
        <CheckboxInput {...register("noOrderLastMonth")} />
      </FormRow>
      <FormRow>
        <Button type="submit" colorScheme="sky-blue" size="sm">
          {t("find_customers")}
        </Button>
      </FormRow>
    </form>
  );
};

export default SearchForm;

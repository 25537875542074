type ComponentType = "button" | "dashboardNotification";
type ColorSchemes = Record<ComponentType, Record<string, string>>;

export const colorSchemes = {
  button: {
    white:
      "text-skyBlue-600 hover:text-white bg-white hover:bg-skyBlue-600 border-skyBlue-600 ",
    "sky-blue":
      "text-white bg-skyBlue-600 hover:bg-skyBlue-700 border-skyBlue-600",
    "black-red": "text-white bg-black-600 hover:bg-intenseRed-600 border-black",
    red: "text-white bg-intenseRed-600 hover:bg-intenseRed-700 border-intenseRed-600",
    ghost: "text-black bg-transparent hover:bg-slate-950/20 border-none",
  },
  dashboardNotification: {
    important: "bg-intenseRed-600 text-white",
    default: "bg-bizGray-600 text-white",
  },
} as const satisfies ColorSchemes;

export type ColorScheme<T extends ComponentType> =
  keyof (typeof colorSchemes)[T];

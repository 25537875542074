import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRow } from "../../../../components/FormRow";
import ButtonSubmit from "../../../../components/Form/ButtonSubmit";
import TextArea from "../../../../components/Form/TextArea";
import ColorSelect from "../../../../components/ColorSelect";

type FormValues = {
  message: string;
  color: string;
  backgroundColor: string;
};

type NotificationsFormPropsType = {
  formData?: { message: string };
  onSubmit: (data: { message: string }) => void;
  isLoading: boolean;
};
function NotificationsForm({
  formData,
  onSubmit,
  isLoading,
}: NotificationsFormPropsType) {
  const { t } = useTranslation("admin_notifications");
  const schema = yup
    .object({
      message: yup.string().trim().required("field_required"),
      color: yup.string().trim().required("field_required"),
      backgroundColor: yup.string().trim().required("field_required"),
    })
    .required();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: formData
      ? {
          ...formData,
        }
      : { message: undefined, color: "#f1f1f1", backgroundColor: "#303a49" },
  });

  return (
    <form
      className="flex mt-8 flex-col"
      onSubmit={handleSubmit((values) => onSubmit(values))}
    >
      <FormRow label={t("message")}>
        <TextArea
          className="w-64"
          {...register("message")}
          errorMessage={errors.message?.message}
        />
      </FormRow>
      <FormRow label={t("text_color")}>
        <Controller
          control={control}
          name="color"
          render={({ field: { value, onChange } }) => (
            <ColorSelect
              value={value}
              onChange={(color) => {
                onChange(color);
              }}
              variant="light"
              menuPlacement="bottom"
            />
          )}
        />
      </FormRow>
      <FormRow label={t("bg_color")}>
        <Controller
          control={control}
          name="backgroundColor"
          render={({ field: { value, onChange } }) => (
            <ColorSelect
              value={value}
              onChange={(color) => {
                onChange(color);
              }}
              variant="light"
              menuPlacement="bottom"
            />
          )}
        />
      </FormRow>
      <FormRow>
        <ButtonSubmit size="lg" colorScheme="sky-blue" isLoading={isLoading}>
          {t("save")}
        </ButtonSubmit>
      </FormRow>
    </form>
  );
}

export default NotificationsForm;

import { FunctionComponent, ReactNode } from "react";
const PageHeader: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="flex mt-1">
    <h1 className="text-3xl	color-bizGray-600 font-gothicBookRegular">
      {children}
    </h1>
  </div>
);

export default PageHeader;

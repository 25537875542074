import { FunctionComponent, ReactElement } from "react";
import useFlashMessage from "../../hooks/useFlashMessage";
import { FlashMessage } from "../../context/FlashMessageContext";
import { AlertType } from "../Alert/Alert";

type FlashMessageHandlerProps = {
  id: string;
  render: (
    data: Pick<
      FlashMessage<{ type?: AlertType }>,
      "title" | "description" | "data"
    >,
  ) => ReactElement;
};

const FlashMessageHandler: FunctionComponent<FlashMessageHandlerProps> = ({
  id,
  render,
}) => {
  const { flashMessage } = useFlashMessage();

  if (flashMessage.targetId === id) {
    const { title, description, data } = flashMessage;
    return render({ title, description, data });
  }

  return <div />;
};

export default FlashMessageHandler;

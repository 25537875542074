import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

const ErrorMessage: FunctionComponent<{
  children?: string;
  timeout?: number;
}> = ({ children, timeout }) => {
  const { t } = useTranslation();
  const [rendered, setRendered] = useState(true);

  if (timeout) {
    const timer = setTimeout(() => {
      setRendered(false);
    }, timeout);
    () => clearTimeout(timer);
  }
  return rendered && children ? (
    <>
      <div className="flex mt-1">
        <div className="flex-shrink-0">
          <i className="fa-light fa-circle-exclamation fa-sm text-red-500"></i>
        </div>
        <div className="ml-1">
          <span className="text-red-500 text-sm mt-1">
            {t(children, { ns: "validation" })}
          </span>
        </div>
      </div>
    </>
  ) : null;
};

export default ErrorMessage;

import {
  createContext,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";

export type FlashMessage<DataType = Record<string, string>> = {
  title: string;
  description: string;
  data: DataType;
  targetId: string;
  time: number;
};

type FlashMessageContextType = {
  flashMessage: FlashMessage;
  displayFlashMessage: (
    targetId: string,
    flashMessage: Omit<Partial<FlashMessage>, "targetId">,
  ) => void;
};

const flashMessageInitialValues = {
  title: "",
  description: "",
  data: {},
  targetId: "",
  time: 0,
};

const FlashMessageContext = createContext<FlashMessageContextType>({
  flashMessage: flashMessageInitialValues,
  displayFlashMessage: () => {},
});

export const FlashMessageProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [flashMessage, setFlashMessage] = useState<FlashMessage>(
    flashMessageInitialValues,
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFlashMessage(flashMessageInitialValues);
    }, flashMessage.time);
    return () => clearTimeout(timeout);
  }, [flashMessage]);

  return (
    <FlashMessageContext.Provider
      value={{
        flashMessage,
        displayFlashMessage: (
          targetId: string,
          { title = "", description = "", data = {}, time = 5000 },
        ) =>
          setFlashMessage({
            targetId,
            title,
            description,
            data,
            time,
          }),
      }}
    >
      {children}
    </FlashMessageContext.Provider>
  );
};

export default FlashMessageContext;

import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRow } from "../../../components/FormRow";
import TextInput from "../../../components/Form/TextInput";
import ButtonSubmit from "../../../components/Form/ButtonSubmit";
import { Language, LanguageModel } from "../../../api/Language";

type FormValues = LanguageModel;

export const LanguageForm: FunctionComponent<{
  formData?: Language;
  onSubmit: (data: FormValues) => unknown;
  isLoading: boolean;
}> = ({ formData, onSubmit, isLoading }) => {
  const { t } = useTranslation(["language_management", "common"]);

  const schema = yup
    .object({
      lang: !formData
        ? yup
            .string()
            .trim()
            .required("field_required")
            .matches(
              /^[a-z]+$/,
              t("only_lowercase_letters_are_valid", { ns: "validation" }),
            )
        : yup.string(),
      fullLangName: yup
        .string()
        .trim()
        .required("field_required")
        .min(
          2,
          t("value_must_be_at_least_n_characters_long", {
            min: 2,
            ns: "validation",
          }),
        ),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  return (
    <form
      className="flex mt-8 flex-col"
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      {!formData ? (
        <FormRow label={t("short_name")}>
          <TextInput
            {...register("lang")}
            errorMessage={errors.lang?.message}
          />
        </FormRow>
      ) : (
        <FormRow label={t("short_name")}>
          <span>{formData.lang}</span>
        </FormRow>
      )}
      <FormRow label={t("name")}>
        <TextInput
          {...register("fullLangName")}
          errorMessage={errors.fullLangName?.message}
        />
      </FormRow>
      <FormRow>
        <ButtonSubmit size="lg" colorScheme="sky-blue" isLoading={isLoading}>
          {t("save")}
        </ButtonSubmit>
      </FormRow>
    </form>
  );
};

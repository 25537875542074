const ADMIN_ROUTES = {
  ADMIN_USERS_PAGE: "/admin/users",
  ADMIN_USERS_ADD: "/admin/users/add",
  ADMIN_USERS_EDIT: "/admin/users/edit",
  ADMIN_NOTIFICATIONS: "/admin/notifications",
  ADMIN_NOTIFICATIONS_ADD: "/admin/notifications/add",
  ADMIN_NOTIFICATIONS_EDIT: "/admin/notifications/edit",
  ADMIN_BONUS_ARCHIVE_MANAGEMENT: "/admin/bonus-archive-management",
  ADMIN_LIVE_BONUS_BUILDER: "/admin/live-bonus-builder",
} as const;

const MANAGER_ROUTES = {
  MANAGER_CUSTOMERS_PAGE: "/manager/customers",
} as const;

const ROUTES = {
  HOME_PAGE: "/",
  PIN: "/pin",
  REMIND_PASSWORD: "/remind-password",
  PAYMENT_INFORMATION: "/payment-info",
  PIN_MANAGEMENT: "/pin-management",
  LANGUAGE: "/language",
  CROP_PROFILE_IMAGE: "/crop-profile-image",
  TRANSLATIONS: "/translations",
  LANGUAGE_MANAGEMENT: "/language-management",
  LANGUAGE_MANAGEMENT_ADD: "/language-management/add",
  LANGUAGE_MANAGEMENT_EDIT: "/language-management/edit",
  ...ADMIN_ROUTES,
  ...MANAGER_ROUTES,
} as const;

export default ROUTES;

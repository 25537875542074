import { FunctionComponent } from "react";
import { ButtonSize, sizes } from "../../../theme/Button";
import { ColorScheme, colorSchemes } from "../../../theme/colorSchemes";

type ButtonSubmitProps = {
  children: string;
  isLoading?: boolean;
  size?: ButtonSize;
  colorScheme?: ColorScheme<"button">;
};

const ButtonSubmit: FunctionComponent<ButtonSubmitProps> = ({
  children,
  isLoading,
  size = "md",
  colorScheme = "sky-blue",
}) => {
  return (
    <button
      disabled={isLoading}
      type="submit"
      className={`${sizes[size]} uppercase ${colorSchemes.button[colorScheme]} transition-colors duration-500 border font-mediu focus:outline-none`}
    >
      {children}
    </button>
  );
};

export default ButtonSubmit;

import { useMutation, useQuery } from "@tanstack/react-query";
import queryClient, { BasicMutationOptions } from "../queryClient";
import useApiClient from "../hooks/useApiClient";
import { AxiosError } from "axios";
import camelcaseKeys from "camelcase-keys";

export const useCreateProfileImageMutation = (
  mutationOptions?: BasicMutationOptions<{}, AxiosError<{ file: string[] }>>,
) => {
  const { privateApiClient } = useApiClient();
  return useMutation<
    Response,
    AxiosError<{ file: string[] }>,
    { file: FileList }
  >({
    mutationKey: ["profile_image"],
    mutationFn: async ({ file }) => {
      const formData = new FormData();
      formData.append("file", file[0]);
      return (
        await privateApiClient.post(`customer/profile/image/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      ).data;
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["profile_image"] }),
    ...mutationOptions,
  });
};

export const useDeleteProfileImageMutation = (
  mutationOptions?: BasicMutationOptions<{}, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<{}, AxiosError, {}>({
    mutationKey: ["ProfileImage"],
    mutationFn: async () =>
      await privateApiClient.delete(`customer/profile/image/delete`),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["ProfileImage"] }),
  });
};

export const useProfileImage = () => {
  const { privateApiClient } = useApiClient();
  return useQuery<{ profileImage: string }, unknown>({
    queryKey: ["ProfileImageUrl"],
    queryFn: async () =>
      camelcaseKeys(
        (await privateApiClient.get(`/dashboard/my-profile`)).data,
        { deep: true },
      ).profileImage,
    initialData: undefined,
  });
};

// export const useGetUser = ({ id }: { id: string }) => {
//   const { privateApiClient } = useApiClient();
//   return useQuery<{ profileImage: string }, unknown>(
//     ["user", { id }],
//     async () =>
//       camelcaseKeys(
//         (await privateApiClient.get(`/dashboard/my-profile`)).data
//       ),
//     {
//       initialData: undefined,
//       enabled: Boolean(id),
//     }
//   );
// };

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";
import { BasicMutationOptions } from "../queryClient";
import { AxiosError } from "axios";

type Response<ItemType> = {
  items: ItemType[];
  count: number;
  page: number;
};

export type Namespace = {
  id: number;
  name: string;
  description: string;
};

export type Translation = {
  id: number;
  key: string;
  value: string;
  description: string;
  language: {
    id: number;
    lang: string;
    fullLangName: string;
  };
  namespace: Namespace;
};

export type CreateNsRequestParams = {
  name: string;
  description: string;
};

export type AddTranslationRequestParams = {
  key: string;
  value: string;
  namespace: number;
  language: number;
  description: string;
};

export type EditTranslationRequestParams = {
  id: number;
  value: string;
  description: string;
};

export type TranslationResponse = Response<Translation>;
export type NamespaceResponse = Response<Namespace>;

export const useGetTranslationList = ({
  language,
  namespace,
}: {
  language: number | undefined;
  namespace: number | undefined;
}) => {
  const { privateApiClient } = useApiClient();
  return useQuery<Translation[], unknown>({
    queryKey: ["TranslationList", language, namespace],
    queryFn: async () =>
      camelcaseKeys(
        (
          await privateApiClient.get<Translation[]>(
            `/translation/${language}/${namespace}`,
          )
        ).data,
      ),
    enabled: Boolean(language && namespace),
    initialData: undefined,
  });
};

export const useGetNamespaceList = () => {
  const { privateApiClient } = useApiClient();
  return useQuery<Namespace[], unknown>({
    queryKey: ["NamespaceList"],
    queryFn: async () =>
      camelcaseKeys(
        (
          await privateApiClient.get<NamespaceResponse>(
            `/translation/namespace/list`,
          )
        ).data.items,
      ),
    initialData: [],
  });
};

export const useCreateNsMutation = (
  mutationOptions?: BasicMutationOptions<unknown, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<unknown, AxiosError, CreateNsRequestParams>({
    mutationKey: ["createNs"],
    mutationFn: async (data) =>
      (await privateApiClient.post(`/translation/namespace/add`, data)).data,
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

export const useAddTranslationMutation = (
  mutationOptions?: BasicMutationOptions<unknown, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError, AddTranslationRequestParams>({
    mutationKey: ["TranslationList"],
    mutationFn: async (data) =>
      (await privateApiClient.post(`/translation/add`, data)).data,
    onSuccess: mutationOptions?.onSuccess,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["TranslationList"] }),
    onError: mutationOptions?.onError,
  });
};

export const useEditTranslationMutation = (
  mutationOptions?: BasicMutationOptions<unknown, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, EditTranslationRequestParams>({
    mutationKey: ["TranslationList"],
    mutationFn: async ({ id, ...rest }) =>
      (await privateApiClient.patch(`/translation/edit/${id}`, rest)).data,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["TranslationList"] }),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

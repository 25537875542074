import { forwardRef } from "react";
import { ErrorMessage } from "../index";
import { cn } from "../../../helpers/cn";

type TextAreaProps = {
  errorMessage?: string;
  className?: string;
};

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ errorMessage, className, ...rest }, ref) => {
    return (
      <div className={cn("flex flex-col w-48", className)}>
        <textarea
          ref={ref}
          {...rest}
          // rows={4}
          className="pl-2 rounded-sm shadow-dashboard-input bg-white text-gray-900 block flex-1 min-w-0 w-48 text-sm border-gray-300 p-1.5 focus:outline-none resize max-w-full"
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  },
);

export default TextArea;

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextInput from "../../../../components/Form/TextInput";
import TextArea from "../../../../components/Form/TextArea";
import ButtonSubmit from "../../../../components/Form/ButtonSubmit";
import { useTranslation } from "react-i18next";
import {
  LiveBonus,
  useLiveBonusBuilder,
} from "../../../../api/LiveBonusBuilder";
import FlashMessageHandler from "../../../../components/FlashMessageHandler";
import Alert from "../../../../components/Alert";
import useFlashMessage from "../../../../hooks/useFlashMessage";
import RecipientsSection from "./RecipientsSection";

function LiveBonusBuilderForm() {
  const { t } = useTranslation("live_bonus_builder");
  const { displayFlashMessage } = useFlashMessage();
  const { mutate } = useLiveBonusBuilder({
    onError: (error) => {
      displayFlashMessage("LveBonusBuilder", {
        description:
          error?.response?.status === 400 ? "invalid_data" : "error_occured",
        data: { type: "danger" },
      });
    },
    onSuccess: () => {
      reset();
      displayFlashMessage("LveBonusBuilder", {
        description: "data_has_been_saved",
      });
    },
  });
  const schema = yup
    .object({
      type: yup.string().required("field_required"),
      sourceCustNo: yup
        .number()
        .typeError(t("must_be_a_number"))
        .nullable()
        .transform((_, val) => (val !== "" ? Number(val) : null)),
      sourceOrderId: yup
        .number()
        .typeError(t("must_be_a_number"))
        .nullable()
        .transform((_, val) => (val !== "" ? Number(val) : null)),
      sourceOrderLineId: yup
        .number()
        .typeError(t("must_be_a_number"))
        .nullable()
        .transform((_, val) => (val !== "" ? Number(val) : null)),
      recipients: yup
        .array(
          yup.object({
            custNo: yup
              .number()
              .typeError(t("must_be_a_number"))
              .required("field_required"),
            currency: yup.string(),
            amount: yup
              .number()
              .typeError(t("must_be_a_number"))
              .required("field_required"),
          }),
        )
        .required("field_required"),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm<LiveBonus>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      recipients: [
        {
          custNo: 0,
          currency: "",
          amount: 0,
        },
      ],
    },
  });

  return (
    <>
      <FlashMessageHandler
        id={"LveBonusBuilder"}
        render={({ description, data: { type = "success" } }) => (
          <Alert type={type}>
            {t(description, { ns: ["validation", "common"] })}
          </Alert>
        )}
      />
      <form
        onSubmit={handleSubmit((value) => {
          mutate(value);
        })}
      >
        <h2 className="text-subtitle uppercase text-skyBlue-400">
          {t("basic_information")}
        </h2>
        <table className="w-full mb-5">
          <tbody>
            <tr className="bg-[#e4e4e4]">
              <td className="py-1.5 px-2.5 w-full sm:w-auto inline-block sm:table-cell">
                {t("type")}
              </td>
              <td className="py-1.5 px-2.5 w-full sm:w-auto inline-block sm:table-cell">
                <TextArea
                  className="block w-auto max-w-full "
                  {...register("type")}
                  errorMessage={errors.type?.message}
                />
              </td>
            </tr>
            <tr className="bg-white">
              <td className="py-1.5 px-2.5 w-full sm:w-auto inline-block sm:table-cell">
                {t("source_cust_no")}
              </td>
              <td className="py-1.5 px-2.5 w-full sm:w-auto inline-block sm:table-cell">
                <TextInput
                  {...register("sourceCustNo")}
                  errorMessage={errors.sourceCustNo?.message}
                />
              </td>
            </tr>
            <tr className="bg-[#e4e4e4]">
              <td className="py-1.5 px-2.5 w-full sm:w-auto inline-block sm:table-cell">
                {t("source_order_id")}
              </td>
              <td className="py-1.5 px-2.5 w-full sm:w-auto inline-block sm:table-cell">
                <TextInput
                  {...register("sourceOrderId")}
                  errorMessage={errors.sourceOrderId?.message}
                />
              </td>
            </tr>
            <tr className="bg-white">
              <td className="py-1.5 px-2.5 w-full sm:w-auto inline-block sm:table-cell">
                {t("source_order_line_id")}
              </td>
              <td className="py-1.5 px-2.5 w-full sm:w-auto inline-block sm:table-cell">
                <TextInput
                  {...register("sourceOrderLineId")}
                  errorMessage={errors.sourceOrderLineId?.message}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <RecipientsSection
          control={control}
          register={register}
          errors={errors}
        />
        <ButtonSubmit colorScheme="white">
          {t("submit", { ns: ["common"] })}
        </ButtonSubmit>
      </form>
    </>
  );
}

export default LiveBonusBuilderForm;

import React, { FunctionComponent, useEffect, useMemo } from "react";
import { Notification, NotificationType } from "../../api/UserNotification";
import DashboardNotification from "../DashboardNotification";
import useLocalStorage from "../../hooks/useLocalStorage";

type DefaultNotificationsPropsType = { notifications: Notification[] };

const DefaultNotifications: FunctionComponent<
  DefaultNotificationsPropsType
> = ({ notifications }) => {
  const defaultNotificationObject = useMemo(
    () =>
      notifications.reduce<Record<number, Notification>>(
        (acc, notification) => {
          if (notification.type === NotificationType.Default) {
            return {
              ...acc,
              [notification.id]: notification,
            };
          }
          return acc;
        },
        {},
      ),
    [notifications],
  );

  const [storageNotifications, setStorageNotifications] = useLocalStorage<
    Record<number, Notification & { viewed?: boolean }>
  >("notifications", defaultNotificationObject);
  useEffect(
    () =>
      setStorageNotifications((storedValue) => {
        let merged = {};
        for (const [key, value] of Object.entries(defaultNotificationObject)) {
          merged = storedValue?.[+key]
            ? {
                ...merged,
                [key]: { ...value, viewed: storedValue[+key].viewed },
              }
            : { ...merged, [key]: { ...value, viewed: false } };
        }
        return merged;
      }),
    [defaultNotificationObject],
  );

  const handleNotificationClose = (id: number) => {
    setStorageNotifications((storedValue) => {
      return { ...storedValue, [id]: { ...storedValue[id], viewed: true } };
    });
  };

  if (!Object.keys(storageNotifications)?.length) return null;
  return (
    <>
      {Object.values(storageNotifications).map(
        ({ message, id, viewed, color, backgroundColor }) => {
          if (viewed) return null;

          return (
            <DashboardNotification
              key={id}
              message={message || ""}
              id={id}
              onClose={handleNotificationClose}
              color={color}
              backgroundColor={backgroundColor}
            />
          );
        },
      )}
    </>
  );
};

export default DefaultNotifications;

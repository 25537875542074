import { FunctionComponent } from "react";
import {
  useGetUserNotificationList,
  NotificationType,
} from "../../api/UserNotification";

const ITBusinessPartnerAgreement: FunctionComponent = (): JSX.Element => {
  const { data } = useGetUserNotificationList();

  return (
    <div className="flex flex-col flex-1">
      <h2 className="font-gothicBookRegular text-skyBlue-600 text-2xl">
        Eqology Business Partner Agreement Status
      </h2>
      {data?.find(
        ({ type }) => type === NotificationType.MissingPaymentData,
      ) ? (
        <p>Abbiamo ricevuto il tuo Partner Agreement firmato, grazie!</p>
      ) : (
        <p>
          Non abbiamo ancora ricevuto il tuo documento firmato Business Partner
          Agreement.{" "}
          <em>
            Non possiamo effettuare il trasferimento della tua provvigione
          </em>{" "}
          finché non verrà consegnato il documento.{" "}
          <a
            href="https://eqology.com/wp-content/uploads/2020/05/APPLICATION-FORM_IT-2020.pdf"
            target="_blank"
            style={{ color: "#00bce4" }}
            rel="noreferrer"
          >
            Clicca qui<sup>&#x238b;</sup>
          </a>{" "}
          per ottenere il tuo modulo.
        </p>
      )}

      <h2 className="font-gothicBookRegular text-skyBlue-600 text-2xl mt-6">
        IMPORTANTE
      </h2>

      <p>
        Per ricevere il pagamento mensile delle commissioni, è necessario
        assicurarsi che i dati bancari ed il codice fiscale siano correttamente
        compilati e salvati nel Business Back Office &gt; Impostazioni &gt; Dati
        Bancari. Inoltre, il Business Partner Agreement (Domanda di Nomina)
        dev’essere stampato, firmato e, insieme ai documenti elencati di
        seguito, inviato al nostro ufficio italiano: <br />
        EQOLOGY ITALY S.R.L.
        <br />
        VIA SABATINI, N ° 31 / F<br />
        51010 MASSA E COZZILE (PT) - ITALIA
        <br />
        oppure all’indirizzo di Posta Elettronica Certificata (PEC):{" "}
        <a href="mailto:eqologyitaly@cgn.legalmail.it">
          eqologyitaly@cgn.legalmail.it
        </a>
      </p>

      <p className="mt-6 mb-6">Documenti richiesti:</p>

      <p style={{ marginBottom: 0 }}>
        Residenti in Italia con cittadinanza italiana o con cittadinanza in un
        altro paese europeo dell'area Schengen:
      </p>
      <ul className="list-disc pl-8 mt-6">
        <li>
          Business Partner Agreement (Domanda di Nomina) stampato e firmato
        </li>
        <li>copia di una Carta d'Identità o passaporto validi</li>
      </ul>

      <p className="mt-6">
        Residenti in Italia con cittadinanza in un paese extra UE o in un paese
        dell'UE al di fuori dell’area Schengen
      </p>
      <ul className="list-disc pl-8 mt-6">
        <li>
          Business Partner Agreement (Domanda di Nomina) stampato e firmato
        </li>
        <li>copia di un passaporto valido</li>
        <li>
          copia di un permesso di soggiorno valido * (valido per almeno tre mesi
          dalla data di presentazione)
        </li>
      </ul>

      <p className="mt-6">
        * IMPORTANTE: i permessi di soggiorno considerati validi sono quelli
        richiesti per motivi di lavoro (lavoro retribuito). Altre ragioni, come
        motivi di studio o di asilo politico, saranno valutate di volta in
        volta, perché richiedono la presentazione di una documentazione
        aggiuntiva.
      </p>

      <p>
        Solo dopo che i documenti saranno stati consegnati correttamente,
        EQOLOGY sbloccherà i pagamenti delle commissioni.
      </p>
    </div>
  );
};

export default ITBusinessPartnerAgreement;

import { useQuery } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";

export type Title = {
  level: string;
  titleName: string;
  shortName: string;
};

export const useGetTitleList = () => {
  const { privateApiClient } = useApiClient();
  return useQuery<Title[], unknown>({
    queryKey: ["Titles"],
    queryFn: async () =>
      camelcaseKeys(
        (await privateApiClient.get<Title[]>(`/customer/titles`)).data,
      ),
    initialData: undefined,
  });
};

import { Role } from "biz-dashboard-frontend/lib/types/components/reports-core/types/Role";
import { FunctionComponent, useContext } from "react";
import { NavLink, To, useLocation } from "react-router-dom";
import { useGrantAccess } from "../../../hooks/useGrantAccess";
import { cn } from "../../../helpers/cn";
import { SidebarContext } from "../../../context/SidebarContext";

const CollapsedNavChild: FunctionComponent<{
  label: string;
  route: To;
  section: string;
  isOldRouting: boolean;
  accessFor?: Role[];
  className?: string;
}> = ({
  label,
  route,
  section,
  isOldRouting,
  accessFor = [
    "ROLE_SUPER_ADMIN",
    "ROLE_USER",
    "ROLE_ADMIN",
    "ROLE_CUSTOMER",
    "ROLE_MANAGER",
  ],
  className,
}) => {
  const { isAccessGranted } = useGrantAccess(accessFor);
  if (!isAccessGranted) {
    return null;
  }
  const { pathname, search } = useLocation();

  const to = `${route}${isOldRouting ? "&" : "?"}${`section=${section}`}`;
  const currentPath = isOldRouting ? `/${search}` : `${pathname + search}`;
  const isActive = currentPath === to;
  const { setOpenedSection, setSidebarOpen } = useContext(SidebarContext);
  const isLgScreen = window.innerWidth > 1024;
  const isSmScreen = window.innerWidth < 640;

  const handleNavLinkClick = () => {
    if (isLgScreen) {
      return null;
    }
    if (isSmScreen) {
      setOpenedSection(null);
      setSidebarOpen(false);
      return;
    }
    setOpenedSection(null);
    return;
  };

  return (
    <NavLink
      onClick={handleNavLinkClick}
      to={to}
      className={cn(
        "text-bizGray-400 text-sm hover:text-skyBlue-600 py-0 pl-4 hover:bg-bizDarkBlue-600 flex items-center border-b-bizDarkBlue-600 border-b mb-[12px] leading-none",
        isActive && "text-skyBlue-600",
        className,
      )}
    >
      <span className="pl-14">{label}</span>
    </NavLink>
  );
};

export default CollapsedNavChild;

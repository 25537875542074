import React, { FunctionComponent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import { cn } from "../../helpers/cn";

export type PaginationCallback = (page: number) => any;

type PaginationProps = {
  perPage: number;
  count: number;
  page: number;
  paginationCallback: PaginationCallback;
  disabled?: boolean;
};

const PaginationButton: FunctionComponent<{
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  isActive?: boolean;
}> = ({ disabled, onClick, isActive, children }) => (
  <button
    className={cn(
      "border-[1px] bg-white border-skyBlue-600 text-skyBlue-600 py-[7px] px-[8px] rounded hover:bg-skyBlue-600 hover:text-white",
      isActive && "bg-skyBlue-600 text-white",
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

const PaginationSeparatot = () => <div className="flex items-end">...</div>;

const Pagination = ({
  perPage,
  count,
  page,
  paginationCallback,
  disabled = false,
}: PaginationProps) => {
  const { t } = useTranslation("pagination");
  const numberOfPages = Math.ceil(count / perPage);
  const visiblePages = Array.from(
    { length: numberOfPages },
    (_, i) => i + 1,
  ).slice(
    Math.abs(page > 3 ? page - 3 : 0),
    5 + Math.abs(page > 3 ? page - 3 : 1),
  );
  const from = (page - 1) * perPage + 1;
  const to = page * perPage < count ? page * perPage : count;

  return (
    <div className="pagination hidden-for-print">
      <div className="flex justify-between items-center">
        {Boolean(count) && (
          <div
            className="col"
            style={{ paddingTop: "4px", paddingRight: "4px" }}
          >
            {t("showing", {
              from,
              to,
              count,
            })}
          </div>
        )}
        <div className="flex gap-1">
          <PaginationButton
            onClick={() => page > 1 && paginationCallback(page - 1)}
            disabled={page === 1 || disabled}
          >
            <span className="hidden md:block">
              {t("previous").toUpperCase()}
            </span>
            <span className="md:hidden">
              <Icon name="leftArrowSkyBlue" />
            </span>
          </PaginationButton>
          {page > 3 && (
            <>
              <PaginationButton
                onClick={() => paginationCallback(1)}
                disabled={disabled}
              >
                1
              </PaginationButton>
              <PaginationSeparatot />
            </>
          )}
          {visiblePages.map((numberOfPage, index) => (
            <PaginationButton
              isActive={page === numberOfPage}
              onClick={() => paginationCallback(numberOfPage)}
              key={index}
              disabled={disabled}
            >
              {numberOfPage}
            </PaginationButton>
          ))}
          {Boolean(numberOfPages) &&
            visiblePages.indexOf(numberOfPages) === -1 && (
              <>
                <PaginationSeparatot />
                <PaginationButton
                  onClick={() => paginationCallback(numberOfPages)}
                  disabled={disabled}
                >
                  {String(numberOfPages)}
                </PaginationButton>
              </>
            )}
          <PaginationButton
            onClick={() => paginationCallback(page + 1)}
            disabled={numberOfPages === page || disabled}
          >
            <span className="hidden md:block">{t("next").toUpperCase()}</span>
            <span className="md:hidden">
              <Icon name="rightArrowSkyBlue" />
            </span>
          </PaginationButton>
        </div>
      </div>
    </div>
  );
};

export default Pagination;

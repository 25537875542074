import { FunctionComponent, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import {
  useDeleteLanguageMutation,
  useGetLanguageList,
} from "../../api/Language";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import ROUTES from "../../navigation/route";
import { Link } from "react-router-dom";
import FlashMessageHandler from "../../components/FlashMessageHandler";
import useFlashMessage from "../../hooks/useFlashMessage";

const LanguageManagementPage: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation(["language_management", "common"]);
  const { data: languageList } = useGetLanguageList();

  const { mutate, isPending } = useDeleteLanguageMutation({
    onSuccess: () => {
      displayFlashMessage("LanguageManagementPage:success", {
        description: "data_has_been_removed",
      });
      setItemToDeleteId(undefined);
    },
  });

  const [itemToDeleteId, setItemToDeleteId] = useState<number>();

  const { displayFlashMessage } = useFlashMessage();

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      <PageHeaderWrapper>
        <PageHeader>{t("header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        <FlashMessageHandler
          id={"LanguageManagementPage:success"}
          render={({ description }) => (
            <Alert type="success">{t(description, { ns: "common" })}</Alert>
          )}
        />
        <div className="py pb-4">
          <Link to={ROUTES.LANGUAGE_MANAGEMENT_ADD}>
            <Button size="sm" colorScheme="sky-blue" onClick={() => {}}>
              {t("add", { ns: "common" })}
            </Button>
          </Link>
        </div>
        <div className="flex">
          <table className="text-sm border-spacing-y-2">
            <thead>
              <tr className="tr-class">
                <th className="th-class">{t("name")}</th>
                <th className="th-class">{t("short_name")}</th>
                <th className="th-class"></th>
              </tr>
            </thead>
            <tbody>
              {languageList?.map(({ lang, fullLangName, id }) => (
                <tr className="tr-class" key={id}>
                  <td className="td-class">{fullLangName}</td>
                  <td className="td-class">{lang}</td>
                  <td className="td-class flex">
                    <Link
                      to={`${ROUTES.LANGUAGE_MANAGEMENT_EDIT}/${id}`}
                      className="capitalize text-skyBlue-600 hover:text-skyBlue-700 px-0.5"
                    >
                      {t("edit", { ns: "common" })}
                    </Link>
                    <div className="px-0.5"></div>
                    <button
                      onClick={() => setItemToDeleteId(id)}
                      className="capitalize text-intenseRed-600 hover:text-intenseRed-700 px-0.5"
                    >
                      {t("remove")}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={Boolean(itemToDeleteId)}
        onClose={() => setItemToDeleteId(undefined)}
        onButtonClick={() => mutate({ id: Number(itemToDeleteId) })}
        buttonText={t("yes", { ns: "common" })}
        title={t("confirm_your_action", { ns: "common" })}
        isLoading={isPending}
      >
        {t("are_you_sure_to_delete", { ns: "common" })}
      </Modal>
    </div>
  );
};

export default LanguageManagementPage;

import { FunctionComponent } from "react";

export type AlertType = "danger" | "success" | "info";
const Alert: FunctionComponent<{
  children: string | JSX.Element;
  type: AlertType;
}> = ({ children, type }) => {
  return {
    info: (
      <div
        className="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
        role="alert"
      >
        {children}
      </div>
    ),
    danger: (
      <div
        className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
        role="alert"
      >
        {children}
      </div>
    ),
    success: (
      <div
        className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
        role="alert"
      >
        {children}
      </div>
    ),
  }[type];
};

export default Alert;

import { ComponentsI18nNamespaces } from "biz-dashboard-frontend/src/ComponentsI18nNamespaces";
import { TOptionsBase, i18n } from "i18next";

const withI18nNamespace = (
  namespace: ComponentsI18nNamespaces,
  i18n: i18n,
) => ({
  t: (phrase: string, options: TOptionsBase) =>
    i18n.t(phrase, { ...options, ns: [namespace] }),
  language: i18n.language,
});

export default withI18nNamespace;

import { createContext, FunctionComponent, ReactNode, useEffect } from "react";
import { useGetUser, User } from "../api/User";
import useAuth from "../hooks/useAuth";
import useLocalStorage from "../hooks/useLocalStorage";

type UserContextType = {
  user: User;
};

const userInitialValues = {
  company: "",
  country: "",
  custNo: 0,
  firstName: "",
  lastName: "",
  title: "",
};

const UserContext = createContext<UserContextType>({
  user: userInitialValues,
});

export const UserProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useLocalStorage<User>("user", userInitialValues);

  const { auth } = useAuth();
  const { data } = useGetUser({
    id: auth.id,
  });

  useEffect(() => {
    !auth.isLoggedIn && setUser(userInitialValues);
  }, [auth.isLoggedIn, setUser]);

  useEffect(() => {
    data && setUser(data);
  }, [data, setUser]);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

export default UserContext;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useWebComponentsWithSPARouting = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleGlobalClick = (event: any) => {
      for (const el of event.path ||
        (event.composedPath && event.composedPath())) {
        if (el.tagName === "A" && el.getAttribute("href")) {
          event.preventDefault();
          const linkHref = el.getAttribute("href");
          if (
            linkHref.startsWith("tel:") ||
            linkHref.startsWith("mailto:") ||
            (linkHref.startsWith("https//:") &&
              !linkHref.startsWith(window.location.href))
          ) {
            window.location.href = linkHref;
          } else {
            navigate(linkHref.replace(window.location.href, ""));
          }
          break;
        }
      }
    };
    document.body.addEventListener("click", handleGlobalClick);
    return () => {
      document.body.removeEventListener("click", handleGlobalClick);
    };
  }, []);
};

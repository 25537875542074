import { FunctionComponent } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import { useGetBankData } from "../../api/BankData";
import { PaymentInformationForm } from "./PaymentInformationForm";
import ITBusinessPartnerAgreement from "./ITBusinessPartnerAgreement";
import useAuth from "../../hooks/useAuth";

const PaymentInformationPage: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation("payment_information");

  const { data } = useGetBankData();

  const {
    auth: { homeShop },
  } = useAuth();

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      <PageHeaderWrapper>
        <PageHeader>{t("header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        <div>{t("description_1")}</div>
        <div className="mt-4">{t("description_2")}</div>
        {data !== undefined && <PaymentInformationForm bankData={data} />}
        {homeShop === "it" && <ITBusinessPartnerAgreement />}
        <div className="mt-4">{t("description_3")}</div>
      </div>
    </div>
  );
};

export default PaymentInformationPage;

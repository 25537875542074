import { FunctionComponent } from "react";
import sprite from "../../assets/images/sprite.png";

const Sprite: FunctionComponent<{
  x: number;
  y: number;
  width: number;
  height: number;
  className?: string;
}> = ({ x, y, width, height, className }) => (
  <div className={`flex ${className ? className : ""}`}>
    <div
      style={{
        backgroundImage: `url(${sprite})`,
        backgroundPositionX: x,
        backgroundPositionY: y,
        width,
        height,
        backgroundRepeat: "no-repeat",
      }}
    />
  </div>
);

export default Sprite;

import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import ButtonSubmit from "../HomePage/components/form/ButtonSubmit";
import Alert from "../../components/Alert";
import { FormRow } from "../../components/FormRow";
import { PinUpdateModel, useUpdatePinMutation } from "../../api/Auth";
import PasswordInput from "../../components/Form/TextInput/PasswordInput";

type FormValues = Required<PinUpdateModel> & {
  pinRepeated: string;
};

const EditPinForm: FunctionComponent = () => {
  const { t } = useTranslation(["pin_management", "common"]);

  const { mutate } = useUpdatePinMutation({
    onError: (error) => {},
    onSuccess: () => {
      setSuccessMessage("data_has_been_saved");
    },
  });

  const schema = yup
    .object({
      existingPin: yup.string()
        .matches(/^[0-9]+$/, t("pin_must_be_a_number"))
        .min(4, t("pin_must_be_exactly_4_digits"))
        .max(4, t("pin_must_be_exactly_4_digits")).required("field_required"),
      newPin: yup
        .string()
        .matches(/^[0-9]+$/, t("pin_must_be_a_number"))
        .min(4, t("pin_must_be_exactly_4_digits"))
        .max(4, t("pin_must_be_exactly_4_digits"))
        .required("field_required"),
      pinRepeated: yup
        .string()
        .required()
        .oneOf([yup.ref("newPin")], t("pin_numbers_equals_error")),
    })
    .required();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [successMessage, setSuccessMessage] = useState<string>();
  const onSubmit = ({ existingPin, newPin }: FormValues) => {
    mutate({ existingPin, newPin });
  };

  return (
    <form
      className="flex mt-8 flex-col"
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <h2 className="mb-4 text-lg">{t("set_pin").toUpperCase()}</h2>

      {successMessage && (
        <Alert type="success">{t(successMessage, { ns: "common" })}</Alert>
      )}
      <FormRow label={t("existing_pin")}>
        <PasswordInput
          {...register("existingPin")}
          errorMessage={errors.existingPin?.message}
        />
      </FormRow>
      <FormRow label={t("pin")}>
        <PasswordInput
          {...register("newPin")}
          errorMessage={errors.newPin?.message}
        />
      </FormRow>
      <FormRow label={t("confirm_new_pin")}>
        <PasswordInput
          {...register("pinRepeated")}
          errorMessage={errors.pinRepeated?.message}
        />
      </FormRow>
      <FormRow>
        <button
          className="uppercase text-white bg-black-600 hover:bg-intenseRed-600 transition-colors duration-500 border border-black font-medium rounded-md text-sm px-6 py-2 mr-2 mb-2 focus:outline-none"
          onClick={() => reset()}
        >
          {t("rest")}
        </button>
        <ButtonSubmit isLoading={false}>{t("change")}</ButtonSubmit>
      </FormRow>
    </form>
  );
};

export default EditPinForm;

import { FunctionComponent } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import { LanguageForm } from "./form/LanguageForm";
import { useAddLanguageMutation } from "../../api/Language";
import useFlashMessage from "../../hooks/useFlashMessage";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../navigation/route";

const AddLanguagePage: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation("language_management");
  const { displayFlashMessage } = useFlashMessage();
  const navigate = useNavigate();

  const { mutate, isPending } = useAddLanguageMutation({
    onError: () => {},
    onSuccess: () => {
      displayFlashMessage("LanguageManagementPage:success", {
        description: "data_has_been_saved",
      });
      navigate(ROUTES.LANGUAGE_MANAGEMENT);
    },
  });

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      <PageHeaderWrapper>
        <PageHeader>{t("header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600">
        <LanguageForm onSubmit={(data) => mutate(data)} isLoading={isPending} />
      </div>
    </div>
  );
};

export default AddLanguagePage;

import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormRow } from "../../../../components/FormRow";
import { AdminUserModel } from "../../../../api/AdminUser";
import TextInput from "../../../../components/Form/TextInput";
import ButtonSubmit from "../../../../components/Form/ButtonSubmit";
import CheckboxInput from "../../../../components/Form/CheckboxInput/CheckboxInput";
import { useGetLanguageList } from "../../../../api/Language";
import Option from "../../../../components/LanguageSelect/Option";
import ReactSelect from "react-select";
import { ErrorMessage } from "../../../../components/Form";
import { WithReactMultiSelect } from "../../../../types/WithReactMultiselect";
import { yupResolver } from "@hookform/resolvers/yup";

type FormValues = WithReactMultiSelect<AdminUserModel, "roles">;

type ObjectShapeValues =
  yup.ObjectShape extends Record<string, infer V> ? V : never;

type Shape<T extends Record<any, any>> = Partial<
  Record<keyof T, ObjectShapeValues>
>;

export const AdminUserForm: FunctionComponent<{
  formData?: Omit<AdminUserModel, "password">;
  onSubmit: (data: AdminUserModel) => unknown;
  isLoading: boolean;
}> = ({ formData, onSubmit, isLoading }) => {
  const { t } = useTranslation(["admin_users", "common"]);
  const { data: languages } = useGetLanguageList();
  const schema = yup
    .object({
      login: yup.string().trim().required("field_required"),
      email: yup
        .string()
        .trim()
        .required("field_required")
        .email(t("invalid_email_format", { ns: "validation" })),
      password: yup.string().nullable().test('min-8-chars-or-empty', 'Password must be exactly 8 characters or empty', function (value) {
        return !value || value.length >= 8;
      }),
      firstName: yup.string().trim().required("field_required"),
      lastName: yup.string().trim().required("field_required"),
      enabled: yup.boolean().required("field_required"),
      lang: yup.string().trim(),
      roles: yup
        .array()
        .of(
          yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required(),
          }),
        )
        .required("field_required"),
    })
    .required();

  type Schema = yup.InferType<typeof schema>;

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: formData
      ? {
          ...formData,
          roles: formData?.roles?.map((value) => ({ label: value, value })),
        }
      : undefined,
  });

  return (
    <form
      className="flex mt-8 flex-col"
      onSubmit={handleSubmit(({ roles, ...data }) =>
        onSubmit({ ...data, roles: roles.map(({ value }) => value) }),
      )}
    >
      <FormRow label={t("login")}>
        <TextInput
          {...register("login")}
          errorMessage={errors.login?.message}
        />
      </FormRow>
      <FormRow label={t("email")}>
        <TextInput
          {...register("email")}
          errorMessage={errors.email?.message}
        />
      </FormRow>
      <FormRow label={t("password")}>
        <TextInput
          {...register("password")}
          errorMessage={errors.password?.message}
        />
      </FormRow>
      <FormRow label={t("first_name")}>
        <TextInput
          {...register("firstName")}
          errorMessage={errors.firstName?.message}
        />
      </FormRow>
      <FormRow label={t("last_name")}>
        <TextInput
          {...register("lastName")}
          errorMessage={errors.lastName?.message}
        />
      </FormRow>
      <FormRow label={t("enabled")}>
        <CheckboxInput
          {...register("enabled")}
          type="checkbox"
          errorMessage={errors.enabled?.message}
        />
      </FormRow>
      <FormRow label={t("language")}>
        <Controller
          control={control}
          name="lang"
          render={({ field: { value, onChange } }) => (
            <ReactSelect
              className={`w-48 color-white  cursor-pointer`}
              value={
                value ? { label: <Option lang={value} />, value } : undefined
              }
              options={languages?.map(({ lang }) => ({
                label: <Option lang={lang} />,
                value: lang,
              }))}
              onChange={(selectedOption) => {
                onChange && onChange(String(selectedOption?.value));
              }}
            />
          )}
        />
        <ErrorMessage>{errors?.lang?.message}</ErrorMessage>
      </FormRow>
      <FormRow label={t("roles")}>
        <Controller
          control={control}
          name="roles"
          render={({ field: { value, onChange } }) => (
            <ReactSelect
              className={`w-48 color-white  cursor-pointer`}
              isMulti
              value={value}
              options={[
                { label: "SUPERADMIN", value: "ROLE_SUPERADMIN" },
                { label: "ADMIN", value: "ROLE_ADMIN" },
                { label: "MANAGER", value: "ROLE_MANAGER" },
                { label: "USER", value: "ROLE_USER" },
              ]}
              onChange={(selectedOptions) => {
                onChange && onChange(selectedOptions);
              }}
            />
          )}
        />
        <ErrorMessage>{errors?.roles?.message}</ErrorMessage>
        {/*<TextInput*/}
        {/*  {...register("roles")}*/}
        {/*  errorMessage={errors.roles?.message}*/}
        {/*/>*/}
      </FormRow>
      <FormRow>
        <ButtonSubmit size="lg" colorScheme="sky-blue" isLoading={isLoading}>
          {t("save")}
        </ButtonSubmit>
      </FormRow>
    </form>
  );
};

import { FunctionComponent, ReactNode } from "react";
import ShadowDOMComponent from "../ShadowDomComponent/ShadowDomComponent";
import { CustomFields, useCmsPage } from "../../cmsApi/Page";
import { useTranslation } from "react-i18next";

const CmsContent: FunctionComponent<{
  namespace: string;
  customFieldName: keyof CustomFields;
}> = ({ namespace, customFieldName }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { data } = useCmsPage({ namespace, language });

  return (
    <div>
      <ShadowDOMComponent>
        {data?.[customFieldName] ? (
          <div
            dangerouslySetInnerHTML={{
              __html: data?.[customFieldName] as string,
            }}
          ></div>
        ) : (
          <div />
        )}
      </ShadowDOMComponent>
    </div>
  );
};

export default CmsContent;

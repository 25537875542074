import { FunctionComponent } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const PublicRoute: FunctionComponent = () => {
  const { auth } = useAuth();
  const location = useLocation();

  return !auth.isLoggedIn || !auth.roles?.length ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default PublicRoute;

import React, { useState } from "react";
import TextInput from "../../../../components/Form/TextInput";
import ButtonSubmit from "../../../../components/Form/ButtonSubmit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RadioInput from "../../../../components/Form/RadioInput/RadioInput";
import { useSetCustomerActive } from "../../../../api/CommissionModify";
import FlashMessageHandler from "../../../../components/FlashMessageHandler";
import Alert from "../../../../components/Alert";
import useFlashMessage from "../../../../hooks/useFlashMessage";
import { FormRow } from "../../../../components/FormRow";

function SetCustomerAsActiveForm({
  bonusRunHistoryId,
}: {
  bonusRunHistoryId: string;
}) {
  const { t } = useTranslation("bonus_archive_management");
  const { displayFlashMessage } = useFlashMessage();
  const { mutate } = useSetCustomerActive({
    onError: (error) => {
      displayFlashMessage("SetCustomerAsActive", {
        description:
          error?.response?.status === 400 ? "invalid_data" : "error_occured",
        data: { type: "danger" },
      });
    },
    onSuccess: () => {
      reset();
      displayFlashMessage("SetCustomerAsActive", {
        description: "data_has_been_saved",
      });
    },
  });
  const schema = yup
    .object({
      customerId: yup.string().required("field_required"),
      active: yup.mixed<"0" | "1">().required("field_required"),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<{
    customerId: string;
    active: "0" | "1";
  }>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      customerId: undefined,
      active: "0",
    },
  });

  return (
    <>
      <FlashMessageHandler
        id={"SetCustomerAsActive"}
        render={({ description, data: { type = "success" } }) => (
          <Alert type={type}>
            {t(description, { ns: ["validation", "common"] })}
          </Alert>
        )}
      />
      <form
        className="flex flex-wrap items-center gap-x-2"
        onSubmit={handleSubmit((value) => {
          if (value.customerId) mutate({ ...value, bonusRunHistoryId });
        })}
      >
        <FormRow label={t("customer_number")} labelClassName="sm:w-auto pr-2">
          <TextInput
            {...register("customerId")}
            errorMessage={errors.customerId?.message}
          />
        </FormRow>
        <div className="flex gap-1 mx-1">
          <RadioInput
            value="0"
            label={t("set_subscriber")}
            {...register("active")}
          />
          <RadioInput
            value="1"
            label={t("set_customer")}
            {...register("active")}
          />
        </div>
        <ButtonSubmit size="sm" colorScheme="white">
          {t("submit", { ns: ["common"] })}
        </ButtonSubmit>
      </form>
    </>
  );
}

export default SetCustomerAsActiveForm;

import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ROUTES from "../../../navigation/route";
import useFlashMessage from "../../../hooks/useFlashMessage";
import { Link } from "react-router-dom";
import PageHeaderWrapper from "../../../components/PageHeaderWrapper";
import PageHeader from "../../../components/PageHeader";
import FlashMessageHandler from "../../../components/FlashMessageHandler";
import Button from "../../../components/Button";
import { useGetAdminUserTableData } from "../../../api/AdminUser";
import Alert from "../../../components/Alert";
import Users from "../../../components/Users";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/Pagination/Pagination";

const UsersPage: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation(["admin_users", "common"]);
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    perPage: 25,
  });
  const { data, isPending } = useGetAdminUserTableData(paginationParams);

  const { displayFlashMessage } = useFlashMessage();
  const isSmallScreen = window.innerWidth <= 1280;

  const renderUsersContent = () => {
    if (!data?.items.length) {
      return <h1>{t("no_users")}</h1>;
    }

    return (
      <div className="flex flex-col gap-4">
        {isSmallScreen ? (
          <Users.Cards items={data.items} />
        ) : (
          <Users.Table items={data.items} />
        )}
        <Pagination
          perPage={paginationParams.perPage}
          page={paginationParams.page}
          count={data.count}
          paginationCallback={(newPage) => {
            setPaginationParams((params) => ({ ...params, page: newPage }));
          }}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8 max-w-full">
      <PageHeaderWrapper>
        <PageHeader>{t("header").toUpperCase()}</PageHeader>
      </PageHeaderWrapper>
      <div className="flex flex-col py-4 text-sm font-default text-almostBlack-600 max-w-full">
        <FlashMessageHandler
          id={"AdminUserPage:success"}
          render={({ description }) => (
            <Alert type="success">{t(description, { ns: "common" })}</Alert>
          )}
        />
        <div className="py pb-4">
          <Link to={ROUTES.ADMIN_USERS_ADD}>
            <Button size="sm" colorScheme="sky-blue" onClick={() => {}}>
              {t("add", { ns: "common" })}
            </Button>
          </Link>
        </div>
        {isPending ? <Loader /> : renderUsersContent()}
      </div>
    </div>
  );
};

export default UsersPage;

import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import {
  SECTION_MEMBERS,
  SECTION_STATISTICS,
  SECTION_BONUS_REPORT,
  SECTION_SETTINGS,
  SECTION_TRANSLATIONS,
  SECTION_ADMIN,
} from "../components/Sidebar/consts";

const sections = [
  SECTION_MEMBERS,
  SECTION_STATISTICS,
  SECTION_BONUS_REPORT,
  SECTION_SETTINGS,
  SECTION_TRANSLATIONS,
  SECTION_ADMIN,
] as const;

export type Section = (typeof sections)[number];
export const SidebarContext = createContext<{
  openedSection: Section | null;
  setOpenedSection: Dispatch<SetStateAction<Section | null>>;
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}>({
  openedSection: null,
  setOpenedSection: () => {},
  sidebarOpen: false,
  setSidebarOpen: () => {},
});

export const SidebarProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [openedSection, setOpenedSection] = useState<Section | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const sidebarContextValue = {
    openedSection,
    setOpenedSection,
    sidebarOpen,
    setSidebarOpen,
  };
  return (
    <SidebarContext.Provider value={sidebarContextValue}>
      {children}
    </SidebarContext.Provider>
  );
};

import { useMutation, useQuery } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";
import queryClient, { BasicMutationOptions } from "../queryClient";
import { AxiosError } from "axios";
import { TableData } from "../types/TableData";
import { PaginationParams } from "../types/PaginationParams";

export type AdminUser = {
  id: number;
  login: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: NonNullable<boolean | undefined>;
  lang: string;
  createdAt: string;
  updatedAt: string;
  roles: string[];
};

export const useGetAdminUserTableData = ({
  page,
  perPage,
}: PaginationParams) => {
  const { privateApiClient } = useApiClient();
  return useQuery<TableData<AdminUser>, unknown>({
    queryKey: ["AdminUserList", page, perPage],
    queryFn: async () =>
      camelcaseKeys(
        (await privateApiClient.get(`/admin/user/list/${page}/${perPage}`))
          .data
      , { deep: true}),

    initialData: undefined,
  });
};

export const useGetAdminUser = ({ id }: { id: number }) => {
  const { privateApiClient } = useApiClient();
  return useQuery<AdminUser, unknown>({
    queryKey: ["AdminUser", id],
    queryFn: async () =>
      camelcaseKeys((await privateApiClient.get(`/admin/user/${id}`)).data),
    initialData: undefined,
  });
};

export type AdminUserModel = Omit<
  AdminUser,
  "id" | "lang" | "createdAt" | "updatedAt"
> & {
  id?: number;
  password?: string | null;
  lang?: string;
};

export const useAddAdminUserMutation = (
  mutationOptions?: BasicMutationOptions<any, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<{}, AxiosError, AdminUserModel>({
    mutationFn: async ({
      login,
      password,
      email,
      lang,
      firstName,
      lastName,
      enabled,
      roles,
    }) => {
      return (
        await privateApiClient.post(`/admin/user/add`, {
          login,
          password,
          email,
          lang,
          first_name: firstName,
          last_name: lastName,
          enabled,
          roles,
        })
      ).data;
    },

    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["AdminUserList"] }),
  });
};

export const useEditAdminUserMutation = (
  mutationOptions?: BasicMutationOptions<any, AxiosError>
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<{}, AxiosError, AdminUserModel>({
    mutationFn: async ({
      id,
      login,
      password,
      email,
      lang,
      firstName,
      lastName,
      enabled,
      roles,
    }: any) => {
      return (
        await privateApiClient.patch(`/admin/user/edit/${id}`, {
          login,
          password,
          email,
          lang,
          first_name: firstName,
          last_name: lastName,
          enabled,
          roles,
        })
      ).data;
    },
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["AdminUserList"] }),
  });
};

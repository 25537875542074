import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

type ShadowDOMComponentProps = {
  children: React.ReactNode;
};

const ShadowDOMComponent: React.FC<ShadowDOMComponentProps> = ({
  children,
}) => {
  const hostElement = useRef<HTMLDivElement | null>(null);
  const shadowRootRef = useRef<ShadowRoot | null>(null);

  useEffect(() => {
    if (hostElement.current && !shadowRootRef.current) {
      shadowRootRef.current = hostElement.current.attachShadow({
        mode: "open",
      });
    }

    if (shadowRootRef.current) {
      ReactDOM.render(<div>{children}</div>, shadowRootRef.current);
    }
  }, [children]);

  return <div ref={hostElement} />;
};

export default ShadowDOMComponent;

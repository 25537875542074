import { useQuery } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";
import { Role } from "biz-dashboard-frontend/lib/types/components/reports-core/types/Role";

export type UserInfo = {
  userId: number;
  custNo: number;
  homeShop: string;
  lang: string;
  pandaDocBpAgreementSigned: boolean;
  translationLastUpdate: string;
  impersonate: boolean;
  roles: Role[];
};

export const useGetUserInfo = ({ id }: { id?: string | number }) => {
  //TODO: ADD ID PARAMETER
  const { privateApiClient } = useApiClient();
  return useQuery<UserInfo, unknown>({
    queryKey: ["user_info"],
    queryFn: async () =>
      camelcaseKeys((await privateApiClient.get<UserInfo>(`/user/info`)).data),
    initialData: undefined,
    enabled: Boolean(id),
  });
};

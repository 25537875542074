import React from "react";
import Button from "../Button";
import { ColorScheme, colorSchemes } from "../../theme/colorSchemes";
import Icon from "../Icon";
import { cn } from "../../helpers/cn";

type DashboardNotificationPropsType = {
  id: number;
  message: string;
  additionalContent?: React.ReactElement;
  type?: ColorScheme<"dashboardNotification">;
  onClose?: (id: number) => void;
  color?: string;
  backgroundColor?: string;
};
function DashboardNotification({
  id,
  message,
  additionalContent,
  type = "default",
  onClose,
  backgroundColor,
  color,
}: DashboardNotificationPropsType) {
  const isClosable = !!onClose;
  const handleCloseNotfication = () => {
    if (!onClose) return;

    onClose(id);
  };

  return (
    <div
      key={id}
      id={id.toString()}
      className={cn(
        `${colorSchemes.dashboardNotification[type]} w-100 flex ${
          isClosable ? "justify-between" : "justify-center"
        } items-center font-gothicStdMedium font-semibold py-2 px-4`,
        color && `text-[${color}]`,
        backgroundColor && `bg-[${backgroundColor}]`,
      )}
    >
      {isClosable && <div></div>}
      <div className="flex flex-col justify-center items-center">
        <div className="flex text-xxs">{message}</div>
        <div className="flex text-xxs">{additionalContent}</div>
      </div>
      {isClosable && (
        <>
          <Button
            colorScheme="ghost"
            size="xs"
            onClick={handleCloseNotfication}
          >
            <div className="rotate-45 text-xl leading-3	">+</div>
          </Button>
        </>
      )}
    </div>
  );
}

export default DashboardNotification;

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Form } from "../../../types/form";
import TextInput from "../components/form/TextInput";
import ButtonSubmit from "../components/form/ButtonSubmit";
import Icon from "../../../components/Icon";

type FormValues = {
  username: string;
  email: string;
};

const schema = yup
  .object({
    username: yup.string().trim().required("field_required"),
    email: yup.string().trim().required("field_required"),
  })
  .required();

const RemindPasswordForm: Form<FormValues> = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation(['home']);

  const { formState, handleSubmit, ...formMethods } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider<FormValues>
      formState={formState}
      {...formMethods}
      handleSubmit={handleSubmit}
    >
      <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name="username"
          sprite={<Icon name="user" />}
          placeholder="Username"
          errorMessage={formState.errors.username?.message}
        />
        <TextInput
          name="email"
          sprite={<Icon name="user" />}
          type="email"
          placeholder="E-mail"
          errorMessage={formState.errors.email?.message}
        />
        <div className="flex justify-end">
          <ButtonSubmit isLoading={isLoading}>
            {t("reset_password")}
          </ButtonSubmit>
        </div>
      </form>
    </FormProvider>
  );
};

export default RemindPasswordForm;

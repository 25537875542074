import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { useGetLanguageList } from "../../api/Language";
import Option from "./Option";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const customStylesDark = {
  singleValue: (provided: any) => ({
    ...provided,
    height: "100%",
    color: "white",
    paddingTop: "3px",
    cursor: "pointer",
  }),
  control: (base: any, state: any) => ({
    ...base,
    background: "#303a49",
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "#3d495c" : "#3d495c",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#00BCE6" : "#00BCE6",
    },
    cursor: "pointer",
  }),
};

const customStylesLight = {
  singleValue: (provided: any) => ({
    ...provided,
    height: "100%",
    paddingTop: "3px",
    cursor: "pointer",
  }),
  control: (base: any, state: any) => ({
    ...base,
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "#3d495c" : "#ffffff",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: !state.isFocused ? "#00BCE6" : "#ffffff",
    },
    cursor: "pointer",
  }),
};

const LanguageSelect: FunctionComponent<{
  onChange?: (language: string) => void;
  variant?: string;
  menuPlacement?: "top" | "bottom";
  value?: string;
  isLoading?: boolean;
}> = ({
  onChange,
  variant = "dark",
  menuPlacement = "top",
  value,
  isLoading = false,
}) => {
  const { data } = useGetLanguageList();
  const { i18n } = useTranslation();

  return (
    <div className={`flex w-64`}>
      <ReactSelect
        isLoading={isLoading}
        styles={variant === "light" ? customStylesLight : customStylesDark}
        className={`w-64 color-white  cursor-pointer`}
        menuPlacement={menuPlacement}
        value={
          value
            ? { label: <Option lang={value} />, value }
            : { label: <Option lang={i18n.language} />, value: i18n.language }
        }
        options={data?.map(({ lang }) => ({
          label: <Option lang={lang} />,
          value: lang,
        }))}
        onChange={(selectedOption) => {
          onChange && onChange(String(selectedOption?.value));
        }}
      />
    </div>
  );
};

export default LanguageSelect;

import { forwardRef, ReactElement } from "react";
import { ErrorMessage } from "../index";

type SelectInputProps = {
  children?: ReactElement | ReactElement[];
  errorMessage?: string;
};

const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
  ({ children, errorMessage, ...rest }, ref) => {
    return (
      <div className="flex flex-col w-48">
        <select
          ref={ref}
          {...rest}
          className="pl-2 rounded-sm shadow-dashboard-input bg-white text-gray-900 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2 focus:outline-none"
        >
          {children}
        </select>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  },
);

export default SelectInput;

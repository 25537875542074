import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from "react";
import { ErrorMessage } from "../index";
type RadioInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  errorMessage?: string;
  name: string;
  label: string;
  value: string;
};

const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({ errorMessage, label, value, ...rest }, ref) => {
    return (
      <div className="flex flex-col">
        <div className="flex gap-1">
          <label>{label}</label>
          <input type="radio" value={value} ref={ref} {...rest} />
        </div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </div>
    );
  },
);

export default RadioInput;

import { AxiosError } from "axios";
import { FunctionComponent, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSignInMutation } from "../../api/Auth";
import Alert from "../../components/Alert";
import { HttpStatusCode } from "../../enums/HttpStatusCode";
import useAuth from "../../hooks/useAuth";
import PinForm from "./form/PinForm";
import { useTranslation } from "react-i18next";
import Icon from "../../components/Icon";

type LocationStateType = {
  username: string;
  password: string;
  remember: boolean;
};

const Pin: FunctionComponent = (): JSX.Element => {
  const { username, password, remember } = useLocation()
    .state as LocationStateType;

  const { onSignIn } = useAuth();

  const [errorMessage, setErrorMessage] = useState<string>();

  const signInMutation = useSignInMutation();

  const { t } = useTranslation();
  const handleSignIn = (pin: string) => {
    signInMutation.mutate(
      {
        username,
        password,
        pin,
      },
      {
        onSuccess: ({ token, refresh_token, homeShop }) => {
          onSignIn({
            accessToken: token,
            refreshToken: refresh_token,
            isLoggedIn: true,
            remember,
            homeShop: homeShop,
          });
        },
        onError: (error: AxiosError) => {
          if (error.response?.status === HttpStatusCode.UNAUTHORIZED) {
            setErrorMessage("invalid_credentials");
          } else if (error.code === AxiosError.ERR_NETWORK) {
          }
        },
      },
    );
  };

  return (
    <div className="flex flex-col flex-1 h-full max-w-lg bg-bizDarkBlue-500 pb-9 font-gothicBookRegular">
      <div className="flex bg-skyBlue-600 h-20 rounded-t justify-center items-center">
        <Icon name="login" />
        <h1 className="pl-4 text-white">ENTER YOUR PIN CODE</h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-1 flex-col py-8 px-8 sm:px-16">
          {errorMessage && <Alert type="danger">{t(errorMessage)}</Alert>}
          <PinForm
            onSubmit={({ pin }) => handleSignIn(pin)}
            setErrorMessage={(errorMessage: string) =>
              setErrorMessage(errorMessage)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Pin;

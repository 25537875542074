import React from "react";
import Loader from "../../components/Loader";
import Sprite from "../../components/Sprite";
import useAuth from "../../hooks/useAuth";

function LoadingPage() {
  const { auth } = useAuth();

  return (
    <main className="flex min-h-screen flex-col bg-bizDarkBlue-600">
      {!auth.isLoggedIn && (
        <div className="flex bg-white justify-center pt-4 pb-5">
          <Sprite width={206} height={34} x={0} y={0} />
        </div>
      )}
      <div className="flex justify-center items-center flex-1 pt-4 pb-8 px-4">
        <Loader />
      </div>
    </main>
  );
}

export default LoadingPage;

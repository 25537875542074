import { FunctionComponent, ReactElement } from "react";
import withI18nNamespace from "../../helpers/withI18nNamespace";
import { useTranslation } from "react-i18next";
import useApiClient from "../../hooks/useApiClient";
import { ComponentsI18nNamespaces } from "biz-dashboard-frontend/src/ComponentsI18nNamespaces";
import { UserProvider } from "biz-dashboard-frontend";
import useUser from "../../hooks/useUser";
import useAuth from "../../hooks/useAuth";
import { useWebComponentsWithSPARouting } from "../../hooks/useWebComponentsWithSPARouting";
import CmsContent from "../CmsContent";
const ReportWrapper: FunctionComponent<{
  Component: FunctionComponent<any>;
  namespace: ComponentsI18nNamespaces;
  params?: any;
}> = ({ Component, namespace, params }): ReactElement => {
  useWebComponentsWithSPARouting();
  const { i18n } = useTranslation();
  const { user } = useUser();
  const { auth } = useAuth();

  const { privateApiClient } = useApiClient();
  return (
    <div className="flex flex-col flex-1 h-full pb-9 bg-bizGray-200 px-4 sm:px-12 py-8">
      <UserProvider
        custNo={user.custNo}
        userName={String(user.custNo)}
        roles={auth.roles}
        isCrm={false}
        homeShop={auth.homeShop}
      />
      <CmsContent namespace={namespace} customFieldName={"header"} />
      <Component
        i18n={withI18nNamespace(namespace, i18n)}
        apiClient={privateApiClient}
        {...params}
      />
      <CmsContent namespace={namespace} customFieldName={"footer"} />
    </div>
  );
};

export default ReportWrapper;

import { useMutation } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";
import { AxiosError } from "axios";
import { BasicMutationOptions } from "../queryClient";

type PointPointsToCurrentMonthPropsType = {
  orderId: string;
  bonusRunHistoryId: string;
};

export const usePointsToCurrentMonth = (
  mutationOptions?: BasicMutationOptions<any, AxiosError<{ message: string }>>,
) => {
  const { privateApiClient } = useApiClient();
  return useMutation<
    {},
    AxiosError<{ message: string }>,
    PointPointsToCurrentMonthPropsType
  >({
    mutationFn: async ({
      orderId,
      bonusRunHistoryId,
    }: PointPointsToCurrentMonthPropsType) =>
      camelcaseKeys(
        (
          await privateApiClient.patch(
            `/admin/commission/modify/order/${orderId}/points/move/${bonusRunHistoryId}`,
          )
        ).data,
      ),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

type PointsFromCustomerToCustomer = {
  customerFromId: string;
  customerToId: string;
  bonusRunHistoryId: string;
  qv: string;
  bv: string;
};

export const usePointsFromCustomerToCustomer = (
  mutationOptions?: BasicMutationOptions<any, AxiosError<{ message: string }>>,
) => {
  const { privateApiClient } = useApiClient();
  return useMutation<
    { qv: string; bv: string },
    AxiosError<{ message: string }>,
    PointsFromCustomerToCustomer
  >({
    mutationFn: async ({
      customerFromId,
      customerToId,
      bonusRunHistoryId,
      qv,
      bv,
    }: PointsFromCustomerToCustomer) =>
      camelcaseKeys(
        (
          await privateApiClient.patch(
            `/admin/commission/modify/customer/${customerFromId}/points/move/${customerToId}/${bonusRunHistoryId}`,
            { qv, bv },
          )
        ).data,
      ),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

type SetCustomerAsActivePropsType = {
  bonusRunHistoryId: string;
  customerId: string;
  active: "0" | "1";
};
export const useSetCustomerActive = (
  mutationOptions?: BasicMutationOptions<any, AxiosError<{ message: string }>>,
) => {
  const { privateApiClient } = useApiClient();
  return useMutation<
    {},
    AxiosError<{ message: string }>,
    SetCustomerAsActivePropsType
  >({
    mutationFn: async ({
      customerId,
      bonusRunHistoryId,
      active,
    }: SetCustomerAsActivePropsType) =>
      camelcaseKeys(
        (
          await privateApiClient.patch(
            `/admin/commission/modify/customer/${customerId}/active/${active}/${bonusRunHistoryId}`,
          )
        ).data,
      ),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

type GivePointsDirectly = {
  bonusRunHistoryId: string;
  customerId: string;
  qv: string;
  bv: string;
};
export const useGivePointsDirectly = (
  mutationOptions?: BasicMutationOptions<any, AxiosError<{ message: string }>>,
) => {
  const { privateApiClient } = useApiClient();
  return useMutation<
    { qv: string; bv: string },
    AxiosError<{ message: string }>,
    GivePointsDirectly
  >({
    mutationFn: async ({
      customerId,
      bonusRunHistoryId,
      qv,
      bv,
    }: GivePointsDirectly) =>
      camelcaseKeys(
        (
          await privateApiClient.patch(
            `/admin/commission/modify/customer/${customerId}/points/add/${bonusRunHistoryId}`,
            { qv, bv },
          )
        ).data,
      ),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

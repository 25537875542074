import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form } from "../../../types/form";
import TextInput from "../components/form/TextInput";
import ButtonSubmit from "../components/form/ButtonSubmit";
import CheckboxInput from "../components/form/CheckboxInput";
import Icon from "../../../components/Icon";

type FormValues = {
  username: string;
  password: string;
  remember: boolean;
};

const schema = yup
  .object({
    username: yup.string().trim().required("field_required"),
    password: yup.string().trim().required("field_required"),
    remember: yup.boolean().default(false),
  })
  .required();

const SignInForm: Form<FormValues> = ({ onSubmit, isLoading }) => {
  const { formState, handleSubmit, ...formMethods } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider<FormValues>
      formState={formState}
      {...formMethods}
      handleSubmit={handleSubmit}
    >
      <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name="username"
          sprite={<Icon name="user" />}
          placeholder="Username"
          errorMessage={formState.errors.username?.message}
        />
        <TextInput
          name="password"
          sprite={<Icon name="lock" />}
          type="password"
          placeholder="Password"
          errorMessage={formState.errors.password?.message}
        />
        <CheckboxInput
          name="remember"
          errorMessage={formState.errors.remember?.message}
        >
          Remember me on this computer
        </CheckboxInput>
        <div className="flex justify-end">
          <ButtonSubmit isLoading={isLoading}>Login</ButtonSubmit>
        </div>
      </form>
    </FormProvider>
  );
};

export default SignInForm;

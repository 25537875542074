import axios from "axios";

export const privateApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export const publicApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export const cmsApiClient = axios.create({
  baseURL: process.env.REACT_APP_CMS_API_ENDPOINT,
});

import {
  DetailedHTMLProps,
  FunctionComponent,
  InputHTMLAttributes,
} from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../../../../components/Form";

type TextInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  errorMessage?: string;
  sprite: JSX.Element;
  name: string;
};

const TextInput: FunctionComponent<TextInputProps> = ({
  sprite,
  errorMessage,
  name,
  ...rest
}) => {
  const { register } = useFormContext();

  return (
    <div className="flex flex-col">
      <span className="inline-flex items-center px-1 pt-1 text-sm rounded-l-md absolute">
        {sprite}
      </span>
      <input
        {...register(name)}
        {...rest}
        className="pl-10 rounded-none rounded-l-md rounded-r-md bg-white text-gray-900 block flex-1 min-w-0 w-full text-sm border-gray-300 p-3.5 focus:outline-none"
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
};

export default TextInput;

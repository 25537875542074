import { FunctionComponent, ReactElement } from "react";

type UploadPhotoAlertProps = {
  title?: string;
  description: ReactElement;
  messages: string[];
};

const ExtendedErrorAlert: FunctionComponent<UploadPhotoAlertProps> = ({
  title,
  description,
  messages,
}) => {
  return (
    <div
      className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
      role="alert"
    >
      <div>
        {title && <span className="font-medium">{title}</span>}
        {description && <span>{description}</span>}
        <ul className="mt-1.5 ml-4 list-disc list-inside">
          {messages.map((message) => (
            <li>{message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ExtendedErrorAlert;

import { useMutation, useQuery } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";
import queryClient, { BasicMutationOptions } from "../queryClient";
import { AxiosError } from "axios";

export type Language = {
  id: number;
  lang: string;
  fullLangName: string;
};

export type LanguageResponse = {
  items: Language[];
  count: number;
};

export const useGetLanguageList = () => {
  const { publicApiClient } = useApiClient();
  return useQuery<Language[], unknown>({
    queryKey: ["LanguageList"],
    queryFn: async () =>
      camelcaseKeys(
        (
          await publicApiClient.get<LanguageResponse>(
            `/translation/language/list`,
          )
        ).data.items,
      ),
    initialData: undefined,
  });
};

type CustomerLanguageUpdateModel = {
  lang: string;
};

export const useUpdateCustomerLanguageMutation = (
  mutationOptions?: BasicMutationOptions<
    CustomerLanguageUpdateModel,
    AxiosError
  >,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<
    CustomerLanguageUpdateModel,
    AxiosError,
    CustomerLanguageUpdateModel
  >({
    mutationKey: ["Language"],
    mutationFn: async ({ lang }) => {
      return (
        await privateApiClient.patch(`/customer/settings/language/${lang}`)
      ).data;
    },
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["user_info"] }),
  });
};

export const useDeleteLanguageMutation = (
  mutationOptions?: BasicMutationOptions<any, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<{}, AxiosError, { id: number }>({
    mutationKey: ["Language"],
    mutationFn: async ({ id }) => {
      return (
        await privateApiClient.delete(`/translation/language/delete/${id}`)
      ).data;
    },
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["LanguageList"] }),
  });
};

export type LanguageModel = Omit<Language, "id" | "lang"> & {
  id?: number;
  lang?: string;
};

export const useAddLanguageMutation = (
  mutationOptions?: BasicMutationOptions<any, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<{}, AxiosError, LanguageModel>({
    mutationKey: ["Language"],
    mutationFn: async ({ lang, fullLangName }) => {
      return (
        await privateApiClient.post(`/translation/language/add`, {
          lang,
          fullLangName,
        })
      ).data;
    },
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["LanguageList"] }),
  });
};

export const useUpdateLanguageMutation = (
  mutationOptions?: BasicMutationOptions<any, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<{}, AxiosError, LanguageModel>({
    mutationKey: ["Language"],
    mutationFn: async ({ id, fullLangName }) => {
      return (
        await privateApiClient.patch(`/translation/language/edit/${id}`, {
          fullLangName,
        })
      ).data;
    },
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["LanguageList"] }),
  });
};

import ROUTES from "../../navigation/route";
import { NavChildren } from "./components/CollapsedNavChildren";

export const SECTION_MEMBERS = "members";
export const SECTION_SETTINGS = "settings";
export const SECTION_BONUS_REPORT = "bonus_report";
export const SECTION_STATISTICS = "statistics";
export const SECTION_TRANSLATIONS = "translations";
export const SECTION_ADMIN = "admin";

export const ADMIN_NAV_CHILDREN_COLLECTION: NavChildren[] = [
  {
    route: ROUTES.ADMIN_USERS_PAGE,
    label: "users",
  },
  {
    route: ROUTES.ADMIN_NOTIFICATIONS,
    label: "notifications",
  },
  {
    route: ROUTES.MANAGER_CUSTOMERS_PAGE,
    label: "customers",
  },
  {
    route: ROUTES.ADMIN_BONUS_ARCHIVE_MANAGEMENT,
    label: "bonus_archive_management",
  },
  {
    route: ROUTES.ADMIN_LIVE_BONUS_BUILDER,
    label: "live_bonus_builder",
  },
];

export const TRANSLATIONS_NAV_CHILDREN_COLLECTION: NavChildren[] = [
  {
    route: ROUTES.TRANSLATIONS,
    label: "translations",
  },
  {
    route: ROUTES.LANGUAGE_MANAGEMENT,
    label: "languages",
  },
];
export const SETTINGS_NAV_CHILDREN_COLLECTION: NavChildren[] = [
  { route: ROUTES.LANGUAGE, label: "language" },
  {
    route: ROUTES.PAYMENT_INFORMATION,
    label: "bank_data",
  },
  {
    route: ROUTES.PIN_MANAGEMENT,
    label: "pin_management",
  },
];
export const BONUS_REPORT_NAV_CHILDREN_COLLECTION: NavChildren[] = [
  {
    route: "/?do=bonusreport",
    label: "commission_report",
    isOldRouting: true,
  },
  {
    route: "/?do=payoutsreport",
    label: "payouts_report",
    isOldRouting: true,
  },
  {
    route: "/?do=pccbonusreport",
    label: "pcc_bonus_report",
    isOldRouting: true,
  },
  {
    route: "/?do=teamcommissionbonusreport",
    label: "team_commission_bonus_report",
    isOldRouting: true,
  },
  {
    route: "/?do=lifestylebonusreport",
    label: "lifestyle_bonus_report",
    isOldRouting: true,
  },
  {
    route: "/?do=rankadvancementbonusreport",
    label: "rank_advancement_bonus_report",
    isOldRouting: true,
  },
  {
    route: "/?do=coachingcommissionreport",
    label: "coaching_commission_report",
    isOldRouting: true,
  },
  {
    route: "/?do=cabAcceleratorReport",
    label: "cab_accelerator_report",
    isOldRouting: true,
  },
  {
    route: "/?do=liveBonusReport",
    label: "live_bonuses_bonus_report",
    isOldRouting: true,
  },
  {
    route: "/?do=qvBoosterReport",
    label: "qv_booster_bonus_report",
    isOldRouting: true,
  },
  {
    route: "/?do=memberscontestbonusreport",
    label: "members_contest_bonus_report",
    isOldRouting: true,
  },
  {
    route: "/?do=arcticChallenge",
    label: "arctic_challenge_report",
    isOldRouting: true,
  },
  {
    route: "/?do=lifestyleTripDetails",
    label: "lifestyle_trip_report",
    isOldRouting: true,
  },
  {
    route: "/?do=premiumChallenge",
    label: "premium_challenge_report",
    isOldRouting: true,
  },
  {
    route: "/?do=bonusactivelegs",
    label: "active_legs_report",
    isOldRouting: true,
  },
  {
    route: "/?do=productList",
    label: "product_list_report",
    isOldRouting: true,
  },
];

export const STATISTICS_NAV_CHILDREN_COLLECTION: NavChildren[] = [
  {
    route: "/?do=stat",
    label: "statistics_report",
    isOldRouting: true,
  },
  {
    route: "/?do=recruitmentContestReport",
    label: "recruitment_contest_report",
    isOldRouting: true,
  },
  {
    route: "/?do=top10EarnersReport",
    label: "top_10_earners_report",
    isOldRouting: true,
  },
  {
    route: "/?do=lifestyleTripTop20Qualifiers",
    label: "top_lifestyle_trip_qualifiers",
    isOldRouting: true,
  },
];
export const MEMBERS_NAV_CHILDREN_COLLECTION: NavChildren[] = [
  {
    route: "/?do=membersearch",
    label: "search_members_report",
    isOldRouting: true,
  },
  {
    route: "/?do=tree",
    label: "organisation_tree",
    isOldRouting: true,
  },
  {
    route: "/?do=groupsearch",
    label: "group_search_report",
    isOldRouting: true,
  },
  {
    route: "/?do=distributors",
    label: "business_partners_report",
    isOldRouting: true,
  },
  {
    route: "/?do=titlesstatus",
    label: "active_titles_status_report",
    isOldRouting: true,
  },
  {
    route: "/?do=activelegs",
    label: "active_legs_report",
    isOldRouting: true,
  },
  {
    route: "/?do=recruits",
    label: "my_members_report",
    isOldRouting: true,
  },
  {
    route: "/?do=myMembersExport",
    label: "my_members_export_report",
    isOldRouting: true,
  },
  {
    route: "/?do=inactives",
    label: "inactive_customers_report",
    isOldRouting: true,
  },
  {
    route: "/?do=newmembers",
    label: "new_customers_report",
    isOldRouting: true,
  },
  {
    route: "/?do=newbusinesskits",
    label: "business_kits_report",
    isOldRouting: true,
  },
  {
    route: "/?do=newtitles",
    label: "new_titles_report",
    isOldRouting: true,
  },
  {
    route: "/?do=openordersreport",
    label: "organisation_orders_report",
    isOldRouting: true,
  },
  {
    route: "/?do=productsStatisticsReport",
    label: "product_statistics",
    isOldRouting: true,
  },
  {
    route: "/?do=endingSubscriptionsReport",
    label: "ending_subscriptions_report",
    isOldRouting: true,
  },
  {
    route: "/?do=pausingcustomers",
    label: "pausing_customers_report",
    isOldRouting: true,
  },
  {
    route: "/?do=expiredcards",
    label: "expiring_cards_report",
    isOldRouting: true,
  },
  {
    route: "/?do=waitingroom",
    label: "waiting_room",
    isOldRouting: true,
  },
  {
    route: "/?do=distributors30day",
    label: "fsq_program_report",
    isOldRouting: true,
  },
];

import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Notification, NotificationType } from "../../api/UserNotification";
import ROUTES from "../../navigation/route";
import DashboardNotification from "../DashboardNotification";

type UpdateBankDetailsInfoPropsType = { notifications: Notification[] };

const UpdateBankDetailsInfo: FunctionComponent<
  UpdateBankDetailsInfoPropsType
> = ({ notifications }) => {
  const { t } = useTranslation("home");
  const missingPayment = notifications?.find(
    ({ type }) => type === NotificationType.MissingPaymentData,
  );

  if (!missingPayment) return null;

  return (
    <DashboardNotification
      type="important"
      message={t("update_bank_account_description")}
      id={missingPayment.id}
      additionalContent={
        <Link to={ROUTES.PAYMENT_INFORMATION} className="underline">
          {t("update_bank_account_button")}
        </Link>
      }
    />
  );
};

export default UpdateBankDetailsInfo;

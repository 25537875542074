import { createContext, FunctionComponent, ReactNode } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import queryClient from "../queryClient";
import { jwtDecode } from "jwt-decode";
import { Role } from "biz-dashboard-frontend/lib/types/components/reports-core/types/Role";

export type AuthType = {
  accessToken: string;
  refreshToken: string;
  roles: Role[];
  isLoggedIn: boolean;
  remember: boolean;
  id: string;
  username: string;
  homeShop: string;
};

type SignInData = Pick<
  AuthType,
  "accessToken" | "refreshToken" | "isLoggedIn" | "remember" | "homeShop"
>;

type AccessTokenData = {
  custNo: string;
  roles: Role[];
  homeShop: string;
  username: string;
};

type AuthContextType = {
  auth: AuthType;
  impersonatedAuth: AuthType;
  setAuth: (value: AuthType) => void;

  setImpersonatedAuth: (value: AuthType) => void;
  onSignIn: (value: SignInData) => void;
  onImpersonate: (value: SignInData) => void;
  logout: () => void;
  logoutImpersonatedUser: () => void;
};

const initialValues = {
  accessToken: "",
  refreshToken: "",
  roles: [],
  isLoggedIn: false,
  id: "",
  remember: false,
  homeShop: "",
  username: "",
};

const AuthContext = createContext<AuthContextType>({
  auth: initialValues,
  impersonatedAuth: initialValues,
  onSignIn: () => {},
  onImpersonate: () => {},
  setAuth: () => {},
  logout: () => {},
  logoutImpersonatedUser: () => {},
  setImpersonatedAuth: () => {},
});

export const AuthProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [auth, setAuth] = useLocalStorage<AuthType>("auth", initialValues);

  const [impersonatedAuth, setImpersonatedAuth] = useLocalStorage<AuthType>(
    "impersonatedAuth",
    initialValues,
  );

  const onSignIn = (data: SignInData) => {
    const { custNo, roles, homeShop, username } = jwtDecode<AccessTokenData>(
      data.accessToken,
    );
    setAuth({
      ...data,
      id: custNo,
      roles,
      homeShop,
      username,
    });
  };

  const onImpersonate = (data: SignInData) => {
    const { custNo, roles, homeShop, username } = jwtDecode<AccessTokenData>(
      data.accessToken,
    );
    setImpersonatedAuth({
      ...data,
      id: custNo,
      roles,
      homeShop,
      username,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        auth: impersonatedAuth.accessToken ? impersonatedAuth : auth,
        impersonatedAuth,
        setAuth,
        setImpersonatedAuth,
        onSignIn,
        onImpersonate,
        logout: () => {
          queryClient.removeQueries();
          setAuth(initialValues);
        },
        logoutImpersonatedUser: () => {
          setImpersonatedAuth(initialValues);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

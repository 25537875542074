import { AxiosError } from "axios";
import { useEffect } from "react";
import { privateApiClient, publicApiClient, cmsApiClient } from "../ApiClient";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";
import { AxiosInstance } from "axios";
import useRememberMeLogin from "./useRememberMeLogin";
import {jwtDecode} from "jwt-decode";

export const useApiClient = (): {
  privateApiClient: AxiosInstance;
  publicApiClient: AxiosInstance;
  cmsApiClient: AxiosInstance;
} => {
  const { auth, logout } = useAuth();
  const rememberMeLogin = useRememberMeLogin();
  const refreshToken = useRefreshToken();

  useEffect(() => {
    const onError = (error: AxiosError) => {
      if (error.code === AxiosError.ERR_NETWORK) {
        // TODO
      }
    };
    const privateApiClientRequestIntercept =
      privateApiClient.interceptors.request.use(
        (config) => {
          config.headers = config.headers ?? {};
          const accessToken = auth.accessToken;
          if (accessToken && !config.headers["Authorization"]) {
            config.headers.Authorization = `Bearer ${accessToken}`;
          }

          return config;
        },
        (error) => {
          return Promise.reject(error);
        },
      );

    const privateApiClientResponseIntercept =
      privateApiClient.interceptors.response.use(
        (response) => response,
        async (error) => {
          const prevRequest = error?.config;
          const currentTime = Math.floor(Date.now() / 1000);

          try {
            if (Math.abs(jwtDecode(auth.accessToken)?.exp ?? 0 - currentTime) <= 900) {
              await refreshToken();
            }
          } catch (e) {}

          if (
            error?.response?.status === 401 &&
            !prevRequest?.sent &&
            auth.remember
          ) {
            prevRequest.sent = true;
            try {
              const newAccessToken = await rememberMeLogin();
              prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
              return privateApiClient(prevRequest);
            } catch (error) {
              logout();
              return Promise.reject(error);
            }
          } else if (error?.response?.status === 401 && !prevRequest?.sent) {
            Promise.resolve();
            logout();
          }

          onError(error);

          return Promise.reject(error);
        },
      );

    const publicApiClientResponseIntercept =
      publicApiClient.interceptors.response.use(
        (response) => response,
        async (error) => {
          onError(error);
          return Promise.reject(error);
        },
      );

    return () => {
      privateApiClient.interceptors.request.eject(
        privateApiClientRequestIntercept,
      );
      privateApiClient.interceptors.response.eject(
        privateApiClientResponseIntercept,
      );
      publicApiClient.interceptors.response.eject(
        publicApiClientResponseIntercept,
      );
    };
  }, [auth, rememberMeLogin, logout]);

  return { privateApiClient, publicApiClient, cmsApiClient };
};

export default useApiClient;

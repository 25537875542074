import { forwardRef } from "react";
import { ErrorMessage } from "../index";

type TextInputProps = {
  errorMessage?: string;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ errorMessage, ...rest }, ref) => {
    return (
      <div className="flex flex-col w-48">
        <input
          ref={ref}
          {...rest}
          className="pl-2 rounded-sm shadow-dashboard-input bg-white text-gray-900 block flex-1 min-w-0 w-full text-sm border-gray-300 p-1.5 focus:outline-none"
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  },
);

export default TextInput;

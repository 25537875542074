import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useApiClient from "../hooks/useApiClient";
import queryClient, { BasicMutationOptions } from "../queryClient";

type SignInResponseData = {
  username: string;
  custNo: number;
  id: number;
  homeShop: string;
  impersonate: true;
  roles: string[];
  token: string;
  refresh_token: string;
  refresh_token_expiration: number;
};

export type SignInRequestParams = {
  username: string;
  password: string;
  pin?: string;
};

export const useSignInMutation = (
  mutationOptions?: BasicMutationOptions<SignInResponseData, AxiosError>,
) => {
  const { publicApiClient } = useApiClient();

  return useMutation<SignInResponseData, AxiosError, SignInRequestParams>({
    mutationFn: async (data) =>
      (await publicApiClient.post(`/login_check`, data)).data,
    mutationKey: ["signIn"],
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

type SignUpResponseData = {
  url: string;
};

type SignUpRequestParams = {
  custNo: string;
};

export const useSignUpMutation = (
  mutationOptions?: BasicMutationOptions<SignUpResponseData, AxiosError>,
) => {
  const { publicApiClient } = useApiClient();

  return useMutation<SignUpResponseData, AxiosError, SignUpRequestParams>({
    mutationKey: ["signup"],
    mutationFn: async ({ custNo }) =>
      (await publicApiClient.get(`/user/register/new/${custNo}`)).data,
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

export const useIsPinRequiredMutation = (
  mutationOptions?: BasicMutationOptions<
    {
      pinNeeded: boolean;
    },
    AxiosError
  >,
) => {
  const { publicApiClient } = useApiClient();

  return useMutation<
    {
      pinNeeded: boolean;
    },
    AxiosError,
    { username: string }
  >({
    mutationKey: ["Pin"],
    mutationFn: async ({ username }) =>
      (await publicApiClient.get(`/customer/settings/pin/info/${username}`))
        .data?.pinNeeded,
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

export const useIsPinRequiredQuery = ({ username }: { username: string }) => {
  const { publicApiClient } = useApiClient();
  return useQuery<
    {
      pinNeeded: boolean;
    },
    unknown
  >({
    queryKey: ["Pin"],
    queryFn: async () =>
      (
        await publicApiClient.get<{
          pinNeeded: boolean;
        }>(`/customer/settings/pin/info/${username}`)
      ).data,
    initialData: undefined,
    enabled: Boolean(username),
  });
};

export type PinUpdateModel = {
  newPin: string;
  existingPin?: string;
};

export const useUpdatePinMutation = (
  mutationOptions?: BasicMutationOptions<PinUpdateModel, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<PinUpdateModel, AxiosError, PinUpdateModel>({
    mutationKey: ["Pin"],
    mutationFn: async (data) => {
      return (await privateApiClient.patch(`/customer/settings/pin`, data))
        .data;
    },
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["Pin"] }),
  });
};

export type PinDeleteModel = {
  existingPin: string;
};
export const useDeletePinMutation = (
  mutationOptions?: BasicMutationOptions<PinDeleteModel, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<PinDeleteModel, AxiosError, PinDeleteModel>({
    mutationKey: ["pin"],
    mutationFn: async (existingPin: PinDeleteModel) =>
      await privateApiClient.delete(`/customer/settings/pin`, {
        data: existingPin,
      }),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["Pin"] }),
  });
};

export type RemindPasswordRequestParams = {
  username: string;
  email: string;
};

export const useRemindPasswordMutation = (
  mutationOptions?: BasicMutationOptions<{}, AxiosError>,
) => {
  const { publicApiClient } = useApiClient();

  return useMutation<{}, AxiosError, RemindPasswordRequestParams>({
    mutationKey: ["remindPassword"],
    mutationFn: async (data) =>
      (
        await publicApiClient.post(`/customer/password/reminder`, {
          ...data,
          key: process.env.REACT_APP_SECURITY_KEY,
        })
      ).data,
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
  });
};

export type ImpersonateRequestParams = {
  custNo: number;
};
export const useImpersonateMutation = (
  mutationOptions?: BasicMutationOptions<{ token: string }, AxiosError>,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<{ token: string }, AxiosError, ImpersonateRequestParams>({
    mutationKey: ["impersonate"],
    mutationFn: async ({ custNo }) =>
      (await privateApiClient.get(`/token/impersonate/user/${custNo}`)).data,
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["user_info"] });
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });
};

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LoadingPage from "./pages/LoadingPage/LoadingPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<LoadingPage />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
);

reportWebVitals();

import { FunctionComponent, ReactElement, useContext } from "react";
import { cn } from "../../../helpers/cn";
import CollapsedNavChildren, { NavChildren } from "./CollapsedNavChildren";
import { Section, SidebarContext } from "../../../context/SidebarContext";
import { Role } from "biz-dashboard-frontend/lib/types/components/reports-core/types/Role";
import { useGrantAccess } from "../../../hooks/useGrantAccess";

const CollapsedNavElement: FunctionComponent<{
  label: string;
  icon: ReactElement;
  children?: ReactElement | ReactElement[];
  section: Section;
  activeIcon: ReactElement;
  childrenCollection: NavChildren[];
  accessFor?: Role[];
  className?: string;
  childrenClassName?: string;
}> = ({
  label,
  icon,
  section,
  activeIcon,
  childrenCollection,
  accessFor = [
    "ROLE_SUPER_ADMIN",
    "ROLE_USER",
    "ROLE_ADMIN",
    "ROLE_CUSTOMER",
    "ROLE_MANAGER",
  ],
  className,
  childrenClassName,
}) => {
  const { isAccessGranted } = useGrantAccess(accessFor);

  if (!isAccessGranted) {
    return null;
  }
  const { openedSection, setOpenedSection } = useContext(SidebarContext);

  const active = section === openedSection;

  const handleNavElementClick = () =>
    active ? setOpenedSection(null) : setOpenedSection(section);

  return (
    <div className="flex flex-col items-stretch bg-bizDarkBlue-600 border-b-bizDarkBlue-500 border-b text-sm">
      <div
        className={cn(
          `font-gothicBookRegular transition-all cursor-pointer text-bizGray-400 py-3.5 pl-3.5 ${
            active ? "bg-bizDarkBlue-600" : "bg-bizDarkBlue-700"
          } hover:bg-bizDarkBlue-600 flex items-center border-b-bizDarkBlue-600 border-b-2`,
          className,
        )}
        onClick={handleNavElementClick}
      >
        <div className="pl-1 shrink-0">{active ? activeIcon : icon}</div>
        <span
          className={cn(
            "pl-6 text-ellipsis text-nowrap",
            active && "text-white",
          )}
        >
          {label.toUpperCase()}
        </span>
      </div>
      <div
        className={cn(
          "transition-height duration-300 delay-0 overflow-hidden",
          active ? "max-h-[1000px]" : "max-h-0",
        )}
      >
        <CollapsedNavChildren
          childrenCollection={childrenCollection}
          section={section}
          className={childrenClassName}
        />
      </div>
    </div>
  );
};

export default CollapsedNavElement;

import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { FunctionComponent } from "react";
import ROUTES from "./route";
import HomePage from "../pages/HomePage";
import PublicPageLayout from "../layouts/PublicPageLayout";
import Pin from "../pages/HomePage/Pin";
import PublicRoute from "./components/PublicRoute";
import RestrictedRoute from "./components/RestrictedRoute";
import DashboardLayout from "../layouts/DashboardLayout";
import useAuth from "../hooks/useAuth";
import WidgetsPage from "../pages/widgets/WidgetsPage";
import RemindPassword from "../pages/HomePage/RemindPassword";
import PaymentInformationPage from "../pages/PaymentInformationPage";
import PinManagementPage from "../pages/PinManagementPage/PinManagementPage";
import LanguagePage from "../pages/LanguagePage/LanguagePage";
import CropProfileImagePage from "../pages/CropProfileImagePage/CropProfileImagePage";
import TranslationsPage from "../pages/TranslationsPage/TranslationsPage";
import LanguageManagementPage from "../pages/LanguageManagementPage/LanguageManagementPage";
import EditLanguagePage from "../pages/LanguageManagementPage/EditLanguagePage";
import AddLanguagePage from "../pages/LanguageManagementPage/AddLanguagePage";
import DashboardNavigation from "./DashboardNavigation";
import UsersPage from "../pages/admin/Users/UsersPage";
import AddUserPage from "../pages/admin/Users/AddUserPage";
import CustomersPage from "../pages/admin/Customers/CustomersPage";
import NotificationsPage from "../pages/admin/Notifications/NotificationsPage";
import AddNotificationsPage from "../pages/admin/Notifications/AddNotificationsPage";
import useValidateTranslations from "../hooks/useValidateTranslations";
import BonusArchiveManagementPage from "../pages/admin/BonusArchiveManagement/BonusArchiveManagementPage";
import LiveBonusBuilder from "../pages/admin/LiveBonusBuilder/LiveBonusBuilder";
import EditUserPage from "../pages/admin/Users/EditUserPage";

const Navigation: FunctionComponent = () => {
  const { auth } = useAuth();
  const [searchParams] = useSearchParams();
  const route = searchParams.get("do");
  const section = searchParams.get("section");

  useValidateTranslations();

  return (
    <Routes>
      <Route element={<PublicPageLayout />}>
        <Route element={<PublicRoute />}>
          {!auth.isLoggedIn && (
            <Route path={ROUTES.HOME_PAGE} element={<HomePage />} />
          )}
          <Route path={ROUTES.REMIND_PASSWORD} element={<RemindPassword />} />
          <Route path={ROUTES.PIN} element={<Pin />} />
        </Route>
      </Route>
      <Route element={<RestrictedRoute to={["ROLE_CUSTOMER", "ROLE_ADMIN"]} />}>
        <Route element={<DashboardLayout />}>
          {auth.isLoggedIn && (
            <Route
              path={ROUTES.HOME_PAGE}
              element={
                route ? <DashboardNavigation route={route} /> : <WidgetsPage />
                // <div/>
              }
            />
          )}
          <Route
            path={ROUTES.PAYMENT_INFORMATION}
            element={<PaymentInformationPage />}
          />
          <Route path={ROUTES.PIN_MANAGEMENT} element={<PinManagementPage />} />
          <Route
            path={ROUTES.CROP_PROFILE_IMAGE}
            element={<CropProfileImagePage />}
          />
          <Route path={ROUTES.LANGUAGE} element={<LanguagePage />} />
          <Route
            element={
              <RestrictedRoute to={["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]} />
            }
          >
            <Route path={ROUTES.TRANSLATIONS} element={<TranslationsPage />} />
            <Route
              path={ROUTES.LANGUAGE_MANAGEMENT}
              element={<LanguageManagementPage />}
            />
            <Route
              path={ROUTES.LANGUAGE_MANAGEMENT_ADD}
              element={<AddLanguagePage />}
            />
            <Route
              path={`${ROUTES.LANGUAGE_MANAGEMENT_EDIT}/:id`}
              element={<EditLanguagePage />}
            />
            <Route
              path={`${ROUTES.ADMIN_USERS_PAGE}`}
              element={<UsersPage />}
            />
            <Route
              path={`${ROUTES.ADMIN_USERS_ADD}`}
              element={<AddUserPage />}
            />
            <Route
              path={`${ROUTES.ADMIN_USERS_EDIT}/:id`}
              element={<EditUserPage />}
            />
            <Route
              path={`${ROUTES.ADMIN_USERS_PAGE}`}
              element={<UsersPage />}
            />
            <Route
              path={`${ROUTES.ADMIN_NOTIFICATIONS}`}
              element={<NotificationsPage />}
            />
            <Route
              path={`${ROUTES.ADMIN_NOTIFICATIONS_ADD}`}
              element={<AddNotificationsPage />}
            />
            <Route
              path={`${ROUTES.ADMIN_BONUS_ARCHIVE_MANAGEMENT}`}
              element={<BonusArchiveManagementPage />}
            />
            <Route
              path={`${ROUTES.ADMIN_LIVE_BONUS_BUILDER}`}
              element={<LiveBonusBuilder />}
            />
          </Route>
          <Route
            element={
              <RestrictedRoute
                to={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_MANAGER"]}
              />
            }
          >
            <Route
              path={`${ROUTES.MANAGER_CUSTOMERS_PAGE}`}
              element={<CustomersPage />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

const Routing = () => (
  <BrowserRouter>
    <Navigation />
  </BrowserRouter>
);

export default Routing;

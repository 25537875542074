import { useMutation, useQuery } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";
import queryClient, { BasicMutationOptions } from "../queryClient";
import { AxiosError } from "axios";

export enum NotificationType {
  Default = 0,
  MissingPaymentData = 1,
  MissingPartnerAgreement = 2,
}

export type Notification = {
  backgroundColor: string;
  color: string;
  id: number;
  userType: number;
  userId: number;
  type: NotificationType;
  message: string | null;
  customData: string[] | null;
};

export type NotificationResponseData = Notification[];

export const useGetUserNotificationList = () => {
  const { privateApiClient } = useApiClient();
  return useQuery<Notification[], unknown>({
    queryKey: ["user_notification_list"],
    queryFn: async () =>
      camelcaseKeys(
        (
          await privateApiClient.get<NotificationResponseData>(
            `/global-notification/user/list`,
          )
        ).data,
      ),
    initialData: undefined,
  });
};

export const useGetNotificationList = () => {
  const { privateApiClient } = useApiClient();
  return useQuery<Notification[], unknown>({
    queryKey: ["notification_list"],
    queryFn: async () =>
      camelcaseKeys(
        (
          await privateApiClient.get<NotificationResponseData>(
            `/global-notification/list`,
          )
        ).data,
      ),
    initialData: undefined,
  });
};

export const useAddNotificationMutation = (
  mutationOptions?: BasicMutationOptions<
    any,
    AxiosError<{ message: string[] }>
  >,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<
    {},
    AxiosError<{ message: string[] }>,
    { message: string }
  >({
    mutationKey: ["notification"],
    mutationFn: async (data) => {
      return (await privateApiClient.post(`/global-notification/add`, data))
        .data;
    },
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["notification_list"] }),
  });
};

export const useDeleteNotification = (
  mutationOptions?: BasicMutationOptions<
    {},
    AxiosError<{ message?: string[] }>
  >,
) => {
  const { privateApiClient } = useApiClient();

  return useMutation<{}, AxiosError<{ message?: string[] }>, {}>({
    mutationKey: ["notification"],
    mutationFn: async (id) =>
      await privateApiClient.delete(`/global-notification/delete/${id}`),
    onSuccess: mutationOptions?.onSuccess,
    onError: mutationOptions?.onError,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["notification_list"] });
      queryClient.invalidateQueries({ queryKey: ["user_notification_list"] });
    },
  });
};

import i18next from "i18next";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const Option: FunctionComponent<{ lang: string }> = ({ lang }) => {
  i18next.loadNamespaces("languages");
  const { t } = useTranslation();

  return (
    <div className="flex  flex-1 justify-between pr-4">
      {t(lang, { ns: "languages" })} <div className="" />
      <i
        className={`fi fi-${lang === "en" ? "gb" : lang} fis w-2`}
        style={{
          width: "20px",
          height: "15px",
          backgroundSize: "cover",
          marginTop: "4px",
        }}
      />
    </div>
  );
};

export default Option;

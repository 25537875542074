import { useQuery } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";

export type User = {
  company: string;
  country: string;
  custNo: number;
  firstName: string;
  lastName: string;
  title: string;
};

export const useGetUser = ({ id }: { id: string }) => {
  const { privateApiClient } = useApiClient();
  return useQuery<User, unknown>({
    queryKey: ["user", { id }],
    queryFn: async () =>
      camelcaseKeys(
        (await privateApiClient.get<User>(`/customer/details/${id}`)).data,
      ),
    initialData: undefined,
    enabled: Boolean(id),
  });
};

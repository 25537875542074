import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Sprite from "../Sprite";
import useAuth from "../../hooks/useAuth";
import { User } from "../../api/User";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import { useGetUserInfo } from "../../api/UserInfo";
import useUser from "../../hooks/useUser";
import { SidebarContext } from "../../context/SidebarContext";

type ToolbarPropsType = {
  user: User;
  showExternalLinks?: boolean;
};
function Topbar({ user, showExternalLinks = false }: ToolbarPropsType) {
  const { logout } = useAuth();
  const { logoutImpersonatedUser, impersonatedAuth } = useAuth();
  const { setSidebarOpen } = useContext(SidebarContext);

  const { data } = useGetUserInfo({ id: user.custNo });

  const { t } = useTranslation(["topbar"]);
  return (
    <div className="flex bg-white w-full z-10 flex-col">
      <div className="flex flex-1">
        <div
          className="flex pt-2 pb-3 px-4 justify-content-center align-items-center lg:hidden border-r cursor-pointer"
          onClick={() => setSidebarOpen((x) => !x)}
        >
          <Icon name="hamburger" className={"items-center"} />
        </div>
        <div className="pt-5 pb-5 px-5 hidden sm:flex">
          <Sprite width={206} height={34} x={0} y={0} />
        </div>
        <div className="flex pt-2.5 pb-2.5 px-3 sm:px-5 sm:hidden flex-1">
          <Sprite width={58} height={34} x={0} y={0} />
        </div>
        <div className="flex-1 border-l items-center pl-5 hidden sm:flex">
          {user && <span>
            {t("hi")} {user.firstName} {user.title}
          </span>}
        </div>
        {showExternalLinks && (
          <>
            <div className="flex border-l items-stretch w:16 lg:w-48">
              <Link
                to="/"
                className="text-gray-600 hover:text-skyBlue-600 flex w:16 lg:w-48 items-center px-3 sm:px-5 lg:pr-1"
              >
                <span className="ml-1 flex lg:hidden scale-[0.8] sm:scale-100">
                  <Icon name="cart" />
                </span>
                <span className="ml-1 mr-6 hidden lg:flex">
                  <Icon name="leftArrowSkyBlue" />
                </span>
                <span className="hidden lg:flex">Web Shop</span>
              </Link>
            </div>
            <div className="flex border-l lg:pl-5 items-stretch w:16 lg:w-48 px-3 sm:px-5 lg:px-0">
              <Link
                to="/"
                className="text-gray-600 hover:text-skyBlue-600 flex w:16 lg:w-48 items-center"
              >
                <span className="hidden lg:flex">Register New</span>
                <span className="lg:ml-4 scale-[0.8] sm:scale-100">
                  <Icon name="userRegister" />
                </span>
              </Link>
            </div>
          </>
        )}
        {impersonatedAuth.accessToken && (
          <div className="flex border-l items-stretch bg-intenseRed-500 hover:bg-intenseRed-600">
            <button
              onClick={() => logoutImpersonatedUser()}
              className="flex md:w-64 items-center text-white"
            >
              {/*<div className="hidden md:flex flex-1 justify-center">{data.}</div>*/}
              <div className="hidden md:flex flex-1 justify-center">
                {impersonatedAuth.id}
              </div>
              <div className="w-14 sm:w-20 flex justify-center items-center h-full">
                <Icon name="exit" />
              </div>
            </button>
          </div>
        )}
        <div className="flex items-stretch">
          <button
            onClick={() => logout()}
            className="group flex md:w-64 items-center text-white bg-skyBlue-600 hover:bg-skyBlue-400"
          >
            <div className="hidden md:flex flex-1 justify-center">
              {t("logout")}
            </div>
            <div className="w-14 sm:w-20 bg-skyBlue-700 flex justify-center items-center h-full">
              <Icon name="rightArrow" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Topbar;

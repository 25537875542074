import { useQuery } from "@tanstack/react-query";
import useApiClient from "../hooks/useApiClient";
import camelcaseKeys from "camelcase-keys";

type Page = {
  id: number;
  title: string;
  lang: string;
} & CustomFields;

export type CustomFields = {
  footer: string;
  header: string;
};

export const useCmsPage = ({
  namespace,
  language,
}: {
  namespace: string;
  language: string;
}) => {
  const { cmsApiClient } = useApiClient();
  return useQuery<Page, unknown>({
    queryKey: [`cms_page_${namespace}_${language}`],
    queryFn: async () =>
      camelcaseKeys(
        (
          await cmsApiClient.get(
            `/wp-json/wp/v2/biz-cms?title=${namespace}&lang=${language}`,
          )
        ).data[0],
      ),
    initialData: undefined,
    enabled: Boolean(namespace),
  });
};

import { FunctionComponent } from "react";

type ButtonSubmitProps = {
  children: string;
  isLoading?: boolean;
  lowercase?: boolean;
};

const ButtonSubmit: FunctionComponent<ButtonSubmitProps> = ({
  children,
  isLoading,
  lowercase = false,
}) => (
  <button
    disabled={isLoading}
    type="submit"
    className="text-white bg-skyBlue-600 hover:bg-skyBlue-700 transition-colors duration-500 border border-skyBlue-600 font-medium rounded-md text-sm px-6 py-2 mr-2 mb-2 focus:outline-none"
  >
    {lowercase ? children : children.toUpperCase()}
  </button>
);

export default ButtonSubmit;

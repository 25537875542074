import { yupResolver } from "@hookform/resolvers/yup";
import React, { FunctionComponent } from "react";
import {
  useFieldArray,
  UseFormRegister,
  Control,
  FieldErrors,
  Controller,
} from "react-hook-form";
import TextInput from "../../../../components/Form/TextInput";
import Button from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import { FormRow } from "../../../../components/FormRow";
import ReactSelect from "react-select";
import { ErrorMessage } from "../../../../components/Form";
import { LiveBonus } from "../../../../api/LiveBonusBuilder";

const CURRENCIES = ["EUR", "DKK", "NOK", "SEK"];

type RecipientsSectioPropsType = {
  control: Control<LiveBonus, any>;
  register: UseFormRegister<LiveBonus>;
  errors: FieldErrors<LiveBonus>;
};
const RecipientsSection: FunctionComponent<RecipientsSectioPropsType> = ({
  control,
  register,
  errors,
}) => {
  const { t } = useTranslation("live_bonus_builder");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "recipients",
  });
  const currencyOptions = [
    { label: "Customer's default", value: "" },
    ...CURRENCIES.map((currency) => ({
      value: currency,
      label: currency,
    })),
  ];
  return (
    <>
      <div className="flex gap-3">
        <h2 className="text-subtitle uppercase text-skyBlue-400">
          {t("recipients")}
        </h2>
        <Button
          onClick={(e) => {
            e.preventDefault();
            append({
              custNo: 0,
              currency: currencyOptions[0].value,
              amount: 0,
            });
          }}
          colorScheme="white"
          size="sm"
        >
          {t("add", { ns: "common" })}
        </Button>
      </div>
      {fields.map((item, index) => (
        <div
          className="flex flex-wrap items-center gap-3 border-b"
          key={item.id}
        >
          <FormRow label={t("customer_number")} labelClassName="sm:w-auto pr-2">
            <TextInput
              {...register(`recipients.${index}.custNo`)}
              errorMessage={errors.recipients?.[index]?.custNo?.message}
            />
          </FormRow>
          <FormRow label={t("currency")} labelClassName="sm:w-auto pr-2">
            <Controller
              control={control}
              name={`recipients.${index}.currency`}
              render={({ field: { value, onChange } }) => (
                <ReactSelect
                  value={
                    value
                      ? currencyOptions.find((option) => value === option.value)
                      : currencyOptions[0]
                  }
                  options={currencyOptions}
                  onChange={(selectedOption) => {
                    onChange && onChange(String(selectedOption?.value));
                  }}
                  className="min-w-44"
                ></ReactSelect>
              )}
            />
            <ErrorMessage>
              {errors?.recipients?.[index]?.currency?.message}
            </ErrorMessage>
          </FormRow>
          <FormRow label={t("amount")} labelClassName="sm:w-auto pr-2">
            <TextInput
              {...register(`recipients.${index}.amount`)}
              errorMessage={errors.recipients?.[index]?.amount?.message}
            />
          </FormRow>
          {fields.length > 1 && (
            <Button onClick={() => remove(index)} colorScheme="white" size="sm">
              {t("remove_recipient")}
            </Button>
          )}
        </div>
      ))}
    </>
  );
};

export default RecipientsSection;

import { FunctionComponent } from "react";

type PinItemProps = {
  filled?: boolean;
};

const PinItem: FunctionComponent<PinItemProps> = ({ filled }) =>
  filled ? (
    <div className="bg-bizDarkBlue-600 w-10 h-10 rounded-full border-2 border-skyBlue-600 justify-center flex items-center">
      <div className="bg-white w-3 h-3 rounded-full"></div>
    </div>
  ) : (
    <div className="bg-almostBlack-500 w-10 h-10 rounded-full"></div>
  );

export default PinItem;

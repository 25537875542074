import { FunctionComponent, ReactNode } from "react";
import { ButtonSize, sizes } from "../../theme/Button";
import { ColorScheme, colorSchemes } from "../../theme/colorSchemes";
import { cn } from "../../helpers/cn";

type ButtonProps = {
  children: string | ReactNode;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: ButtonSize;
  colorScheme?: ColorScheme<"button">;
  className?: string;
  type?: "submit" | "button" | "reset" | undefined;
};
const Button: FunctionComponent<ButtonProps> = ({
  children,
  isLoading,
  onClick,
  size = "md",
  colorScheme = "sky-blue",
  className,
  type,
}) => (
  <button
    disabled={isLoading}
    className={cn(
      `${sizes[size]} uppercase transition-colors duration-500 border font-medium focus:outline-none ${colorSchemes.button[colorScheme]} ${className}`,
    )}
    onClick={onClick}
    type={type}
  >
    {children}
  </button>
);

export default Button;
